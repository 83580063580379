import axios from "axios"
import configs from "../configs/apiConfigs";
import {BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";
import { getCookie } from "../utils/cookies";

export const fetchClinicList = () => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "cliniclist/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const fetchDigitalForms = (clinicId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "documentlist/"+clinicId+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const uploadPDF = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'messagecenter/uploadpdf',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const sendDigitalDocEmail = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/sendclinicdigitaldocemail',
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: false
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}