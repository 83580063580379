import { logoutUser } from "./firebaseUtils";
import { getCookie, prepareCookie, removeCookie } from "./cookies";

export const attachHeader = (isAttachAuthParam: boolean, isMultiPart: boolean = false) => {
    try {
        const login = getCookie();
        if (isAttachAuthParam) {
            let headers = null;
            if (isMultiPart) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                    // 'x-access-param': login.authParam,
                    // 'xpr_user_id': login.uniqueId,
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'patient'
                }
            } else {
                headers = {
                    'Content-Type': 'application/json',
                    /* 'x-access-param': login.authParam,
                    'xpr_user_id': login.uniqueId, */
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'patient'
                }
            }
            return headers;
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        return headers;
    } catch (error) {
        removeCookie();
        logoutUser();
        window.location.href = '/';
    }
}