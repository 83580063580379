import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils";

export const checkLogin = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "checklogin/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const patientLogin = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "patientlogin1/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const forgotPassword = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "forgotpassword/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
};

export const verifyCode = (data: any, patientId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "register/" + patientId + "/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const getMasterInsuranceList = () => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'master/getinsurance/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const registerUser = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'register/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const checkForgotpasswordAccount = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "checkaccount/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const verifyOtpForgotpassword = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "forgotpassword/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
};

export const resetPasswordApi = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "resetpassword/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
};