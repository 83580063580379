export enum CLAIM_SUBMIT {
    CLAIM_SUBMIT_PENDING = 'CLAIM_SUBMIT_PENDING',
    CLAIM_SUBMIT_ERROR = 'CLAIM_SUBMIT_ERROR',
    CLAIM_SUBMIT_SUCCESS = 'CLAIM_SUBMIT_SUCCESS'
};
export interface ClaimSubmitRequest {
    userId?: string;
    uniqueId: string;
    appointmentId: number;
    appointmentDate: string;
    score: string;
    forChild: string;
    receivedPast: string;
    additionalNote: string;
    amount: string;
    comment: string;
    receipt: any;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface ClaimSubmitResponse {
    status: Status;
}

export interface ClaimSubmitState {
    pending: boolean;
    claimSubmitRequest?: ClaimSubmitRequest;
    claimSubmitResponse?: ClaimSubmitResponse;
    error: any;
}

export interface ClaimSubmitPendingAction {
    type: CLAIM_SUBMIT.CLAIM_SUBMIT_PENDING;
    payload: ClaimSubmitRequest;
}

export interface ClaimSubmitErrorAction {
    type: CLAIM_SUBMIT.CLAIM_SUBMIT_ERROR;
    error: any;
}
export interface ClaimSubmitSuccessAction {
    type: CLAIM_SUBMIT.CLAIM_SUBMIT_SUCCESS;
    claimSubmit: ClaimSubmitResponse;
}

export type ClaimSubmitActionTypes = ClaimSubmitErrorAction | ClaimSubmitPendingAction | ClaimSubmitSuccessAction;