import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { roundNumberWithRoundingFactorDecimalFix } from '../../../../utils/global';
import { NoCard } from './NoCard';
import { Link, NavLink } from 'react-router-dom';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import configs from '../../../configs/apiConfigs';
import { WriteReviewModal } from '../../appointments/WriteReviewModal';
import Carousel from 'react-bootstrap/Carousel';
import { RecurringPayment } from '../../../pages/payment/Payment';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppointment, assignAppointment, fetchAllAppointment } from '../../../services/appointment';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { pinsData } from '../../../../store/pins/actions';
import { EmergencyDisplay } from '../emergency/EmergencyDisplay';
import { userLogout } from '../../../../store/auth/actions';
import { toast } from 'react-toastify';

export const CompletedAppointments = ({ appointments, fromDashboard, selectedAppointment, mode }: any) => {
    const [currentClaimStatusOpen, setCurrentClaimStatusOpen] = useState('');
    const [currentSelectedAppointment, setCurrentSelectedAppointment]: any = useState('');
    const [writeReview, setWriteReview] = useState(false);
    const location: any = useLocation();
    const [isShowRecurringPayment, setIsShowRecurringPayment] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const [isNoRecord, setIsNoRecord] = useState(false);
    const [appointmentList, setAppointmentList]: any = useState([]);
    const [isShowEmergency, setIsShowEmergency] = useState(false);

    useEffect(() => {
        if (appointments && appointments.completed && appointments.completed.length > 0) {
            setAppointmentList(appointments.completed);
            if (selectedAppointment && mode === "payment" && selectedAppointment?.billingdata?.clinicToPatientBilling?.status !== "paid") {
                setIsShowRecurringPayment(true);
                setCurrentSelectedAppointment(selectedAppointment);
            } else if (selectedAppointment && mode === "payment") {
                toast.success("You have already paid for the appointment");
                dispatch(userLogout());
                history.push('/');
            }
        }
    }, [appointments, selectedAppointment]);
    const fetchAppointmentApi = () => {
        setIsLoading(true);
        dispatch(fetchLoadingPending());
        fetchAllAppointment(patientData.uniqueId).then((success) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setAppointmentList(success.completed);
                if (success.appointments && success.appointments.length <= 0) {
                    setIsNoRecord(true);
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    const hideWriteReview = () => {
        setWriteReview(false);
        setCurrentSelectedAppointment('');
    }

    const handleChat = (appointment: any) => {
        dispatch(pinsData({
            ...appointment,
            appointmentId: appointment.uniqueid,
            sendToCId: appointment.clinic.uniqueid,
            pinType: "message",
            openPin: true,
        }))
    }
    const appointmentLoop = () => {
        return (
            (appointmentList && appointmentList.length > 0) ?
                appointmentList.map((appointment: any) => {
                    let endTime: any = '';
                    if (appointment.time && appointment.duration) {
                        endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                    }
                    let pFullname = '';
                    if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
                        pFullname = appointment.dependant.firstName + " " + appointment.dependant.lastName;
                    }
                    if (fromDashboard) {
                        return (
                            <Carousel.Item key={appointment.id}>
                                {singleAppointment(appointment, endTime, pFullname)}
                            </Carousel.Item>
                        )
                    } else {
                        return (
                            singleAppointment(appointment, endTime, pFullname)
                        )
                    }
                })
                :
                <NoCard name="Completed" />
        )
    }
    const singleAppointment = (appointment: any, endTime: any, pFullname: any) => {
        return (
            <div key={appointment.id} className="appo_card card p-4 p-lg-3 position-relative bg-light">
                <div className="app_status">
                    {/* <span className="badge ms-2 rounded-pill bg-primary">Completed</span> */}
                    {
                        !(appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment) &&
                        <span className="badge ms-2 rounded-pill bg-danger">Invoice not available</span>
                    }
                    {
                        (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment) && (appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.clinicToPatientBilling && appointment.billingdata.clinicToPatientBilling.total && (appointment.billingdata.clinicToPatientBilling.total !== appointment.billingdata.recurring.paid)) &&
                        <span className="badge ms-2 rounded-pill bg-primary">Total - ${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span>
                    }
                    {
                        (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment) &&(appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                            appointment.billingdata.clinicToPatientBilling.status === "pending" && appointment.billingdata && appointment.billingdata.recurring && (!appointment.billingdata.recurring.recurringStatus || appointment.billingdata.recurring.recurringStatus === false)) &&
                        <span className="badge ms-2 rounded-pill bg-danger">Unpaid</span>
                    }
                    {
                        (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment) &&(appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                            appointment.billingdata.clinicToPatientBilling.status === "pending" && appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus === true) &&
                        <span className="badge ms-2 rounded-pill bg-danger">Unpaid - ${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span>
                    }
                    {
                        (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment) &&(appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                            appointment.billingdata.clinicToPatientBilling.status === "paid" && appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.recurring.paid !== appointment.billingdata.clinicToPatientBilling.total && appointment.billingdata.recurring.paid > 0) &&
                        <span className="badge ms-2 rounded-pill bg-danger">Unpaid - ${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total - Number(appointment.billingdata.recurring.paid)), 2)}</span>
                    }
                    {
                        (appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                            appointment.billingdata.clinicToPatientBilling.status === "paid") &&
                        <span className="badge ms-2 rounded-pill bg-success">Paid
                            {(appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.recurring.paid > 0) ?
                                <>
                                    {appointment.billingdata.recurring.paid > 0 && <> - ${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.recurring.paid, 2)}</>}
                                </>
                                :
                                <>
                                    {appointment.billingdata.clinicToPatientBilling.total > 0 && <> - ${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</>}
                                </>
                            }
                        </span>
                    }
                </div>

                <div className="d-flex align-items-center appo_with mb-4">
                    <div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
                        <img alt="Image placeholder" src={configs.IMAGE_URL + '/doctor/' + appointment.doctor.photo.name + '-200x200.' + appointment.doctor.photo.type} />
                    </div>
                    <div className="av-txt">
                        <div className="av_title">{appointment.doctor.fullname ? appointment.doctor.fullname : ''}</div>
                        <div className="av_subtitle opacity-50">{appointment.clinic.name ? appointment.clinic.name : '-'}</div>
                    </div>
                </div>

                <div className="d-flex align-items-center appo_for mb-4">
                    <div className="av-txt">
                        <div className="av_title fw-normal">{pFullname ? pFullname : '-'}</div>
                        <div className="av_subtitle">{appointment.date ? moment(appointment.date).format('MMM DD, YYYY') : '-'} ({appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'})</div>
                        {
                            (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment && appointment.billingdata && appointment.billingdata.clinicToPatientBilling && appointment.billingdata.clinicToPatientBilling.total && appointment.billingdata.clinicToPatientBilling.total > 0 && appointment.clinic && appointment.clinic.paymentSubscription === "On") ?
                            <div className="av_subtitle">
                                {/* Total - <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span> */}
                                    {
                                        (appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.recurring.numberOfEmi && appointment.billingdata.clinicToPatientBilling.total !== appointment.billingdata.recurring.paid && appointment.billingdata.clinicToPatientBilling.total > 0) &&
                                    <> {appointment.billingdata.recurring.paymentMode} Installment : <span>${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total / Number(appointment.billingdata.recurring.numberOfEmi)), 2)}</span></>
                                }
                                </div>
                                :<></>
                        }
                    </div>
                </div>

                <div className="short_action">
                    <ul>
                        <li>
                            <button className="btn rounded-circle" onClick={() => { handleChat(appointment) }}>
                                <span className="material-icons">insert_comment</span>
                            </button>
                        </li>
                        {
                            (appointment && appointment.emergency && appointment.emergency.id) &&
                            <li>
                                <button className="btn rounded-circle" onClick={() => { setCurrentSelectedAppointment(appointment); setIsShowEmergency(true) }} >
                                    <img src={require('../../../assets/images/emergency.svg')} height={'20px'} width={'20px'} />
                                </button>
                            </li>
                        }
                        
                    </ul>
                </div>

                <div className="card_buttons">
                    {
                        (appointment.clinic && appointment.clinic.metadata && appointment.clinic.metadata.googleReviewUrl && appointment.clinic.metadata.googleReviewUrl !== "") ?
                            <a className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3" href={appointment.clinic.metadata.googleReviewUrl} target="_blank">Write a Review</a>
                            : <>
                                {
                                    (appointment.rating === null) &&
                                    <button className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3" onClick={() => {
                                        console.log('callReview');

                                        setWriteReview(true);
                                        setCurrentSelectedAppointment(appointment);
                                    }}>Write a Review</button>
                                }
                            </>
                    }
                    {/* <button type="button" className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3">Write Review</button> */}
                    {
                        (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment &&
                            (appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                                appointment.billingdata.clinicToPatientBilling.status === "pending") && (
                                appointment.clinic && appointment.clinic.paymentSubscription === "On"
                            )) &&
                        <button className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3"
                            onClick={(e: any) => {
                                setCurrentClaimStatusOpen('');
                                setIsShowRecurringPayment(true);
                                setCurrentSelectedAppointment(appointment);
                            }}>
                            {
                                (appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus &&
                                    appointment.billingdata.recurring.numberOfEmi) ?
                                    <>Pay now - <span>${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total / Number(appointment.billingdata.recurring.numberOfEmi)), 2)}</span></>
                                    :
                                    <>Pay now - <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span></>
                            }
                            {/* Pay now - <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span> */}

                        </button>
                    }

                    {/* <button type="button" className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3">Pay Now</button> */}
                </div>
            </div>
        )
    }
    return (
        <>
            {
                (fromDashboard) ?
                    <Carousel
                        className="myappointment_coro"
                        controls={true}
                        interval={null}
                        nextIcon={<i className="bi bi-arrow-right-circle"></i>}
                        prevIcon={<i className="bi bi-arrow-left-circle"></i>}
                    >
                        {appointmentLoop()}
                    </Carousel>
                    : appointmentLoop()
            }
            <SharedOffCanvas
                isOpen={isShowRecurringPayment}
                handleClose={() => { 
                    if (mode === "payment") {
                        return;
                    }
                    setIsShowRecurringPayment(false) 
                }}
                Children={RecurringPayment}
                mode={mode}
                handleSuccess={() => {
                    setIsShowRecurringPayment(false);
                    fetchAppointmentApi();
                }}
                appointment={currentSelectedAppointment}
            />
            {
                (writeReview) &&
                <WriteReviewModal
                    writeReview={writeReview}
                    hideWriteReview={hideWriteReview}
                    currentSelectedAppointment={currentSelectedAppointment}
                    writeReviewSuccess={() => {
                        hideWriteReview();
                        fetchAppointmentApi();
                    }}
                />
            }
            <SharedOffCanvas
                isOpen={isShowEmergency}
                handleClose={() => { setIsShowEmergency(false) }}
                Children={EmergencyDisplay}
                handleSuccess={() => {
                    setIsShowEmergency(false)
                }}
                appointment={currentSelectedAppointment}
            />
        </>
    )
}