import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { fetchClinicList, fetchDigitalForms } from '../../../services/digitalForm';
import { Controller, useForm } from 'react-hook-form';
import { fetchClinicWiseAppointments, submitEmergencyRequest } from '../../../services/emergency';
import moment from 'moment';
import { toast } from 'react-toastify';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import apiConfigs from '../../../configs/apiConfigs';
import { getCookie } from '../../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { userLogout } from '../../../../store/auth/actions';
import { useHistory } from 'react-router';
export const Emergency = ({ handleModalClose, handleSuccess }: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [clinicList, setClinicList] = useState([]);
    const [appointmentList, setAppointmentList] = useState([]);
    const [selectedPictures, setSelectedPictures]: any = useState([]);
    const [imageUrls, setImageUrls]: any = useState('');
    const [videoName, setVideoName]: any = useState('');
    const [selectedVideo, setSelectedVideo]: any = useState('');
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const [videoUrl, setVideoUrl]:any = useState('');
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            clinicId: '',
            subject: '',
            description: '',
            appointment: '',
            pictures: '',
            video: ''
        }
    });

    useEffect(() => {
        fetchClinics();
    }, []);

    const fetchClinics = () => {
        dispatch(fetchLoadingPending());
        fetchClinicList().then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setClinicList(success.clinics);
                console.log('successClinic');

                if (success.clinics && success.clinics.length > 0) {
                    setValue('clinicId', success.clinics[0].id)
                }
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }

    useEffect(() => {
        if (getValues('clinicId')) {
            fetchAppointmentList();
        }
    }, [watch('clinicId')]);

    const fetchAppointmentList = () => {
        dispatch(fetchLoadingPending());
        fetchClinicWiseAppointments(getValues('clinicId')).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setAppointmentList(success.appointments);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }
    const handlePictures = (event: any) => {
        const file = event.target.files[0];
        if (imageUrls.length < 3) {
            if (file.type === "image/png" || file.type === 'image/jpg' || file.type == 'image/jpeg') {
                if (file.size > 1048576) {
                    setError('pictures', {
                        type: 'picturesize', message: 'Maximum file size should be 1mb'
                    });
                    setValue('pictures', null);
                } else {
                    setValue('pictures', event.target.files);
                    getBase64(file);
                    setSelectedPictures([...selectedPictures, file]);
                }
            } else {
                setError('pictures', {
                    type: 'picturetype', message: 'Picture extension is not valid, allowed png,jpg,jpeg'
                });
                setValue('pictures', null);
            }
        } else {
            setError('pictures', {
                type: 'picturemax', message: 'Maximum 3 file upload.'
            });
        }
    }
    const getBase64 = (file: any,type?:any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (type === 'video') {
                    resolve(setVideoUrl(reader.result));
                } else {
                    resolve(setImageUrls([...imageUrls, reader.result]));
                }
            }
            reader.onerror = error => reject(console.log(error));
        });
    }
    const handleUploadVideo = (event: any) => {
        const file = event.target.files[0];
        console.log('file',file);
        
        if (file.type === "video/mp4") {
            if (file.size > (1048576 * 3)) {
                setError('video', {
                    type: 'videosize', message: 'Maximum file size should be 3mb'
                });
                setValue('video', null);
            } else {
                getBase64(file,'video')
                setValue('video', event.target.files);
                setVideoName(event.target.files[0].name);
                setSelectedVideo(event.target.files[0]);
            }
        } else {
            setError('video', {
                type: 'videotype', message: 'Video extension is not valid, allowed mp4.'
            });
            setValue('video', null);
        }
    }

    const addToFirebase = (data: any) => {
        const selectedAppointment: any = appointmentList.find((appointment: any) => appointment.uniqueid === data.appointment);
        const user: any = getCookie();
        const clinic:any = clinicList.find((clinic: any) => { return clinic.id === data.clinicId });
        if (patientData && selectedAppointment && user) {
            const obj = {
                "appointmentId": Number(data.appointment),
                "senderId": Number(user.xpr_user_id),
                "sender": CHAT.SENDER.PATIENT,
                "action": "Triage emergency",
                "actionType": CHAT.ACTION_TYPE.TRIAGE_EMERGENCY,
                "subject": data.subject,
                "description": data.description,
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": clinic.uniqueId,
                "dependantId": Number(patientData.dependant[0].uniqueId),
                "providerName": selectedAppointment.fullname,
                "dateTime": {
                    date: selectedAppointment.date,
                    time: selectedAppointment.time
                }
            };
            dispatch(fetchLoadingPending());
            firebase.firestore().collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                .then(() => {
                    dispatch(fetchLoadingSuccess(false));
                    console.log("Document successfully written!");
                    toast.success('Emergency request sent successfully.');
                    handleModalClose();
                })
                .catch((error: any) => {
                    dispatch(fetchLoadingSuccess(false));
                    dispatch(userLogout());
                    history.push('/');
                    console.error("Error writing document: ", error);
                });
        }
    }

    const onSubmit = (data: any) => {
        console.log('data', data);
        const request = {
            clinicId: data.clinicId,
            appointmentId: data.appointment,
            subject: data.subject,
            description: data.description,
            photos: selectedPictures,
            video: selectedVideo
        }
        dispatch(fetchLoadingPending());
        submitEmergencyRequest(request).then((success: any) => {
            // dispatch(fetchLoadingSuccess(false));
            console.log('success', success);
            if (success && success.response && success.response.data && success.response.data.status && success.response.data.status.error === false) {
                // toast.success(success.response.data.status.msg ? success.response.data.status.msg : 'Emergency request sent successfully.');
                addToFirebase(data);
                // handleModalClose();
            } else if (success && success.response && success.response.data && success.response.data.status && success.response.data.status.error === true) {
                toast.error(success.response.data.status.msg);
                dispatch(fetchLoadingSuccess(false));
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            if (err && err.status === true && err.msg) {
                toast.error(err.msg);
            }
            console.log(err);
        })
    }
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>Cancel</button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Triage/Emergency</h5>
            </div>
            <div className="offcanvas-body">
                <div className="triage_form">
                    <div className="mb-3">
                        <label className="form-label">Clinic</label>
                        <select className="form-select" name="clinicId" ref={register({ required: true })}>
                            {
                                (clinicList && clinicList.length > 0) &&
                                clinicList.map((clinic: any) => {
                                    return (
                                        <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Subject</label>
                        <input type="text" className="form-control" id="" placeholder="Write the problem statement" name="subject" ref={register({ required: true })} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea className="form-control" id="" rows={3} placeholder="Write details about the problem" name="description" ref={register({ required: true })}></textarea>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Appointment</label>
                        <select className="form-select" name="appointment" ref={register({ required: true })}>
                            {
                                (appointmentList && appointmentList.length > 0) &&
                                appointmentList.map((appointment: any) => {
                                    return (
                                        <option key={appointment.id} value={appointment.uniqueid}>{appointment.fullname} ({moment(appointment.date + " " + appointment.time, "YYYY-MM-DD hh:mm:a").format('MMM DD, YYYY - LT')})</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="mb-3">
                        <div className="d-flex mb-2">
                            {
                                (imageUrls && imageUrls.length > 0) &&
                                imageUrls.map((image: any, index: number) => {
                                    return (
                                        <img key={Math.random() + index} src={image} className="border rounded me-2" alt="image" style={{ height: '50px', width: '50px' }} />
                                    )
                                })
                            }

                        </div>

                        <label className="form-label">Upload Pictures</label><span className="fs-7 opacity-50 "> (Max - 3)</span>
                        <input type="file" disabled={imageUrls.length === 3} className="btn btn-upload w-100 fw-normal" name="pictures" accept="image/png, image/jpg, image/jpeg" ref={register} onChange={handlePictures} />
                        <div className="fs-7 opacity-50 text-end mt-2">Max file size 1 MB</div>
                        {
                            (errors && errors.pictures && errors.pictures.type === 'picturesize') &&
                            <div className="form-error">{errors.pictures.message}</div>
                        }
                        {
                            (errors && errors.pictures && errors.pictures.type === 'picturetype') &&
                            <div className="form-error">{errors.pictures.message}</div>
                        }
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Upload Video</label>
                        {/* <div className="fs-7 opacity-50 mb-2">{videoName ? videoName : ''}</div> */}
                        <input disabled={videoUrl} type="file" className="btn btn-upload w-100 fw-normal" name="video" accept="video/mp4" ref={register} onChange={handleUploadVideo} />
                        <div className="fs-7 opacity-50 text-end mt-2">Max file size 3 MB</div>
                        {
                            (errors && errors.video && errors.video.type === 'videosize') &&
                            <div className="form-error">{errors.video.message}</div>
                        }
                        {
                            (errors && errors.video && errors.video.type === 'videotype') &&
                            <div className="form-error">{errors.video.message}</div>
                        }
                    </div>
                    {
                        (videoUrl) &&
                        <div className="mb-3">
                            <video width="350" height="150" controls >
                                <source src={videoUrl} type="video/mp4" />
                            </video>
                        </div>
                    }
                    
                    <div className="mb-3">
                        <button type="button" className="btn btn-dark w-100 fw-normal" onClick={handleSubmit(onSubmit)}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}