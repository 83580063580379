import React from 'react'

export const Alert = () => {
    return (
        <div className="alert alert-warning mb-4 text-center" role="alert">
            Keep everyone safe around you, complete COVID-19 pre-screening before every appointment.
            {/* <button type="button" className="btn-close" aria-label="Close"></button> */}
        </div>
    )
}
