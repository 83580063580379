import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ClinicList } from './ClinicList';
import { Messages } from './Messages';

export const ChatPage = () => {
    const [selectedClinic, setSelectedClinic]: any = useState('');
    const history = useHistory();
    const handleSelectedClinic = (clinic: any) => {
        setSelectedClinic(clinic);
    }
    return (
        <>
            <div className="main-middle-sec " id="main-middle">
                <div className="container-fluid clinic-chat-sec">
                    <div className="row">
                        <div className="col-12 col-md-3 border-end chatlist-panel px-0">
                            <div className="p-3 pb-2">
                                <div className="fs-5 fw-medium mb-2">Clinic List</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="chat-tab-links">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <button className="nav-link active" type="button" onClick={() => {
                                                history.push('/chat/clinic');
                                            }}>Clinic</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link" type="button" onClick={() => {
                                                history.push('/chat/group');
                                            }}>Group</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div id="userListBox">
                                    <div className="infinite-scroll-component__outerdiv">
                                        <div className="infinite-scroll-component ">
                                            <ul className="list-group">
                                                <ClinicList
                                                    handleSelectedClinic={(clinic: any) => { handleSelectedClinic(clinic) }}
                                                    selectedClinic={selectedClinic}
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedClinic !== '' &&
                            <Messages clinic={selectedClinic} />}
                    </div>

                </div>
            </div>
        </>
    )
}