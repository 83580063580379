import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import configs from '../../configs/apiConfigs';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { getPatientClinicList } from '../../services/patientchat';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import { appointmentDetail } from '../../services/groupPin';

let listener: any;
let removePinListener: any;
let groupListTemp: any = [];
let userDocIdFirestore = '';
export const GroupList = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [groupList, setGroupList]: any = useState([]);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const [isNoRecord, setIsNoRecord] = useState(false);

    useEffect(() => {
        getGroups();
    }, []);

    const getGroups = () => {
        const patientUniqueId = patientData.uniqueId;
        listener = firebase.firestore().collection(configs.USER_COLLECTION).where('uniqueId', '==', patientUniqueId).get().then((snapshot) => {
            const user = snapshot.docs.map((item) => { return { id: item.id, ...item.data() } });
            const userDocId = user[0]?.id;
            if (userDocId) {
                userDocIdFirestore = userDocId;
                firebase.firestore().collection(configs.GROUP_COLLECTION).where('members', 'array-contains', userDocId).onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            const data = change.doc.data();
                            Object.assign(data, { groupId: change.doc.id });
                            appointmentDetail(data.appointmentUniqueId).then((success) => {
                                if (success && success.status && success.status.error === false) {
                                    Object.assign(data, success.data);
                                    setGroupList((groupList: any) => { return _.uniqBy([...groupList, data], 'appointmentUniqueId') });
                                    groupListTemp.push(data);
                                }
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                    });
                });

                /* Listen for group chat reject by provider */
                /* firebase.firestore().collection(configs.GROUP_COLLECTION).where('isClosed', '==', true).where('requestAcceptedByProvider.accepted', '==', false).onSnapshot((snapshot) => {
                    const data = snapshot.docs.map((doc) => {
                        setGroupList(groupListTemp.filter((item: any) => { return item.appointmentUniqueId !== doc.data().appointmentUniqueId }));
                        groupListTemp = groupListTemp.filter((item: any) => { return item.appointmentUniqueId !== doc.data().appointmentUniqueId });
                    });
                }); */

                /* Remove pin when group chat is closed by clinic. */
                /* removePinListener = firebase.firestore().collection(configs.GROUP_COLLECTION).where('isClosed', '==', true).where('members', 'array-contains', userDocId).onSnapshot((snapshot) => {
                    const data = snapshot.docs.map((doc) => {
                        setGroupList(groupListTemp.filter((item: any) => { return item.appointmentUniqueId !== doc.data().appointmentUniqueId }));
                        groupListTemp = groupListTemp.filter((item: any) => { return item.appointmentUniqueId !== doc.data().appointmentUniqueId });
                    });
                }); */
            }

        });
    }

    console.log('---groupList---', groupList);

    return (
        <>
            {
                isLoading &&
                <LoadingIndicator />
            }
            <div className="clinic-list d-none d-md-block">
                {(groupList && groupList.length > 0) ? groupList.map((group: any) => {
                    return (
                        <React.Fragment key={group.groupId}>
                            <GroupListItem
                                group={group}
                                selectedGroup={props.selectedGroup}
                                handleSelectedGroup={props.handleSelectedGroup}
                            ></GroupListItem>
                        </React.Fragment>


                    )
                }) : isNoRecord && <div className="flex-grow-1">
                    <div>No Group Found</div>
                </div>}
            </div>
        </>
    )
}

const GroupListItem = ({ group, selectedGroup, handleSelectedGroup, }: any) => {
    const [count, setCount] = useState(0);
    useEffect(() => {
        getUnReadCount();
    }, []);
    const getUnReadCount = () => {
        firebase.firestore().collection(configs.MESSAGE_COLLECTION).doc(group.groupId).onSnapshot((snapshot) => {
            const docs: any = snapshot.data();
            console.log(docs.messages);
            let count = 0;
            if (docs && Array.isArray(docs.messages)) {
                for (let doc of docs?.messages) {
                    if (!doc.readBy.includes(userDocIdFirestore)) {
                        count = count + 1;
                    }
                }
            }
            setCount(count);
        })
    }
    return (
        <li className="border-bottom">
            <div className={selectedGroup.groupId === group.groupId ? "list-group-item list-group-item-action border-0 active" : "list-group-item list-group-item-action border-0"} onClick={() => {
                handleSelectedGroup(group);
            }}>
                <div className="d-flex align-items-center position-relative">
                    {
                        group && group.clinic && group.clinic.name && group.doctor && group.doctor.fullname && group.dependant && group.dependant.fname &&
                        <div className="avatar avatar-48 img-circle me-2 text-uppercase">{group.clinic.name.charAt(0) + group.doctor.fullname.charAt(0) + group.dependant.fname.charAt(0)}</div>
                    }
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between">
                            {group.name}
                            {count !== 0 && <span className="badge rounded-pill bg-primary  text-center ms-2 small unread-count">{count}</span>}
                        </div>
                        {/* <span>{lastMessage}</span> */}
                    </div>
                </div>
            </div>
        </li>
    )
}