import React from 'react';
export const PreScreeningThanks = () => {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-6 mt-5">
                    <div className="text-center pt-5">
                        <h3>Thank you for Pre-screening.</h3><br />
                        <h4>Please click the back arrow or visit appointment details to check the Pre-screening status.</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
