import React, { useEffect, useState } from "react";
import { Device } from "@twilio/voice-sdk";
import axios from "axios";
import { shallowEqual, useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { Modal } from "react-bootstrap";
import configs from "../../configs/apiConfigs";

export const VoiceCall = (props: any) => {
  const [status, setStatus] = useState("");
  const [device, setDevice]: any = useState(null);
  const [showIncomingCallModal, setShowIncomingCallModal] = useState(false);
  const [connectionCall, setConnectionCall]: any = useState(null);
  const [clinicName, setClinicName] = useState("");

  const patientData = useSelector(
    (state: IAppState) => state.patientData.patientDataResponse,
    shallowEqual
  );

  useEffect(() => {
    if (patientData && patientData.uniqueId) {
      axios
        .post(configs.COMMUNICATION_SERVER + "token/generate", {
          clientId: patientData.uniqueId,
        })
        .then((success) => {
          if (success && success.data) {
            const device = new Device(success.data.token, {
              allowIncomingWhileBusy: true,
            });
            setDevice(device);
            device.register();
            device.on("registered", (connection) => {
              console.log("registered");
              setStatus("Device ready");
            });
            device.on("incoming", (connection) => {
              console.log("Incoming call", connection);
              setStatus("Incoming call");
              setConnectionCall(connection);
              setShowIncomingCallModal(true);
              if (connection.customParameters.get("clinicName")) {
                setClinicName(connection.customParameters.get("clinicName"));
              }
            });
            device.on("error", (error) => {
              setStatus("Device error");
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [patientData]);

  const handleHangUp = () => {
    let deviceObj: Device = device;
    connectionCall?.disconnect();
    deviceObj?.disconnectAll();
    document.getElementById("main-middle")?.classList.remove("call-start");
    setShowIncomingCallModal(false);
    setStatus("");
  };

  const onHide = () => {
    setStatus("");
    setShowIncomingCallModal(false);
  };

  const onAccept = () => {
    if (connectionCall) {
      connectionCall.accept();
      document.getElementById("main-middle")?.classList.add("call-start");
      addEventListeners();
      setStatus("Connected");
    }
  };

  const addEventListeners = () => {
    connectionCall.on("accept", () => {
      console.log("--Accepted---");
      setStatus("Connected");
    });
    connectionCall.on("cancel", () => {
      console.log("--Cancelled---");
      setStatus("Call cancelled");
      handleHangUp();
    });
    connectionCall.on("disconnect", () => {
      console.log("--Disconnected---");
      setStatus("Call disconnected");
      handleHangUp();
    });
    connectionCall.on("reject", () => {
      console.log("--Rejected---");
      setStatus("Call rejected");
      handleHangUp();
    });
  };

  const onDecline = () => {
    console.log(connectionCall);
    if (connectionCall) {
      connectionCall.reject();
      setStatus("");
      setShowIncomingCallModal(false);
    }
  };

  let deviceObj: Device = device;
  console.log(status);
  if (showIncomingCallModal) {
    return (
      <>
        {status === "Connected" && (
          <div className="call-strip sticky-top">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0 me-2">
                  {clinicName?.charAt(0)}
                </div>
                <div className="av-txt flex-grow-1">
                  <div className="av_title text-white">
                    {clinicName}
                  </div>
                  <div className="av_subtitle opacity-50 text-white">{status}</div>
                </div>
              </div>

              <div>
                <button className="btn btn-danger" onClick={handleHangUp}>
                  <i className="bi bi-telephone"></i> Hangup
                </button>
              </div>

            </div>
          </div>
        )}

        {status === "Incoming call" && (
          <Modal
            className="callpop"
            centered
            show
            backdrop={false}
            onHide={onHide}
          >
            <Modal.Body>
              <div className="d-flex justify-content-center mb-3">
                <div className="modal-title h5 text-white">Incoming call</div>
              </div>

              <div className="d-flex justify-content-center mb-2">
                <div className="avatar avatar-xxl img-circle">
                  {clinicName.charAt(0)}
                </div>
              </div>

              <div className="text-center mb-5 text-muted">{clinicName}</div>
              <div className="d-flex justify-content-center mb-2">
                <button className="btn btn-success rounded-circle" onClick={onAccept}>
                  <span className="material-icons">call</span>
                </button>
                <button className="btn btn-danger rounded-circle ms-5" onClick={onDecline}>
                  <span className="material-icons">call_end</span>
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
  return null;
};
