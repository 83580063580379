import { loadStripe, Stripe } from '@stripe/stripe-js';
import apiConfigs from '../../../configs/apiConfigs';
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import './_practiceSteps.scss';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { addPatientStripeCard } from '../../services/Payment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { getCookie } from '../../utils';
import { CHAT } from '../../constants';
import { userLogout } from '../../../store/auth/actions';
import { toast } from 'react-toastify';
import { roundNumberWithRoundingFactorDecimalFix } from '../../../utils/global';
import { fetchCards, removeStripeCard } from '../../services/card';
import { SharedOffCanvas } from '../../components/sharedOffCanvas/SharedOffCanvas';
import { PaymentAddCard } from '../../components/dashboardNew/profile/PaymentAddCard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import configs from '../../../configs/apiConfigs';
import { ConfirmationModal } from '../../components/confirmationModal/ConfirmationModal';

const stripePromise = loadStripe(apiConfigs.STRIPE_PUBLIC_KEY);
let cardNumberElement: any = null;
export function RecurringPayment({ handleModalClose, appointment, handleSuccess, mode }: any) {
	const [stripeReady, setStripeReady] = useState(false);
	const [cardHolderName, setCardHolderName] = useState('');
	const [cardValidation, setCardValidation] = useState({ invalid: false, msg: '' });
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const [show, setShow] = useState(false);
	const [cardList, setCardList]: any = useState([]);
	const [isShowPaymentAddCard, setIsShowPaymentAddCard] = useState(false);
	const [selectedCard, setSelectedCard]: any = useState('');
	const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
	const [isNoRecord, setIsNoRecord] = useState(false);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [selectedCardId, setSelectedCardId]: any = useState('');

	useEffect(() => {
		console.log('appointment', appointment);
		getCards();
	}, []);
	const getCards = () => {
		dispatch(fetchLoadingPending());
		fetchCards().then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success && success.status && success.status.error === false) {
				setCardList(success.stripeCards)
				if (success.stripeCards && success.stripeCards.length <= 0) {
					setIsNoRecord(true);
				}
			} else if (success && success.status.error === true) {
				setCardList([])
				setIsNoRecord(true);
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
			setIsNoRecord(true);
			console.log(err);
			if (err.error === true) {
				setCardList([])
				setIsNoRecord(true);
			}
		})
	}

	const cardNumberRef = (element: any) => {
		if (element) {
			dispatch(fetchLoadingPending());
			cardNumberElement = element;
			dispatch(fetchLoadingSuccess(false));
			setStripeReady(true);
		}
	};

	const addPaymentToFirestore = (message: any) => {
		const { state }: any = location;
		if (appointment) {
			console.log('State', state);
			const obj = {
				"appointmentId": Number(appointment.uniqueid),
				"senderId": Number(getCookie().xpr_user_id),
				"sender": CHAT.SENDER.PATIENT,
				"action": CHAT.ACTION.PBP,
				"actionType": 'PAYMENT_SUCCESSFUL',
				"text": "Payment successfull",
				"createdAt": firebase.firestore.FieldValue.serverTimestamp(),
				"updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
				"sendToCId": appointment.clinic.uniqueid,
				"sendToDId": appointment.doctor.uniqueid,
				"metadata": {
					"viewByClinicAt": null,
					"viewByPatientAt": null,
				},
				"dependantId": Number(appointment.dependant.uniqueid),
				dateTime: {
					date: appointment.date,
					time: appointment.time
				}
			};
			console.log(obj);
			firebase.firestore().collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
				.then(() => {
					setTimeout(() => {
						dispatch(fetchLoadingSuccess(false));
						toast.success(message);
						console.log("Document successfully written!");
						if (mode === "payment") {
							setPaymentSuccess(true);
							return;
						}
						handleSuccess()
						// history.go(-1);
					}, 3000);
				})
				.catch((error: any) => {
					dispatch(fetchLoadingSuccess(false));
					// this.props.logout();
					dispatch(userLogout());
					history.push('/');
					console.error("Error writing document: ", error);
				});
		}
	}

	const createStripeToken = () => {
		try {
			dispatch(fetchLoadingPending());
			stripePromise.then((success: Stripe | null) => {
				if (success) {
					const stripe = success;
					stripe.createToken(cardNumberElement, { name: cardHolderName }).then((success: any) => {
						if (success.error && (success.error.code === "incomplete_number" || success.error.code === "invalid_number")) {
							setCardValidation({ invalid: true, msg: success.error.message });
							dispatch(fetchLoadingSuccess(false));
						} else if (cardHolderName === "") {
							setCardValidation({ invalid: true, msg: 'Card Holder name required' });
							dispatch(fetchLoadingSuccess(false));
						} else if (success.error && (success.error.code === "incomplete_expiry" ||
							success.error.code === "invalid_expiry_year_past" ||
							success.error.code === "invalid_expiry_year")) {
							setCardValidation({ invalid: true, msg: success.error.message });
							dispatch(fetchLoadingSuccess(false));
						} else if (success.error && (success.error.code === "incomplete_cvc")) {
							setCardValidation({ invalid: true, msg: success.error.message });
							dispatch(fetchLoadingSuccess(false));
						} else if (success.error) {
							setCardValidation({ invalid: true, msg: success.error.message });
							dispatch(fetchLoadingSuccess(false));
						} else if (success.token) {
							setCardValidation({ invalid: false, msg: '' });
							console.log('success', success.token.id);
							const { state }: any = location;
							if (appointment && success.token.id && success.token.card && success.token.card.id) {
								let data: any = {
									patientUniqueId: userData.uniqueId,
									stripeToken: success.token.id,
									accountId: appointment.stripeAccount.accountID,
									appointmentId: appointment.id,
									cardId: ''
								}
								addPatientStripeCard(data).then((success) => {
									if (success && success.status && success.status.error === false) {
										addPaymentToFirestore(success.status.msg);
									}
								}).catch((err) => {
									if (err && err.error) {
										toast.error(err.msg);
									}
									dispatch(fetchLoadingSuccess(false));
									console.log(err);
								})
							} else {
								dispatch(fetchLoadingSuccess(false));
							}
						}
					}).catch((err) => {
						dispatch(fetchLoadingSuccess(false));
					});
				}
			}, (error) => {
				dispatch(fetchLoadingSuccess(false));
			});
		} catch (err) {
			dispatch(fetchLoadingSuccess(false));
		}
	}
	const onFinish = () => {
		createStripeToken();
	}

	const onPay = () => {
		let data: any = {
			patientUniqueId: userData.uniqueId,
			cardId: selectedCard.cardId,
			accountId: appointment.stripeAccount.accountID,
			appointmentId: appointment.id,
			stripeToken: ''
		}
		dispatch(fetchLoadingPending());
		addPatientStripeCard(data).then((success) => {
			if (success && success.status && success.status.error === false) {
				addPaymentToFirestore(success.status.msg);
			} else {
				dispatch(fetchLoadingSuccess(false));
			}
		}).catch((err) => {
			if (err && err.error) {
				toast.error(err.msg);
			}
			dispatch(fetchLoadingSuccess(false));
			console.log(err);
		})
	}
	let endTime: any = '';
	if (appointment.time && appointment.duration) {
		endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
	}
	let pFullname = '';
	if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
		pFullname = appointment.dependant.firstName + " " + appointment.dependant.lastName;
	}


	const handleDeleteCard = (Id: any) => {
        setIsConfirmationModal(true);
        setSelectedCardId(Id);
    };

	const removeCard = () => {
        dispatch(fetchLoadingPending());
        removeStripeCard(selectedCardId).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                toast.success('Card remove successfully');
                getCards();
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    };

    const hideConfirmationModal = () => {
        setIsConfirmationModal(false);
    }

	return (
		<>
			<div className="paynow_forms d-flex flex-column overflow-hidden">

				<div className="offcanvas-action d-flex">
					{
						mode !== "payment" &&
						<button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
							// data-bs-dismiss="offcanvas" aria-label="Close"
							onClick={() => {
								if (mode === "payment") {
									return;
								}
								handleModalClose()
							}}
						>
							Close
						</button>
					}
				</div>

				<div className="offcanvas-header">
					<h5 className="offcanvas-title">Pay Now</h5>
				</div>

				<div className="offcanvas-other">
					<div className="d-flex justify-content-between align-items-center border-bottom pb-1">
						<span className="fs-7 opacity-50">Amount</span>
						<span className="fs-4 fw-medium">
							{
								(appointment && appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus &&
									appointment.billingdata.recurring.numberOfEmi) ?
									<>${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total / Number(appointment.billingdata.recurring.numberOfEmi)), 2)}</>
									:
									<>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</>
							}
						</span>
					</div>
				</div>

				<div className="offcanvas-body">
					{
						!paymentSuccess &&
						<>
							<div className="saved_cards">
								{
									(cardList && cardList.length > 0) &&
									cardList.map((card: any) => {
										return (
											<div key={card.cardId} className="card px-4 py-3 mb-3">
												<div className='d-flex flex-row justify-content-between'>
													<div>
														<h6 className="">Payment Method</h6>
													</div>
													<div>
														<button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => { handleDeleteCard(card.cardId) }}>
															Change Card
														</button>
													</div>
												</div>
												<small className="">{card.name}</small>
												<div className="form-check">
													<input className="form-check-input" type="radio" name="usedCard" id="usercard1" onChange={() => { setSelectedCard(card) }} />
													<label className="form-check-label" htmlFor="usercard1">
														{card.brand} **** **** **** {card.last4}
													</label>
													<label className="form-check-label">
														<small>
															Expires {card.expMonth}/{card.expYear}
														</small>
													</label>
												</div>
											</div>
										)
									})
								}
								{/* <div className="text-end mb-3">
							<button type="button" className="btn btn-link p-0 fs-7" onClick={() => { setIsShowPaymentAddCard(true) }}>Add new card</button>
						</div> */}

							</div>

							{(cardList && cardList.length === 0 && isNoRecord) &&
								<Elements stripe={stripePromise}>

									<div className="mb-3 stp_element">
										<label className="form-label">Card number</label>
										<CardNumberElement className={"form-control"}
											options={{ showIcon: true, placeholder: "4242 4242 4242 4242" }}
											onReady={cardNumberRef}></CardNumberElement>
									</div>
									<div className="mb-3">
										<label className="form-label">Name on card</label>
										<input type="text" className="form-control" placeholder="Name on card" value={cardHolderName} onChange={e => setCardHolderName(e.target.value)} />
									</div>

									<div className="mb-3 stp_element">
										<label htmlFor="cc-expiration" className="form-label">Expiration date (mm/yy)</label>
										<CardExpiryElement className="form-control"></CardExpiryElement>
									</div>

									<div className="mb-3 stp_element">
										<label htmlFor="cc-expiration" className="form-label">CVV</label>
										<CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
									</div>

									{
										cardValidation.invalid === true &&
										<div className="alert alert-danger py-2">
											{cardValidation.msg}
										</div>
									}

									{/* <div className="form-check mb-3">
							<input className="form-check-input" type="checkbox" value="" id="saveCard" />
							<label className="form-check-label" htmlFor="saveCard">
								Save this card for next payment.
							</label>
						</div> */}
								</Elements>}

							<div className="mb-3">
								{
									(cardList && cardList.length === 0) ?
										<button type="button" className="btn btn-dark w-100 fw-normal"
											disabled={!stripeReady}
											onClick={() => { onFinish() }}>
											Save and Pay
										</button>
										:
										<button type="button" className="btn btn-dark w-100 fw-normal"
											disabled={!selectedCard}
											onClick={() => { onPay(); }}>
											Pay
										</button>
								}
							</div>
							<div className="paytc fs-7 text-center">
								Please read all payment <a href="https://www.xcare.app/payment-terms-of-service/" target="_blank">terms and conditions</a>
							</div>
						</>
					}
					{
						paymentSuccess &&
						<label>Your payment is successful, for safety reasons please close this browser window.</label>
					}
					<div className="appo_card card p-4 p-lg-3 position-relative bg-light mt-2">
						<div className="d-flex align-items-center appo_with mb-4">
							<div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
								<img alt="Image placeholder" src={configs.IMAGE_URL + '/doctor/' + appointment.doctor.photo.name + '-200x200.' + appointment.doctor.photo.type} />
							</div>
							<div className="av-txt">
								<div className="av_title">{appointment.doctor.fullname ? appointment.doctor.fullname : ''}</div>
								<div className="av_subtitle opacity-50">{appointment.clinic.name ? appointment.clinic.name : '-'}</div>
							</div>
						</div>

						<div className="d-flex align-items-center appo_for mb-4">
							<div className="av-txt">
								<div className="av_title fw-normal">{pFullname ? pFullname : '-'}</div>
								<div className="av_subtitle">{appointment.date ? moment(appointment.date).format('MMM DD, YYYY') : '-'} ({appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'})</div>
								{
									(appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment && appointment.billingdata && appointment.billingdata.clinicToPatientBilling && appointment.billingdata.clinicToPatientBilling.total && appointment.billingdata.clinicToPatientBilling.total > 0 && appointment.clinic && appointment.clinic.paymentSubscription === "On") ?
										<div className="av_subtitle">
											{/* Total - <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span> */}
											{
												(appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.recurring.numberOfEmi && appointment.billingdata.clinicToPatientBilling.total !== appointment.billingdata.recurring.paid && appointment.billingdata.clinicToPatientBilling.total > 0) &&
												<> {appointment.billingdata.recurring.paymentMode} Installment : <span>${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total / Number(appointment.billingdata.recurring.numberOfEmi)), 2)}</span></>
											}
										</div>
										: <></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<SharedOffCanvas
				isOpen={isShowPaymentAddCard}
				handleClose={() => { setIsShowPaymentAddCard(false) }}
				Children={PaymentAddCard}
				handleSuccess={() => {
					setIsShowPaymentAddCard(false)
					getCards();
				}}
			/>
			{
                    (isConfirmationModal) &&
                    <ConfirmationModal
                        isConfirm={isConfirmationModal}
                        hideConfirmationModal={hideConfirmationModal}
                        onClickDelete={() => {
                            hideConfirmationModal();
                            removeCard()
                        }}
						title={`This action will delete the card details that we have on your profile and prompt for adding new card details.`}
						confirmText={'Confirm'}
                    />
                }
		</>
	);
}
