import React, { useEffect, useState } from 'react';

export const DigitalFormDownload = ({ handleModalClose, handleSuccess, document, clinicList, clinicId }: any) => {
    
    const [selectedClinic, setSelectedClinic]:any = useState('');
    useEffect(() => {
        if (clinicId && clinicList && clinicList.length > 0) {
            setSelectedClinic(clinicList.find((clinic: any) => clinic.id === clinicId))
        }
    },[]);
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>Cancel</button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Download Form</h5>
            </div>

            <div className="offcanvas-body">

                <div className="alert alert-warning mb-4 text-center" role="alert">
                    Download and fill up the digital form. You do not need any special software to fill up this form on your desktop, laptop, tablet or mobile phone. Once you fill up the form please email it to <strong>{selectedClinic && selectedClinic.email ? selectedClinic.email:''}</strong>. Don't forget to write your name in the subject line along with Form title (ex: Yogi Pathare - Intake Form one). As an alternative, you can also upload the digital form on Self care portal or through the mobile app.
                </div>

                <div className="mb-2 text-dark">{document && document.documentname ? document.documentname:''}</div>
                <div className="mb-3">
                    <a target="_blank" href={document && document.path ? document.path:''} type="button" className="btn btn-dark w-100 fw-normal">
                        Download now
                    </a>
                </div>
            </div>
        </>
    )
}
