import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppointment, assignAppointment, fetchAllAppointment } from '../../../services/appointment';
import { ReSchedule } from './ReSchedule';
import { NoCard } from './NoCard';
import configs from '../../../configs/apiConfigs';
import moment from 'moment';
import { AppointmentConfirmationModal } from '../../appointments/AppointmentConfirmationModal';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import apiConfigs from '../../../configs/apiConfigs';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { showWaitingPresc, userLogout } from '../../../../store/auth/actions';
import { getCookie } from '../../../utils/cookies';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { PrescreeningModal } from '../../appointments/PrescreeningModal';
import { CheckInModal } from '../../appointments/CheckInModal';
import Carousel from 'react-bootstrap/Carousel'
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { pinsData } from '../../../../store/pins/actions';
export const UpcomingAppointments = ({ appointments, fromDashboard, handleSuccess }: any) => {
    let db: any;
    const [currentSelectedAppointment, setCurrentSelectedAppointment]: any = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [status, SetStatus] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const location: any = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [appointmentList, setAppointmentList]: any = useState([]);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const [isNoRecord, setIsNoRecord] = useState(false);
    const [completeScreeningModal, setCompleteScreeningModal] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [checkInModal, setCheckInModal] = useState(false);
    const [isShowReschedule, setIsShowReschedule] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState('')
    const [userLocation, setUserLocation] = useState({ latitude: '', longitude: '' });
    const [checkInWarning, setCheckInWarning] = useState(false);

    useEffect(() => {
        db = firebase.firestore();
        // fetchAppointmentApi();
    }, []);
    useEffect(() => {
        if (appointmentList && appointmentList.upcoming && appointmentList.upcoming.length > 0 && new URLSearchParams(location.search) && new URLSearchParams(location.search).get('id') !== '') {
            let Id = new URLSearchParams(location.search).get('id');
            const selectedAppointment = appointmentList.upcoming.find((item: any) => item.uniqueid == Id);
            if (selectedAppointment && selectedAppointment.metadata && selectedAppointment.metadata.appointment &&
                !(selectedAppointment.metadata.appointment.prescreening && selectedAppointment.metadata.appointment.prescreening?.attempt)) {
                dispatch(showWaitingPresc(false));
                setCompleteScreeningModal(true);
                setCurrentSelectedAppointment(appointmentList.upcoming.find((item: any) => item.uniqueid === Id))
            } else {
                dispatch(showWaitingPresc(false));
            }
        }
    }, [appointmentList]);

    const fetchAppointmentApi = () => {
        setIsLoading(true);
        dispatch(fetchLoadingPending());
        fetchAllAppointment(patientData.uniqueId).then((success) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setAppointmentList(success.upcoming);
                if (success.appointments && success.appointments.length <= 0) {
                    setIsNoRecord(true);
                }
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            setIsLoading(false);
            console.log(err);
        })
    }
    const handleHidePreScreeningModal = () => {
        setCurrentQuestion(0);
        setCompleteScreeningModal(false);
    }
    const handleSuccessPreScreening = () => {
        setCurrentQuestion(0);
        setCompleteScreeningModal(false);
        const login: any = getCookie();
        dispatch(fetchLoadingSuccess(false));
        fetchAppointmentApi();
        handleSuccess();
    }
    const hadleShowAlert = () => {
        setShowAlert(true);
    }

    const btnClickConfirm = () => {
        var textMessage: any = '';
        // "text": "Confirmation by " + currentSelectedAppointment.patient.firstName + " " + currentSelectedAppointment.patient.lastName + " for appointment on " + moment(currentSelectedAppointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " - " + currentSelectedAppointment.time + " with " + currentSelectedAppointment.doctor.fullname + " having XcarePro id " + currentSelectedAppointment.uniqueid,
        if (patientData && patientData.id === currentSelectedAppointment.dependant.loginId) {
            textMessage = "Appointment on " + moment(currentSelectedAppointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " - " + currentSelectedAppointment.time + " with " + currentSelectedAppointment.doctor.fullname + " is confirmed by " + currentSelectedAppointment.patient.firstName + " " + currentSelectedAppointment.patient.lastName + ". XCarePro id: " + currentSelectedAppointment.uniqueid;
        } else {
            textMessage = "Appointment on " + moment(currentSelectedAppointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " - " + currentSelectedAppointment.time + " with " + currentSelectedAppointment.doctor.fullname + " for " + currentSelectedAppointment.dependant.firstName + " " + currentSelectedAppointment.dependant.lastName + " is confirmed by " + patientData.firstName + " " + patientData.lastName + ". XCarePro id: " + currentSelectedAppointment.uniqueid;
        }
        db = firebase.firestore();
        dispatch(fetchLoadingPending());
        const user: any = getCookie();
        const obj = {
            "appointmentId": Number(currentSelectedAppointment.uniqueid),
            "senderId": Number(user.xpr_user_id),
            "sender": CHAT.SENDER.PATIENT,
            "action": "Confirm by patient",
            "actionType": CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT,
            "text": textMessage,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": currentSelectedAppointment.clinic.uniqueid,
            "addedFromMessageCenter": true,
            "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
            dateTime: {
                date: currentSelectedAppointment.date,
                time: currentSelectedAppointment.time
            }
        };
        console.log('firestoreConfirmObj', obj);
        setIsLoading(true);
        if (currentSelectedAppointment && currentSelectedAppointment.metadata && currentSelectedAppointment.metadata.appointment && currentSelectedAppointment.metadata.appointment.confirmation && currentSelectedAppointment.metadata.appointment.confirmation.status === "cancelled") {
            db.collection(apiConfigs.CHAT_COLLECTION_NAME)
            .where('appointmentId', '==', Number(currentSelectedAppointment.uniqueid))
            .where('actionType', '==', CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT).get().then((querySnapshot:any) => {
                querySnapshot.forEach((doc:any) => {
                    db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc(doc.id).update({
                        "metadata.markReadByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
                        "metadata.hidingDueToKeepPrevious": true
                    });
                });
            });
        }
        db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                dispatch(fetchLoadingSuccess(false));
                console.log("Document successfully written!");
                setCurrentSelectedAppointment('');
                setTimeout(() => {
                    fetchAppointmentApi();
                    handleSuccess();
                }, 1000);
            })
            .catch((error: any) => {
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false));
                dispatch(userLogout());
                history.push('/');
                console.error("Error writing document: ", error);
            });
    }
    const btnClickCancel = () => {
        db = firebase.firestore();
        dispatch(fetchLoadingPending());
        const user: any = getCookie();
        const obj = {
            "appointmentId": Number(currentSelectedAppointment.uniqueid),
            "senderId": Number(user.xpr_user_id),
            "sender": CHAT.SENDER.PATIENT,
            "action": "Cancel by patient",
            "actionType": CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT,
            "text": "Cancellation by " + currentSelectedAppointment.patient.firstName + " " + currentSelectedAppointment.patient.lastName + " for appointment on " + moment(currentSelectedAppointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " - " + currentSelectedAppointment.time + " with " + currentSelectedAppointment.doctor.fullname + " having XcarePro id " + currentSelectedAppointment.uniqueid,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": currentSelectedAppointment.clinic.uniqueid,
            "addedFromMessageCenter": true,
            "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
            dateTime: {
                date: currentSelectedAppointment.date,
                time: currentSelectedAppointment.time
            }
        };
        console.log('firestoreCancelObj', obj);
        return;
        setIsLoading(true);
        db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                dispatch(fetchLoadingSuccess(false));
                console.log("Document successfully written!");
                setCurrentSelectedAppointment('');
                setTimeout(() => {
                    fetchAppointmentApi();
                    handleSuccess();
                }, 1000);
            })
            .catch((error: any) => {
                dispatch(fetchLoadingSuccess(false));
                dispatch(userLogout());
                history.push('/');
                console.error("Error writing document: ", error);
            });
    }
    const hideConfirmationModal = () => {
        setIsConfirmationModal(false);
        SetStatus('');

    }
    const hideCheckInModal = () => {
        setCheckInModal(false);
    }
    const handleReschedule = (appointment: any) => {
        setSelectedAppointment(appointment);
        setIsShowReschedule(true);
    }
    const handleLocationPermission = (appointment: any) => {
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position: any) => {
                setUserLocation(
                    {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                );
                let data = {
                    ...appointment, userLoc: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                };
                console.log('data', data);

                history.push({ pathname: '/map', state: data });
            }, (error) => {
                console.log('error', error)
            });
        }
    }

    const handleChat = (appointment: any) => {
        dispatch(pinsData({
            ...appointment,
            appointmentId: appointment.uniqueid,
            sendToCId: appointment.clinic.uniqueid,
            pinType: "message",
            openPin: true,
        }))
    }

    const wrapeCarousel = () => {
        if (fromDashboard) {
            return (
                <Carousel
                    className="myappointment_coro"
                    controls={true}
                    interval={null}
                    nextIcon={<i className="bi bi-arrow-right-circle"></i>}
                    prevIcon={<i className="bi bi-arrow-left-circle"></i>}
                >
                    {appointmentLoop()}
                </Carousel>
            )
        } else {
            return (appointmentLoop())
        }
    }
    const appointmentLoop = () => {
        return (
            (appointments && appointments.upcoming && appointments.upcoming.length > 0) ?
                appointments.upcoming.map((appointment: any) => {
                    let endTime: any = '';
                    if (appointment.time && appointment.duration) {
                        endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                    }
                    let pFullname = '';
                    if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
                        pFullname = appointment.dependant.firstName + " " + appointment.dependant.lastName;
                    }
                    if (fromDashboard) {
                        return (
                            <Carousel.Item key={appointment.id}>
                                {singleAppointment(appointment, endTime, pFullname)}
                            </Carousel.Item>
                        )
                    } else {
                        return (
                            singleAppointment(appointment, endTime, pFullname)
                        )
                    }
                })
                :
                <NoCard name="Upcoming" />
        )
    }

    const singleAppointment = (appointment: any, endTime: any, pFullname: any) => {
        return (
            <div key={appointment.id} className="appo_card card p-4 p-lg-3 position-relative bg-light">
                <div className="app_status">
                    {
                        ((appointment.currentStatus === 'requested' || appointment.currentStatus === 'waitlist') && appointment.metadata && appointment.metadata.appointment && (!appointment.metadata.appointment.confirmation || appointment.metadata.appointment.confirmation.status !== "cancelled"))  && <span className="badge ms-2 rounded-pill bg-warning">Waitlist</span>
                    }
                    {
                        appointment.currentStatus === 'scheduled' && appointment.metadata && appointment.metadata.appointment && !appointment.metadata.appointment.confirmation &&
                        <span className="badge ms-2 rounded-pill bg-secondary">Scheduled</span>
                    }
                    {
                        (appointment.metadata && appointment.metadata.appointment && !appointment.metadata.appointment?.checkin) &&
                        appointment.currentStatus === 'scheduled' && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "confirmed" && <span className="badge ms-2 rounded-pill bg-primary">Confirmed</span>
                    }
                    {
                        (appointment.currentStatus === 'scheduled' || appointment.currentStatus === 'requested' || appointment.currentStatus === 'waitlist') && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "cancelled" &&
                        <span className="badge ms-2 rounded-pill bg-danger">Re-Schedule - Pending</span>
                    }
                    {/*  {
                        (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "cancelled" &&
                            appointment.metadata.appointment.confirmation.by === "Patient") &&
                        <span className="badge ms-2 rounded-pill bg-danger">By Patient</span>
                    } */}
                    {
                        (appointment.currentStatus !== 'requested' && appointment.currentStatus !== 'waitlist' && appointment && appointment.covid_pre_screen &&  appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "confirmed" && 
                            appointment.metadata && appointment.metadata.appointment && (!(appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening?.attempt))) &&
                        <span className="badge ms-2 rounded-pill bg-danger">Pre-Screening - Pending</span>
                    }
                    {
                        (appointment && appointment.covid_pre_screen && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening?.attempt && appointment.metadata.appointment.prescreening.verifiedOn) &&
                        <span className="badge ms-2 rounded-pill bg-success">Pre-Screening - Verified</span>
                    }
                    {
                        ((appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening?.attempt && appointment.metadata.appointment?.checkin && appointment.metadata.appointment?.checkin?.attempt) ||
                        (appointment && !appointment.covid_pre_screen && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment?.checkin && appointment.metadata.appointment?.checkin?.attempt)) &&
                        <span className="badge ms-2 rounded-pill bg-primary">Checked-in</span>
                    }
                    {
                        (appointment && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "cancelled" &&
                        appointment.reschedule && appointment.reschedule.date && appointment.reschedule.startTime && appointment.reschedule.endTime) &&
                        <span className="badge ms-2 rounded-pill bg-primary">{moment(appointment.reschedule.date, 'YYYY-MM-DD').format('MMM DD, YYYY ')}({appointment.reschedule.startTime +" - "+appointment.reschedule.endTime})</span>
                    }
                </div>

                <div className="d-flex align-items-center appo_with mb-4">
                    <div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
                        <img alt="Image placeholder" src={configs.IMAGE_URL + '/doctor/' + appointment.doctor.photo.name + '-200x200.' + appointment.doctor.photo.type} />
                    </div>
                    <div className="av-txt">
                        <div className="av_title">{appointment.doctor.fullname ? appointment.doctor.fullname : ''}</div>
                        <div className="av_subtitle opacity-50">{appointment.clinic.name ? appointment.clinic.name : '-'}</div>
                    </div>
                </div>

                <div className="d-flex align-items-center appo_for mb-4">
                    <div className="av-txt">
                        <div className="av_title fw-normal">{pFullname ? pFullname : '-'}</div>
                        <div className="av_subtitle">{appointment.date ? moment(appointment.date).format('MMM DD, YYYY') : '-'} ({appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'})</div>
                    </div>
                </div>

                <div className="short_action">
                    <ul>
                        <li>
                            <button className="btn rounded-circle" onClick={() => { handleLocationPermission(appointment) }}>
                                <span className="material-icons">subdirectory_arrow_right</span>
                            </button>
                        </li>

                        <li>
                            <button className="btn rounded-circle" onClick={() => { window.location.href = `mailto:${'abc@abc.com?subject=SendMail&body=Description'}`; }}>
                                <span className="material-icons">share</span>
                            </button>
                        </li>

                        <li>
                            <button className="btn rounded-circle" onClick={() => { handleChat(appointment) }}>
                                <span className="material-icons">insert_comment</span>
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="card_buttons">
                    {
                        ((appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening?.attempt && appointment.metadata.appointment?.checkin && appointment.metadata.appointment?.checkin?.attempt) ||
                        (appointment && !appointment.covid_pre_screen && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment?.checkin && appointment.metadata.appointment?.checkin?.attempt)) &&
                        <div className="alert alert-warning text-center" style={{height:'30px',paddingTop:'2px'}} role="alert">
                            Wait for clinic to text/call.
                        </div>
                    }
                    {
                        ((appointment.currentStatus === 'requested' || appointment.currentStatus === 'waitlist') && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "cancelled") &&
                        <button type="button" disabled={true} className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3">Waitlisted</button>
                    }
                    {
                        ((((appointment.currentStatus === 'scheduled' || appointment.currentStatus === 'requested' || appointment.currentStatus === 'waitlist') && !(appointment && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation) || appointment && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation?.attempt === false)) && appointment.isenablereschedulelink !== "0") &&
                        <button type="button" className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3"
                            onClick={() => {
                                handleReschedule(appointment)
                            }}
                        >
                            Re-Schedule
                        </button>
                    }

                    {
                        (appointment.currentStatus === 'scheduled' && appointment.metadata && appointment.metadata.appointment && (!appointment.metadata.appointment.confirmation || appointment.metadata.appointment.confirmation.status === "cancelled" )) &&
                        <button type="button" className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3"
                            onClick={() => {
                                setCurrentSelectedAppointment(appointment);
                                setIsConfirmationModal(true);
                                SetStatus('confirm');
                                }}>
                                {
                                    (appointment && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "cancelled") ?
                                        'Keep previous appointment'
                                        :
                                    'Confirm'
                                }
                            </button>
                    }
                    {
                      (appointment && appointment.covid_pre_screen) && (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "confirmed" && appointment.metadata && appointment.metadata.appointment &&
                            (!appointment.metadata.appointment.prescreening || (!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening?.attempt)))) &&
                        <button type="button" className={"btn btn-dark fw-normal fs-7 me-2 btn-sm px-3"}
                                disabled={
                                    (appointment.date
                                        && appointment.time && Math.floor(moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
                                        ) ? false : true
                                }
                            onClick={(e) => {
                                if (appointment.date
                                    && appointment.time &&
                                    Math.floor(moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
                                     &&
                                    appointment.metadata && appointment.metadata.appointment &&
                                    (!appointment.metadata.appointment.prescreening || (!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening?.attempt)))) {
                                    setCompleteScreeningModal(true);
                                    setCurrentSelectedAppointment(appointment);
                                }
                            }}
                        >Pre-Screening</button>
                    }
                    {
                        (appointment.metadata
                            && appointment.metadata.appointment
                            && appointment.metadata.appointment.prescreening &&
                            appointment.metadata.appointment.prescreening?.attempt &&
                            appointment.metadata.appointment.prescreening.verifiedOn &&
                            (!appointment.metadata.appointment?.checkin || (!(appointment.metadata.appointment?.checkin && appointment.metadata.appointment?.checkin?.attempt)))
                            ||
                            (appointment && !appointment.covid_pre_screen && (!appointment.metadata.appointment?.checkin || (!(appointment.metadata.appointment?.checkin && appointment.metadata.appointment?.checkin?.attempt))) &&
                              (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment?.confirmation && appointment.metadata.appointment?.confirmation?.status === "confirmed")
                        )) &&
                        <button type="button" className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3"
                                disabled={
                                    // Math.floor(moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) >= 0 &&
                                        Math.floor(moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 60 &&
                                        moment(appointment.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD')) ? false : true
                                }
                            onClick={(e) => {
                                if (Math.floor(moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) >= 0 &&
                                    Math.floor(moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 60  && moment(appointment.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
                                    setCheckInModal(true);
                                    setCurrentSelectedAppointment(appointment);
                                } else {
                                    setCheckInModal(true);
                                    setCurrentSelectedAppointment(appointment);
                                    setCheckInWarning(true);
                                }
                            }}
                        >Check-in</button>
                    }
                </div>
            </div>
        )
    }
    return (
        <>
            {
                (fromDashboard) ?
                    <Carousel
                        className="myappointment_coro"
                        controls={true}
                        interval={null}
                        nextIcon={<i className="bi bi-arrow-right-circle"></i>}
                        prevIcon={<i className="bi bi-arrow-left-circle"></i>}
                    >
                        {appointmentLoop()}
                    </Carousel>
                    : appointmentLoop()
            }

            <SharedOffCanvas
                isOpen={isShowReschedule}
                handleClose={() => { setIsShowReschedule(false) }}
                Children={ReSchedule}
                handleSuccess={() => {
                    setIsShowReschedule(false)
                    // fetchAppointmentApi();
                    handleSuccess();
                }}
                appointment={selectedAppointment}
            />
            {/* <ReSchedule /> */}
            {
                (checkInModal) &&
                <CheckInModal
                    isCheckInWarning={checkInWarning}
                    checkInModal={checkInModal}
                    hideCheckInModal={hideCheckInModal}
                    currentSelectedAppointment={currentSelectedAppointment}
                    checkInSuccess={() => {
                        fetchAppointmentApi();
                        hideCheckInModal();
                        handleSuccess();
                    }}
                />
            }
            {
                (completeScreeningModal) &&
                <PrescreeningModal
                    completeScreeningModal={completeScreeningModal}
                    hadleShowAlert={hadleShowAlert}
                    handleHidePreScreeningModal={handleHidePreScreeningModal}
                    currentSelectedAppointment={currentSelectedAppointment}
                    handleSuccessPreScreening={handleSuccessPreScreening}
                />
            }
            {
                (isConfirmationModal) &&
                <AppointmentConfirmationModal
                    isConfirm={isConfirmationModal}
                    status={status}
                    hideConfirmationModal={hideConfirmationModal}
                    currentSelectedAppointment={currentSelectedAppointment}
                    onClickYes={() => {
                        if (status === 'cancel') {
                            hideConfirmationModal();
                            btnClickCancel();
                        } else {
                            hideConfirmationModal();
                            btnClickConfirm();
                        }
                    }}
                />
            }
        </>
    )
}
