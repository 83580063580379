import { PaymentState, PaymentActionType, PAYMENT } from "./types";

const initialState: PaymentState = {
    error: null,
    pending: false,
}
export const paymentReducer = (state = initialState, action: PaymentActionType): PaymentState => {
    switch (action.type) {
        case PAYMENT.PAYMENT_CLIENT_SECRET:
            return {
                ...state,
                pending: true,
                error: null,
                paymentClientSecretRequest: action.payload,
                paymentClientSecretResponse: undefined
            }
        case PAYMENT.PAYMENT_CLIENT_SECRET_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                paymentClientSecretRequest: undefined,
                paymentClientSecretResponse: action.payload
            }
        case PAYMENT.PAYMNT_CLIENT_SECRET_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                paymentClientSecretRequest: undefined,
                paymentClientSecretResponse: undefined
            }
        default:
            return state;
    }
}