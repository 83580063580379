import React from 'react';
import { emailValidator } from '../../utils/validators';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { PatientDataState, PatientDataResponse, PatientReferRequest } from '../../store/patientData/types';
import { patientReferPending } from '../../store/patientData/actions';
import { getCookie } from '../../utils';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
import { TITLES_LABELS } from '../../constants';
import { Animated } from "react-animated-css";
import apiConfigs from '../../configs/apiConfigs';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
interface Props {
    patientDataState: PatientDataState;
    patientRefer: typeof patientReferPending;
}
interface State {
    emailRefer: string;
    emailReferValidation: { valid: boolean, msg: string };
    patientData?: PatientDataResponse;
    textCopied: boolean;
    showReferByEmail: boolean;
    emails: string[];
}
class Refer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailRefer: '',
            emailReferValidation: { valid: true, msg: '' },
            textCopied: false,
            showReferByEmail: false,
            emails: [],
        }
        this.copyFunction = this.copyFunction.bind(this);
    }
    componentDidMount() {
        if (this.props.patientDataState && this.props.patientDataState.error === null &&
            this.props.patientDataState.patientDataResponse) {
            this.setState({
                patientData: this.props.patientDataState.patientDataResponse,
            });
        }
    }
    handleShareClick() {
        /* if (this.state.emailRefer === "") {
            this.setState({ emailReferValidation: { valid: false, msg: 'Please enter email addresses.' } });
            return;
        }
        let emails: string[] = this.state.emailRefer.split(",");
        emails = emails.map((email) => {
            return email.trim();
        });
        if (emails.length > 5) {
            this.setState({ emailReferValidation: { valid: false, msg: 'Max 5 emails allowed.' } });
            return;
        }
        let isAllowedToRefer = true;
        for (let email of emails) {
            if (!emailValidator(email)) {
                isAllowedToRefer = false;
                this.setState({ emailReferValidation: { valid: false, msg: 'Either of email is not valid, please check.' } })
                break;
            }
        }
        if (isAllowedToRefer) {
            const login: any = getCookie();
            this.setState({ emailReferValidation: { valid: true, msg: '' } });
            this.props.patientRefer({
                // userId: login.id,
                // uniqueId: login.uniqueId,
                uniqueId: login.xpr_user_id,
                inviteEmail: emails.toString()
            });
        } */
        if (this.state.emails.length > 0) {
            const login: any = getCookie();
            this.props.patientRefer({
                uniqueId: login.xpr_user_id,
                inviteEmail: this.state.emails.toString()
            });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            if (nextProps.patientDataState.error !== null) {
                if (nextProps.patientDataState.error.status && nextProps.patientDataState.error.status.error === true) {
                    toast.error(nextProps.patientDataState.error.status.msg);
                } else {
                    toast.error('Error Occured');
                }
            } else {
                if (nextProps.patientDataState.pending === false) {
                    if (nextProps.patientDataState.patientReferResponse &&
                        nextProps.patientDataState.patientReferResponse.status &&
                        nextProps.patientDataState.patientReferResponse.status.error === false) {
                        toast.success(nextProps.patientDataState.patientReferResponse.status.msg);
                        this.setState({ emailRefer: '' });
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.patientDataState.error = null;
        if (this.props.patientDataState.patientReferResponse) {
            this.props.patientDataState.patientReferResponse.status.error = true;
        }
    }
    copyFunction() {
        /* Get the text field */
        var copyText: any = document.getElementById("myInput");
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        /* Copy the text inside the text field */
        document.execCommand("copy");
        this.setState({ textCopied: true });
        /* Alert the copied text */
        // toast.success('Copied to clipboard');
    }
    render() {
        const { emails } = this.state;
        return (

            <>

                <div className="main-middle-sec">
                    <div className="container upcoming-box">
                        <div className="row justify-content-center h-100">
                            <div className="col-md-6 align-self-center">
                                <div className="text-center">
                                    <div className="pin-letter">                                     
                                        <img src={require('../../assets/images/announce.png')} className="img-responsive letter-icon mb-3" />
                                        <h3>Stay tuned, we will rollout <b>My-referrals</b> soon.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            // <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDuration={1000} isVisible={true}>
            //     <section className="refer-page pantient-view">
            //         <Helmet><title>{TITLES_LABELS.META.REFER}</title></Helmet>
            //         <div className="container">
            //             <div className="row justify-content-between">
            //                 <div className="col-md-6 align-self-center">
            //                     <div className="ref_box">
            //                         <div className="title-sec">

            //                             <div className="d-flex">
            //                                 <div className="lbimg"><img src={require('../../assets/images/lootbox-sm.svg')} /></div>
            //                                 <h2>Invite your <strong>best friend</strong> & get <strong className="ref-val">{this.state.patientData ? this.state.patientData.referral + ' Pt.' : ''}</strong></h2>
            //                             </div>
            //                             <h6>Referrals programme</h6>
            //                             <p>Both you and the person you refer get <strong>{this.state.patientData ? this.state.patientData.referral + ' Pt.' : ''}</strong> when they visit their Xcarepro dentist.</p>

            //                             <div className="peql">100 Points = $1</div>
            //                         </div>
            //                         <div className="refBtns">
            //                             <button type="button" className="btn btn-red btn-custom mr-3" onClick={(e) => {
            //                                 this.setState({ showReferByEmail: !this.state.showReferByEmail });
            //                             }}>Invite by email</button>
            //                             <button type="button" className="btn btn-black btn-custom">My Referral</button>
            //                         </div>

            //                         <div className="field-sec">
            //                             {
            //                                 (this.state.showReferByEmail) &&
            //                                 <div className="ref_hid">
            //                                     <label>Send invite email</label>
            //                                     <div className="input-section">
            //                                         <ReactMultiEmail
            //                                             placeholder="Input your emails"
            //                                             emails={emails}
            //                                             onChange={(_emails: string[]) => {
            //                                                 this.setState({ emails: _emails });
            //                                             }}
            //                                             validateEmail={email => {
            //                                                 return isEmail(email);
            //                                             }}
            //                                             getLabel={(
            //                                                 email: string,
            //                                                 index: number,
            //                                                 removeEmail: (index: number) => void,
            //                                             ) => {
            //                                                 return (
            //                                                     <div data-tag key={index}>
            //                                                         <div data-tag-item>
            //                                                             {email}
            //                                                         </div>
            //                                                         <span data-tag-handle onClick={() => removeEmail(index)}>
            //                                                             ×
            //                                                         </span>
            //                                                     </div>
            //                                                 );
            //                                             }}
            //                                         />
            //                                         {/* <input type="text"
            //                                             placeholder="Email of family, friends, etc." className="location-input" value={this.state.emailRefer}
            //                                             onInput={(e: any) => { this.setState({ emailRefer: e.target.value }) }}
            //                                             onChange={(e) => { this.setState({ emailRefer: e.target.value }) }} /> */}

            //                                         {/* <button className="btn" onClick={(e) => { this.handleShareClick() }}>SEND <img src={require('../../assets/images/ic_send_24px.svg')} className="ml-1" /></button>
            //                                     {
            //                                         this.state.emailReferValidation.valid === false &&
            //                                         <div className="text-danger error">{this.state.emailReferValidation.msg}</div>
            //                                     } */}

            //                                         <div className="refBtns">
            //                                             {/* <button type="button" className="btn btn-red btn-custom mr-3">Add More</button> */}
            //                                             <button type="button" className="btn btn-black btn-custom" disabled={this.state.emails.length < 1} onClick={(e) => { this.handleShareClick() }}>Invite</button>
            //                                         </div>

            //                                     </div>
            //                                 </div>
            //                             }
            //                             <div className="copy_lnksn">
            //                                 <label>
            //                                     {
            //                                         (this.state.showReferByEmail === true) ?
            //                                             <>or s</> : <>S</>
            //                                     }
            //                                     hare your link</label>
            //                                 {
            //                                     (this.state.patientData && this.state.patientData.referralCode !== "") ?
            //                                         <div className="link-section">
            //                                             <input id="myInput" type="text" value={apiConfigs.SOURCE_URL + "invite/" + this.state.patientData.referralCode}
            //                                                 className="location-input" readOnly onClick={(e) => { this.copyFunction() }} />
            //                                             {/* <button className="btn btn-custom mt-3" onClick={(e) => { this.copyFunction() }}>Copy link <img src={require('../../assets/images/ic_link_24px.svg')} />
            //                                             </button> */}
            //                                             {
            //                                                 this.state.textCopied === true &&
            //                                                 <span className="copied">Copied</span>
            //                                             }
            //                                         </div>
            //                                         :
            //                                         <div className="link-section">
            //                                             <input id="myInput" type="text" value={""} className="location-input" readOnly onClick={(e) => { this.copyFunction() }} />
            //                                             {/* <button className="btn btn-custom mt-3" onClick={(e) => { this.copyFunction() }}>Copy link <img src={require('../../assets/images/ic_link_24px.svg')} />
            //                                             </button> */}
            //                                             {
            //                                                 this.state.textCopied === true &&
            //                                                 <span className="copied">Copied</span>
            //                                             }
            //                                         </div>
            //                                 }
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className="col-md-6 align-self-center text-center">
            //                     <img src={require('../../assets/images/referal-graphic.svg')} className="referal-graphic" />
            //                 </div>
            //             </div>
            //         </div>
            //     </section>
            // </Animated>
        )
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    patientRefer: (request: PatientReferRequest) => {
        dispatch(patientReferPending(request));
    }
});
const mapStateToProps = (state: IAppState) => ({
    patientDataState: state.patientData,
});
export default connect(mapStateToProps, mapDispatchToProps)(Refer);