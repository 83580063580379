import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";

export const profileUpdate = (data: any, pUniqueId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "updateaccount/" + pUniqueId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}
export const communicationUpdate = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "updatecommunication/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}

export const fetchProfile = () => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "patientaccount/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const uploadProfilePicture = (data: any,patientId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "upload/photo/"+patientId+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        }
    };
    return commonApi(request);
}

export const getTnc = () => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "config/patient_tnc/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}