import { fetchLoadingPending, fetchLoadingSuccess } from './../store/loadingIndicator/actions';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { FORGOT_PASSWORD, FetchForgotPasswordPendingAction, ForgotPasswordRequest } from "../store/forgotpassword/types";
import { fetchForgotPasswordError, fetchForgotPasswordSuccess } from "../store/forgotpassword/actions";

function execute(forgotPassword: ForgotPasswordRequest) {
    const headers = {
        'Content-Type': 'application/json',
        // 'X-Auth-Token': '97e0d315477f435489cf04904c9d0e6co',
    };
    return axios.post(CONFIG.API_URL + "forgot/", forgotPassword, {
        headers: headers,
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* forgotPasswordPendingRun(action: FetchForgotPasswordPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchForgotPasswordError(response.data));
            } else {
                yield put(fetchForgotPasswordSuccess(response.data));
            }
        } else {
            yield put(fetchForgotPasswordError(response))
        }
    }
    if (error) {
        yield put(fetchForgotPasswordError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* forgotPasswordPendingWatcher() {
    yield takeEvery(FORGOT_PASSWORD.LOAD_PENDING, forgotPasswordPendingRun);
}