export const CHAT: any = {
    SENDER: {
        PATIENT: 'patient',
        CLINIC: 'clinic',
        USER: 'user',
        REMINDER: 'reminder',
        ADMIN: 'admin',
    },
    ACTION: {
        SMTC: 'Sent message to clinic.',
        SMTP: 'Sent message to patient.',
        CCP: 'clinic called to patient.',
        PCC: 'patient called to clinic.',
        PBP: 'Prescreening by patient',
        CBP: 'Checkin by patient',
        PVBP: 'Prescreening Verify by patient.',
    },
    ACTION_TYPE: {
        MESSAGE: 'MESSAGE',
        STATUS: 'STATUS',
        RESCHEDULE_SLOTS: 'RESCHEDULE_SLOTS',
        PHONE_CALL: 'PHONE_CALL',
        RECONFIRMATION_SMS: 'RECONFIRMATION_SMS',
        PRESCREENING: 'PRESCREENING',
        CHECKIN: 'CHECKIN',
        PRESCREENING_STATUS: 'PRESCREENING_STATUS',
        PRESCREENING_REQUEST: 'PRESCREENING_REQUEST',
        PAYMENT_REQUEST: 'PAYMENT_REQUEST',
        PAYMENT_SUCCESSFUL: 'PAYMENT_SUCCESSFUL',
        APPONINTMENT_ASSIGN: 'APPONINTMENT_ASSIGN',
        MESSAGE_CENTER_TEXT: 'MESSAGE_CENTER_TEXT',
        CONFIRM_BY_PATIENT: 'CONFIRM_BY_PATIENT',
        CANCELLED_BY_PATIENT: 'CANCELLED_BY_PATIENT',
        MESSAGE_TEXT: 'MESSATE_TEXT',
        TRIAGE_EMERGENCY:'TRIAGE_EMERGENCY'
    },
    FROM_STATUS: {
        CREATED: 'CREATED',
        REQUESTED: 'REQUESTED',
        COMPLETED: 'COMPLETED',
        CANCELLED: 'CANCELLED',
        SCHEDULED: 'SCHEDULED',
    },
    TO_STATUS: {
        CREATED: 'CREATED',
        REQUESTED: 'REQUESTED',
        COMPLETED: 'COMPLETED',
        CANCELLED: 'CANCELLED',
        SCHEDULED: 'SCHEDULED',
    },
    USER_TYPE: {
        PATIENT: 'patient',
        CLINIC: 'clinic',
        PROVIDER: 'provider'
    }
}