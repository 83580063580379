import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
export const AppointmentsTab = () => {

    const location = useLocation();
    const history = useHistory();
    return (
        <section className="middle-navbar px-md-3">
            <div className="container-fluid">
                <div className="d-flex justify-content-between">
                    <div className="w-100">
                        <div className="nav-title-txt">My Appointment</div>
                        <ul className="nav nav-tabs" id="commonTab" role="tablist">
                            {/* <li className="nav-item" role="presentation">
                                <a className={(location.pathname === '/dashboard/requested') ? "active nav-link" : 'nav-link'} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                    aria-selected={(location.pathname === '/dashboard/requested') ? "true" : "false"}
                                    onClick={() => { history.push('/dashboard/requested'); }}

                                >Requested</a>
                            </li> */}
                            <li className="nav-item" role="presentation">
                                <a className={(location.pathname === '/dashboard/upcoming') ? "active nav-link" : 'nav-link'} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                    aria-selected={(location.pathname === '/dashboard/upcoming') ? "true" : "false"}
                                    onClick={() => { history.push('/dashboard/upcoming'); }}

                                >Upcoming</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={(location.pathname === '/dashboard/completed') ? "active nav-link" : 'nav-link'} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                    aria-selected={(location.pathname === '/dashboard/completed') ? 'true' : "false"}
                                    onClick={() => { history.push('/dashboard/completed'); }}
                                >Completed</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={(location.pathname === '/dashboard/cancelled') ? "active nav-link" : 'nav-link'} id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact"
                                    aria-selected={(location.pathname === '/dashboard/cancelled') ? 'true' : "false"}
                                    onClick={() => { history.push('/dashboard/cancelled'); }}
                                >Cancelled</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

