import { FORGOT_PASSWORD, FetchForgotPasswordErrorAction, FetchForgotPasswordPendingAction, FetchForgotPasswordSuccessAction, ForgotPasswordRequest, ForgotPasswordResponse } from './types';
/* Action Creators */
export const fetchForgotPasswordPending = (forgotPassword: ForgotPasswordRequest): FetchForgotPasswordPendingAction => {
    return {
        type: FORGOT_PASSWORD.LOAD_PENDING,
        payload: forgotPassword
    };
};

export const fetchForgotPasswordSuccess = (forgotResponse: ForgotPasswordResponse): FetchForgotPasswordSuccessAction => {
    return {
        type: FORGOT_PASSWORD.LOAD_SUCCESS,
        forgotPassword: forgotResponse
    }
}

export const fetchForgotPasswordError = (err: any): FetchForgotPasswordErrorAction => {
    return {
        type: FORGOT_PASSWORD.LOAD_ERROR,
        error: err
    }
}