import React from 'react';
import './PopUpStyle.css';
import apiConfigs from '../../configs/apiConfigs';
import { Avatar } from '@material-ui/core';
import { allowAlphabetsAndSpace, phoneValidator, emailValidation, allowOnlyDigit } from '../../utils/validators';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { patientDataPending } from '../../store/patientData/actions';
import { PatientDataState, PatientDataRequest } from '../../store/patientData/types';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import axios from 'axios';
import CONFIG from '../../configs/apiConfigs';
import { attachHeader, getCookie } from '../../utils';
import { toast } from 'react-toastify';
import { takeOnlyDigitAndPlus, takeOnlyDigit } from '../../utils/global';
import { AnySrvRecord } from 'dns';
import InputMask from 'react-input-mask';
import { CONTACTMETHODS } from '../../constants';
interface Props {
    showNextPopUp: (clinic: any, selectedAvailability: any, dependantName: string, dependantRelation: string, appointmentFor: string) => void;
    closePopUp: () => void;
    data: any;
    selectedDoctor: any;
    patientDataState: PatientDataState;
    patientData: typeof patientDataPending;
}
interface State {
    selectedAvailability: any;
    dependantName: string;
    dependantRelation: string;
    availabilities: any[];
    appointmentFor: any;
    patientData: any;
    isaddDependant: boolean;
    //Input
    firstName: any;
    lastName: any;
    relation: any;
    email: any;
    dob: any;
    phone: any;
    gender: any;
    contactMethod: any;
    reminder: any;
    //AddedDependant
    addedDependant: any;
}
class PopUpTimingRelation extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedAvailability: '',
            appointmentFor: '',
            dependantName: '', dependantRelation: 'Son',
            availabilities: [{
                id: "1",
                time: "9am - 12pm",
                img: "images/morning.svg",
                value: "Morning"
            }, {
                id: "2",
                time: "12pm - 4pm",
                img: "images/noon.svg",
                value: "afternoon"
            }, {
                id: "3",
                time: "After 4pm",
                img: "images/evening.svg",
                value: "evening"
            }, {
                id: "4",
                time: "Sat - Sun",
                img: "images/weekends.svg",
                value: "weekend"
            }],
            patientData: '', isaddDependant: false,
            firstName: '', lastName: '', relation: "Son", email: '', dob: moment().subtract(1, 'days'),
            phone: '', gender: 'Male', contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: "",
            addedDependant: ''


        }
        this.onAvailabilityChange = this.onAvailabilityChange.bind(this);
    }
    continueClick = () => {
        const dependant = this.state.patientData.find((item: any) => item.id === this.state.appointmentFor);
        if (dependant) {
            this.props.showNextPopUp(this.props.data, this.state.selectedAvailability, this.state.dependantName,
                this.state.dependantRelation, dependant);
        }
    }
    closePopUp = () => {
        this.props.closePopUp();
    }
    onAvailabilityChange(id: string) {
        this.setState({ selectedAvailability: id });
    }
    componentDidMount() {
        console.log('Props', this.props.data)
        if (this.props.selectedDoctor.hours[6].startHour === "Closed" || this.props.selectedDoctor.hours[6].endHour === "Closed" ||
            this.props.selectedDoctor.hours[5].startHour === "Closed" || this.props.selectedDoctor.hours[5].endHour === "Closed") {
            this.setState({
                availabilities: this.state.availabilities.slice(0, this.state.availabilities.length - 1)
            });
        }
        if (this.props.patientDataState.patientDataResponse && this.props.patientDataState.patientDataResponse.dependant) {
            console.log('Datass', this.props.patientDataState.patientDataResponse)
            this.setState({ patientData: this.props.patientDataState.patientDataResponse.dependant, appointmentFor: this.props.patientDataState.patientDataResponse.dependant[0].id })
        }
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props !== nextProps) {
            if (nextProps.patientDataState && nextProps.patientDataState.patientDataResponse &&
                nextProps.patientDataState.patientDataResponse !== this.props.patientDataState.patientDataResponse) {
                this.setState({ patientData: nextProps.patientDataState.patientDataResponse.dependant })
            }
        }

        return true;
    }

    handleChangeDate = (date: any) => {
        this.setState({ dob: date })
    }
    handleAddDependant = () => {

        if (this.state.firstName === '') {
            toast.warn('Please enter valid FirstName.');
            return;
        }
        if (this.state.lastName === '') {
            toast.warn('Please enter valid LastName.');
            return;
        }
        if (this.state.firstName === '') {
            toast.warn('Please enter valid FirstName.');
            return;
        }

        if (!emailValidation(this.state.email)) {
            toast.warn('Please enter valid email.');
            return
        }
        if (this.state.phone.length !== 10) {
            toast.warn('Please enter valid mobile.');
            return
        }
        const body = {
            fname: this.state.firstName,
            lname: this.state.lastName,
            relation: this.state.relation,
            email: this.state.email,
            phone: this.state.phone,
            gender: this.state.gender,
            dob: this.state.dob,
            contactMethod: this.state.contactMethod,
            reminder: this.state.reminder,
        }

        const url = CONFIG.API_URL + 'patient' + '/' + this.props.patientDataState.patientDataResponse?.uniqueId + '/dependant/add/';
        axios.post(url, body, { headers: attachHeader(), withCredentials: true }).then((response) => {
            if (response.status !== 200) {
                throw response;
            } else if (response.data && response.data.status && response.data.status.error === true) {
                throw response.data;
            }
            return response.data;
        }).then((success: any) => {
            console.log('Success', success)
            this.setState({
                addedDependant: success, isaddDependant: false, firstName: '', lastName: '',
                email: '', phone: '', relation: "Son", gender: "Male", dob: moment().subtract(1, 'days'),
                contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: ""
            })
            const login: any = getCookie();
            this.props.patientData({ uniqueId: login.xpr_user_id });
        }).catch((error: any) => {
            console.log(error)
        });
    }

    render() {
        return (

            <div className="step-1">
                <div className="modal-header">
                    <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(); }}>
                        <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                    </button>

                    <div className="media">
                        <div className="media-img mr-3 img-box">
                            {
                                (this.props.data.logo && this.props.data.logo.name && this.props.data.logo.type) ?
                                    <img src={apiConfigs.IMAGE_URL + '/clinic/' + this.props.data.logo.name + '-200x200.' + this.props.data.logo.type} className="img-fluid" /> :
                                    <img src={apiConfigs.DEFAULT_IMAGE_URL_CLINIC} className="img-fluid" />
                            }
                        </div>
                        <div className="media-body align-self-end">
                            <div>Book your appointment with</div>
                            <div className="p-name">{this.props.data.name}</div>
                        </div>
                    </div>

                </div>

                <div className="modal-body">
                    <div className="publp_title">
                        <h6>Please fill below details?</h6>
                    </div>
                    <div className="publp_form">
                        <div className="row d-flex justify-content-center">
                            {
                                this.state.availabilities.map((value, index) => {
                                    return (
                                        <div>
                                            <p className="bookingTime"><input type="radio" name="availability_time"
                                                value={this.state.selectedAvailability}
                                                checked={this.state.selectedAvailability === value.id}
                                                onChange={(e) => { this.onAvailabilityChange(value.id) }} /><span>{value.time}
                                                </span>
                                            </p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="form-group text-center">
                            <label>Appointment For</label>
                            <button className='btn btn-dark ml-3' onClick={() => { this.setState({ isaddDependant: !this.state.isaddDependant }) }}>Add Dependant</button>
                            {!this.state.isaddDependant && <select className="form-control mt-3" value={this.state.appointmentFor} onChange={(e) => {
                                this.setState({ appointmentFor: e.target.value });
                            }}>
                                {this.state.patientData.length > 0 && this.state.patientData.map((item: any) => {
                                    return <option key={item.id} value={item.id}>{item.firstName + " " + item.lastName}</option>
                                })}


                            </select>}
                            {/* <select className="form-control" value={this.state.appointmentFor} onChange={(e) => {
                                this.setState({ appointmentFor: e.target.value });
                            }}>
                                <option value="0">Select appointment for</option>
                                <option value="1">Account Holder</option>
                                <option value="2">Dependent</option>
                            </select> */}
                        </div>
                        {
                            this.state.isaddDependant &&
                            <div className="card">
                                <div className="card-header">
                                    Add new dependant
                                </div>
                                <div className="card-body bg-light">
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input placeholder="First Name" type="text" className="form-control" value={this.state.firstName} onChange={(e) => {
                                                    if (allowAlphabetsAndSpace(e.target.value)) {
                                                        this.setState({ firstName: e.target.value });
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input placeholder="Last Name" type="text" className="form-control" value={this.state.lastName} onChange={(e) => {
                                                    if (allowAlphabetsAndSpace(e.target.value)) {
                                                        this.setState({ lastName: e.target.value });
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Dependent Relation</label>
                                                <select className="form-control" value={this.state.relation} onChange={(e) => {
                                                    this.setState({ relation: e.target.value });
                                                }}>
                                                    <option value="Son">Son</option>
                                                    <option value="Daughter">Daughter</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Spouse">Spouse</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Gender</label>
                                                <select className="form-control" value={this.state.gender} onChange={(e) => {
                                                    this.setState({ gender: e.target.value });
                                                }}>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input placeholder="Email" type="text" className="form-control" value={this.state.email} onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Mobile number</label>
                                                <InputMask mask="9999999999" value={this.state.phone}
                                                    onChange={(e) => {
                                                        this.setState({ phone: e.target.value });
                                                    }}>
                                                    {(inputProps: any) => <input {...inputProps} className="form-control"></input>}
                                                </InputMask>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id={"date-picker-dialog"}
                                                    name={"date-picker-dialog"}
                                                    label="Date of Birth"
                                                    format="YYYY/MM/DD"
                                                    maxDate={moment().subtract(1, 'days')}
                                                    value={moment(this.state.dob)}
                                                    onChange={(date: any) => { this.handleChangeDate(date) }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Contact Method</label>
                                                {/* <select className="form-control" value={this.state.contactMethod} onChange={(e) => {
                                                    this.setState({ contactMethod: e.target.value });
                                                }}>
                                                    <option value="Call">Call</option>
                                                    <option value="Text">Text</option>
                                                    <option value="Text & Email">Text & Email</option>
                                                </select> */}
                                                <div className="form-group">
                                                    <div className="custom-control custom-radio mr-3">
                                                        <input type="radio" id="call" name="customRadio" className="custom-control-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL, reminder: "" }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL ? true : false} />
                                                        <label className="custom-control-label" htmlFor="call">Email Only</label>
                                                    </div>

                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" id="text" name="customRadio" className="custom-control-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.TEXT, reminder: '' }) }} checked={this.state.contactMethod === CONTACTMETHODS.TEXT ? true : false} />
                                                        <label className="custom-control-label" htmlFor="text">Text Only</label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" id="no" name="customRadio" className="custom-control-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: "" }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL_TEXT ? true : false} />
                                                        <label className="custom-control-label" htmlFor="no">Email & Text</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col">
                                            <div className="form-group">
                                                <label>Reminder</label>
                                                <select className="form-control" value={this.state.reminder} onChange={(e) => {
                                                    this.setState({ reminder: e.target.value });
                                                }}>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="form-group addept-btn text-right">
                                        <button className="btn btn-custom" onClick={() => {
                                            this.setState({ isaddDependant: false });
                                        }}>Cancel</button>
                                        <button className="btn btn-dark" onClick={() => { this.handleAddDependant() }}>Submit</button>
                                    </div>

                                </div>
                            </div>

                        }
                        {
                            this.state.appointmentFor === "2" &&
                            <div>
                                <div className="form-group">
                                    <label>Dependent Name</label>
                                    <input type="text" className="form-control" value={this.state.dependantName} onChange={(e) => {
                                        if (allowAlphabetsAndSpace(e.target.value)) {
                                            this.setState({ dependantName: e.target.value });
                                        }
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label>Dependent Relation</label>
                                    <select className="form-control" value={this.state.dependantRelation} onChange={(e) => {
                                        this.setState({ dependantRelation: e.target.value });
                                    }}>
                                        <option value="Son">Son</option>
                                        <option value="Daughter">Daughter</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Father">Father</option>
                                        <option value="Spouse">Spouse</option>
                                    </select>
                                </div>
                                {/* <div className="form-group">
                                    <label>Dependent Relation</label>
                                    <input type="text" className="form-control" value={this.state.dependantRelation} onChange={(e) => {
                                        if (allowAlphabetsAndSpace(e.target.value)) {
                                            this.setState({ dependantRelation: e.target.value });
                                        }
                                    }} />
                                </div> */}
                            </div>
                        }
                        {
                            !this.state.isaddDependant &&
                            <div className="form-group pop_btnsec">
                                <button className="btn btn-custom w-100" disabled={(this.state.selectedAvailability === '' || this.state.appointmentFor === '')} onClick={(e) => { this.continueClick() }}>Continue</button>
                            </div>
                        }
                    </div>

                </div>


            </div>
        );
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    patientData: (request: PatientDataRequest) => {
        dispatch(patientDataPending(request));
    }
});
const mapStateToProps = (state: IAppState) => ({
    patientDataState: state.patientData,
});
export default connect(mapStateToProps, mapDispatchToProps)(PopUpTimingRelation);