import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AddCoverage } from './AddCoverage';
import { EditCoverage } from './EditCoverage';
import { AddSpent } from './AddSpent';
import { ViewCoverage } from './ViewCoverage';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { fetchCoverageDetails } from '../../../services/insuranceCalculator';
import { iteratee } from 'lodash';

export const InsuranceManage = (props: any) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        props.handleClose();
    }
    useEffect(() => {
        if (props.isShow) {
            setShow(true);
            if (userData) {
                setSelectedDependant(userData.dependant[0]);
            }
        }
    }, [props.isShow]);

    const [isShowAddCoverage, setIsShowAddCoverage] = useState(false);
    const [isShowEditCoverage, setIsShowEditCoverage] = useState(false);
    const [isShowAddSpent, setIsShowAddSpent] = useState(false);
    const [isShowViewCoverage, setIsShowViewCoverage] = useState(false);
    const [dependants, setDependants] = useState([]);
    const [selectedDependant, setSelectedDependant]: any = useState('');
    const dispatch = useDispatch();
    const [coverageDetails, setCoverageDetails]: any = useState([]);
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const [selectedCoverage, setSelectedCoverage]:any = useState('');

    useEffect(() => {
        if (userData && userData.dependant && userData.dependant.length > 0) {
            setDependants(userData.dependant);
            setSelectedDependant(userData.dependant[0]);
        }
    }, [userData]);

    useEffect(() => {
        if (selectedDependant) {
            fetchCoverageData();
        }
    },[selectedDependant]);

    const fetchCoverageData = () => {
        if (selectedDependant && selectedDependant.id) {
            dispatch(fetchLoadingPending());
            fetchCoverageDetails(selectedDependant.id).then((success:any) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.status && success.status.error === false) {
                    setCoverageDetails(success.coverage);
                }
            }).catch((err:any) => {
                dispatch(fetchLoadingSuccess(false));
                if (err.error) {
                    setCoverageDetails([]);
                }
                console.log(err);
            })
        }
    }

    const onChnageDependant = (e:any) => {
        let tempDependant = userData.dependant.find((dependant: any) => dependant.id === e.target.value);
        if (tempDependant) {
            setSelectedDependant(tempDependant);
        }
    }

    

    return (
        <>
            <Offcanvas className="insurance_manage" placement="end" show={show} onHide={handleClose}>
                <div className="offcanvas-action d-flex">
                    <button type="button" className="btn btn-link p-0 fw-normal fs-7"
                        onClick={() => { setIsShowAddCoverage(true) }}
                    >
                        Add Coverage
                    </button>

                    <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                        onClick={() => { handleClose() }}>
                        Done
                    </button>
                </div>

                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">Insurance Details</h5>
                </div>
                <div className="offcanvas-other">
                    <div className="mb-3">
                        <label className="form-label">Dependent</label>
                        <select className="form-select" name="dependantid" onChange={onChnageDependant}>
                            {
                                (dependants && dependants.length > 0) &&
                                dependants.map((dependant: any) => {
                                    return (
                                        <option key={dependant.id} value={dependant.id}>{dependant.firstName +" "+ dependant.lastName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    {/* <div className="card px-4 py-3 bg-light">
                        <div className="fs-5 fw-medium">
                            Green Shield Canada
                        </div>
                        <div className="fs-7 opacity-50">
                            Plan - Flex 10 Plan
                        </div>
                    </div> */}
                    {
                        (coverageDetails && coverageDetails.length > 0) &&
                        <div className="d-flex mt-3">
                            <button className="flex-fill me-2 btn btn-dark fw-normal fs-7" onClick={() => { setIsShowViewCoverage(true) }} >View Expense</button>
                            <button className="flex-fill btn btn-dark fw-normal fs-7" onClick={() => { setIsShowAddSpent(true) }}>Add Expense</button>
                        </div>
                    }
                </div>
                <div className="offcanvas-body">
                    {
                        (coverageDetails && coverageDetails.length === 0) &&
                        <div className="alert alert-warning mb-4 text-center" role="alert">
                            You don't have any coverage details, please add coverage details.
                        </div>
                    }
                    <div className="coverages_list">
                        {
                            (coverageDetails && coverageDetails.length > 0) &&
                            coverageDetails.map((coverage: any) => {
                                return (
                                    <>
                                        <div key={coverage.id} className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="coverage_name">
                                                {coverage.name} Coverage
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => { setIsShowEditCoverage(true);setSelectedCoverage(coverage) }}>
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            (coverage && coverage.details.length > 0) &&
                                            coverage.details.map((item: any) => {
                                                return (
                                                    <div key={item.id+item.name} className="coverage_item">
                                                        <div className="d-flex justify-content-end fs-5 fw-medium">
                                                            ${item.totalExpense ? item.totalExpense : 0}.00
                                                        </div>

                                                        <div className="progress my-2" style={{ height: "3px" }} >
                                                            <div className="progress-bar" style={{ width: (item.totalExpense/item.amount)*100+"%" }} ></div>
                                                        </div>

                                                        <div className="d-flex align-items-start justify-content-between">
                                                            <div className="fs-7 opacity-50">{item.name}</div>
                                                            <div className="fs-7 opacity-50">${item.amount}.00</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </Offcanvas>
            {/* <AddCoverage isShow={isShowAddCoverage} handleClose={() => { setIsShowAddCoverage(false) }} /> */}
            {/* <EditCoverage isShow={isShowEditCoverage} handleClose={() => { setIsShowEditCoverage(false) }} /> */}
            {/* <AddSpent isShow={isShowAddSpent} handleClose={() => { setIsShowAddSpent(false) }} /> */}
            {/* <ViewCoverage isShow={isShowViewCoverage} handleClose={() => { setIsShowViewCoverage(false) }} /> */}

            <SharedOffCanvas
                isOpen={isShowAddCoverage}
                handleClose={() => { setIsShowAddCoverage(false) }}
                Children={AddCoverage}
                handleSuccess={() => {
                    setIsShowAddCoverage(false);
                    fetchCoverageData();
                }}
                dependant={selectedDependant}
            />
            <SharedOffCanvas
                isOpen={isShowAddSpent}
                handleClose={() => { setIsShowAddSpent(false) }}
                Children={AddSpent}
                handleSuccess={() => {
                    setIsShowAddSpent(false);
                    fetchCoverageData();
                }}
                dependant={selectedDependant}
            />
            <SharedOffCanvas
                isOpen={isShowViewCoverage}
                handleClose={() => { setIsShowViewCoverage(false) }}
                Children={ViewCoverage}
                handleSuccess={() => {
                    setIsShowViewCoverage(false);
                    fetchCoverageData();
                }}
                dependant={selectedDependant}
                // coverageDetails={coverageDetails}
                coverageData={coverageDetails}
                handleApiCall={() => {
                    fetchCoverageData();
                }}
            />
            <SharedOffCanvas
                isOpen={isShowEditCoverage}
                handleClose={() => { setIsShowEditCoverage(false) }}
                Children={EditCoverage}
                handleSuccess={() => {
                    setIsShowEditCoverage(false);
                    fetchCoverageData();
                }}
                dependant={selectedDependant}
                coverageDetails={selectedCoverage}
            />
        </>
    )
}
