import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchAppointment } from '../../services/appointment';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../pages/loadingIndicator/LoadingIndicator';
import configs from '../../configs/apiConfigs';
export const Cancelled = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [appointmentList, setAppointmentList] = useState([]);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const history = useHistory();
    const [isNoRecord, setIsNoRecord] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        fetchAppointment(patientData.uniqueId, 'cancelled').then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setAppointmentList(success.appointments);
                if (success.appointments && success.appointments.length <= 0) {
                    setIsNoRecord(true);
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }, []);
    return (
        <section className="px-md-3 appointment-sec">
            {
                isLoading &&
                <LoadingIndicator />
            }
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="appUpcoming">
                            {(appointmentList && appointmentList.length > 0) ?
                                appointmentList.map((appointment: any, index: number) => {
                                    let endTime: any = '';
                                    if (appointment.time && appointment.duration) {
                                        endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                                    }
                                    let pFullname = '';
                                    if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
                                        pFullname = appointment.dependant.firstName + " " + appointment.dependant.lastName;
                                    }
                                    return (
                                        <div key={index + Math.random() + 'appointment'} className="d-md-flex d-block appDataBox rounded p-3 justify-content-between my-4">
                                            <div className="leftSec d-flex" onClick={() => {
                                                history.push('/appointmentdetail/' + appointment.uniqueid)
                                            }}>
                                                <div className="dateBox primary-badge py-3 px-md-3 px-2  me-md-3 me-2">
                                                    <div className="dayVal text-center pt-1">{appointment.date ? moment(appointment.date).format('DD') : '-'}</div>
                                                    <div className="monVal text-center text-muted">{appointment.date ? moment(appointment.date).format('MMM') : '-'}</div>
                                                </div>
                                                <div className="detailBox d-block">
                                                    <span className="badge rounded-pill bg-primary text-white text-capitalize">{appointment.currentStatus ? appointment.currentStatus : '-'}</span>
                                                    <div className="detailTxt my-1">{appointment.appointmentType ? appointment.appointmentType : '-'} with {appointment.doctor.fullname ? appointment.doctor.fullname : ''} - ({appointment.doctor.speciality})</div>
                                                    <div className="d-block justify-content-between">
                                                        <div className="text-muted me-3">{appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'}  {appointment.date ? "(" + moment(appointment.date).format('MMM DD, YYYY') + ")" : '-'}</div>
                                                        <div className="text-muted">Clinic: <span>{appointment.clinic.name ? appointment.clinic.name : '-'}</span></div>
                                                    </div>
                                                    <div className="text-muted">Apt. for - <span>{pFullname ? pFullname : '-'}</span></div>
                                                </div>
                                            </div>
                                            <div className="rightSec d-flex mt-xl-0 m-lg-3 ">
                                                <div className={"align-self-center btn btn-outline-primary"} onClick={() => {
                                                    history.push('/search-provider');
                                                }}>
                                                    Book Again
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : isNoRecord &&
                                <div className="d-flex appDataBox rounded p-3 justify-content-between my-4">
                                    No record found.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
