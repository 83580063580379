import { AppointmentDetailState, AppointmentDetailTypes, APPOINTMENT } from "./types";

export const initialState: AppointmentDetailState = {
    pending: false,
    error: null
};
export const appointmentDetailReducer = (state = initialState, action: AppointmentDetailTypes) => {
    switch (action.type) {
        case APPOINTMENT.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case APPOINTMENT.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                appointmentDetailRequest: action.payload
            }
        case APPOINTMENT.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                appointmentDetailResponse: action.appointmentDetail
            }
        case APPOINTMENT.CHANGE_APPOINTMENT_REMIDER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case APPOINTMENT.CHANGE_APPOINTMENT_REMINDER_PENDING:
            return {
                ...state,
                pending: true,
                changeAppointmentReminderRequest: action.payload
            }
        case APPOINTMENT.CHANGE_APPOINTMENT_REMINDER_SUCCESS:
            if (state.appointmentDetailResponse) {
                return {
                    ...state,
                    pending: false,
                    changeApppointmentRemiderResponse: action.changeAppointmentRemider,
                    appointmentDetailResponse: {
                        ...state.appointmentDetailResponse,
                        status: {
                            ...state.appointmentDetailResponse.status,
                            error: false,
                        },
                        metadata: {
                            ...state.appointmentDetailResponse.metadata,
                            patient: {
                                ...state.appointmentDetailResponse.metadata.patient,
                                reminder: action.changeAppointmentRemider.reminder
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                pending: false,
                changeApppointmentRemiderResponse: action.changeAppointmentRemider,
            }
        case APPOINTMENT.WRITE_REVIEW_PENDING:
            return {
                pending: true,
                error: null,
                writeReviewRequest: action.payload
            }
        case APPOINTMENT.WRITE_REVIEW_ERROR:
            return {
                pending: false,
                error: action.payload,
                writeReviewRequest: undefined,
                writeReviewResponse: undefined
            }
        case APPOINTMENT.WRITE_REVIEW_SUCCESS:
            return {
                pending: false,
                error: null,
                writeReviewRequest: undefined,
                writeReviewResponse: action.payload
            }
        case APPOINTMENT.ASSIGN_APPOINTMENT:
            return {
                ...state,
                pending: true,
                error: null,
                assignAppointmentRequest: action.payload,
                assignAppointmentResponse: undefined
            }
        case APPOINTMENT.ASSIGN_APPOINTMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                assignAppointmentRequest: undefined,
                assignAppointmentResponse: undefined
            }
        case APPOINTMENT.ASSIGN_APPOINTMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                assignAppointmentRequest: undefined,
                assignAppointmentResponse: action.payload
            }
        default:
            return state;
    }
}