import React, { useEffect, useState } from 'react'
import { InsuranceManage } from './InsuranceManage';

export const Insurance = (props: any) => {

    const [isShowInsuranceManage, setIsShowInsuranceManage] = useState(false);

    return (
        <>

            <div className="card_item insurance_c card p-3">
                <div className="infocard_header mb-2">
                    <div className="card_ititle">Personal Insurance calculator</div>
                    <div className="card_desc opacity-75">
                        Keep track of your insurance spend
                        {/* <label>Coming soon..</label> */}
                    </div>
                </div>
                <div className="card_ilink">
                    {/* <label>Coming soon...</label> */}
                    <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => { setIsShowInsuranceManage(true) }}>Manage Insurance Coverage</button>
                </div>
            </div>

            {/* <div className="card_item insurance_c card p-3">
                <div className="infocard_header d-flex justify-content-between mb-3">
                    <div className="card_ititle">Insurance calculator</div>
                    <div className="card_ilink">
                        <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => { setIsShowInsuranceManage(true) }}>Manage</button>
                    </div>
                </div>
                <div className="infocard_body">
                    <div className="d-flex">
                        <div className="rewardsp me-3 pe-3 border-end">
                            <div className="fs-7">Spent amt.</div>
                            <div className="fs-4">$1500.00</div>
                        </div>

                        <div className="loyalp">
                            <div className="fs-7">Balance amt.</div>
                            <div className="fs-4">$4000.00</div>
                        </div>
                    </div>
                </div>
            </div> */}

            <InsuranceManage isShow={isShowInsuranceManage} handleClose={() => { setIsShowInsuranceManage(false) }} />
        </>
    )
}
