import React, { useState, useEffect } from 'react';
//import '../../assets/scss/new-ui.scss';
import { Cancelled } from '../../components/appointments/Cancelled';
import { Completed } from '../../components/appointments/Completed';
import { Upcoming } from '../../components/appointments/Upcoming';
import { AppointmentsTab } from '../appointments/Appointments';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { Requested } from '../../components/appointments/Requested';
export const Dashboard = () => {
    return (
        <>
            <div className="main-middle-sec" id="main-middle">
                <AppointmentsTab />
                <Switch>
                    <Route path="/dashboard/upcoming" component={Upcoming}></Route>
                    {/* <Route path="/dashboard/requested" component={Requested}></Route> */}
                    <Route path="/dashboard/cancelled" component={Cancelled}></Route>
                    <Route path="/dashboard/completed" component={Completed}></Route>
                </Switch>
            </div>
        </>
    )
}