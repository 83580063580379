import { ProviderState, ProviderActionTypes, PROVIDER } from "./types";

const initialState: ProviderState = {
    error: null,
    pending: false
}
export const providerReducer = (state = initialState, action: ProviderActionTypes): ProviderState => {
    switch (action.type) {
        case PROVIDER.SEARCH_PROVIDER_PENDING:
            return {
                pending: true,
                error: null,
                providerSearchRequest: action.payload,
                providerSearchResponse: undefined
            }
        case PROVIDER.SEARCH_PROVIDER_PENDING_ERROR:
            return {
                pending: false,
                error: action.payload,
                providerSearchResponse: undefined,
                providerSearchRequest: undefined
            }
        case PROVIDER.SEARCH_PROVIDER_PENDING_SUCCESS:
            return {
                pending: false,
                error: null,
                providerSearchRequest: undefined,
                providerSearchResponse: action.payload
            }
        default:
            return state;
    }
}