import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';

export const AppointmentConfirmationModal = ({ isConfirm, status, hideConfirmationModal, currentSelectedAppointment, onClickYes }: any) => {
    return (
        <Modal className="newcommon" show={isConfirm} onHide={hideConfirmationModal} backdrop={true} keyboard={true} centered>
            {
                Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) < 0 &&
                <Modal.Header>
                    <div className="modal-title">Confirm</div>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}>
                    </button>
                </Modal.Header>
            }
            <Modal.Body>
                <div className="d-flex justify-content-center btns-box">
                    {
                        Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) < 0
                            ?
                            <div className="modal-title">{`Appointment is in the past, please contact clinic admin.`}</div>
                            :
                            (currentSelectedAppointment && currentSelectedAppointment.metadata && currentSelectedAppointment.metadata.appointment && currentSelectedAppointment.metadata.appointment.confirmation && currentSelectedAppointment.metadata.appointment.confirmation.status === "cancelled") ?
                                <div className="modal-title">{`Are you sure you want to keep your previous appointment?`}</div>
                                :
                                <div className="modal-title">{`Are you sure you want to ${status} this appointment ?`}</div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                {
                    !(Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) < 0) &&
                        <>
                            <button onClick={onClickYes} className="btn btn-dark mx-2 px-3">Yes</button>
                            <button onClick={hideConfirmationModal} className="btn btn-dark mx-2 px-3">No</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}
