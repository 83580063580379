import React from 'react'

export const EditMember = () => {
    return (
        <div className="offcanvas offcanvas-end my_family_edit" tabIndex={-1} id="my_family_edit" aria-labelledby="my_family_edit">

            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#my_family"
                    aria-controls="my_family"
                >
                    Save
                </button>
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#my_family"
                    aria-controls="my_family"
                >
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Edit member</h5>
            </div>
            <div className="offcanvas-body">

                <div className="add_member_form">
                    <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <input type="text" className="form-control" id="" placeholder="Patient first name" />
                        <div className="form-error">
                            Please select a valid content.
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <input type="text" className="form-control" id="" placeholder="Patient Patient last name" />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" className="form-control" id="" placeholder="Enter email" />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Mobile #</label>
                        <input type="text" className="form-control" id="" placeholder="Enter mobile #" />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Gender</label>
                        <select className="form-select">
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Relation</label>
                        <select className="form-select">
                            <option value="1">Spouse</option>
                            <option value="2">Son</option>
                            <option value="2">Daughter</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Date of birth</label>
                        <input type="date" className="form-control" id="" placeholder="Date of birth MM/DD/YYYY" />
                    </div>

                    <div className="mb-3">
                        <div className="d-block">
                            <label className="form-label">Preferred contact method</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="pre_email" value="option1" />
                            <label className="form-check-label" htmlFor="pre_email">Email</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="pre_text" value="option2" />
                            <label className="form-check-label" htmlFor="pre_text">Text</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="pre_both" value="option3" />
                            <label className="form-check-label" htmlFor="pre_both">Text & Email</label>
                        </div>

                    </div>

                    <div className="mb-3">
                        <div className="d-block">
                            <label className="form-label">Create sign in for new member</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="signin_yes" value="option1" />
                            <label className="form-check-label" htmlFor="signin_yes">Yes</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="signin_no" value="option2" />
                            <label className="form-check-label" htmlFor="signin_no">No</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
