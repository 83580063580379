import React, { useState, useEffect, useMemo } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReferFriendList } from '../xcarepoints/ReferFriendList';
import { ReferByEmailSms } from './ReferByEmailSms';
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
import { getLoyaltyActionList } from '../../../services/loyalty';
import moment from 'moment';

export const LoyaltyActivity = ({handleModalClose, handleSuccess}:any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const currentDependant = useMemo(() => {
        const ID = patientData.id;
        return patientData?.dependant?.find((item: any) => Number(item.loginId) === Number(ID));
    }, [patientData]);
    const joinLoyaltyProgram = async () => {
        try {
            setIsLoading(true);
            const success = await getLoyaltyActionList(currentDependant?.id);
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setList(success.data);
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    };
    useEffect(() => {
        joinLoyaltyProgram();
    },[]);
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Points Activity</h5>
            </div>

            <div className="offcanvas-body">
            {
            isLoading ? 'Loading...' :
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Description</th>
                        <th>Points</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list?.map((item: any) => {
                                return (
                                    <tr>
                                        <td>{moment(item.createdAt).format("DD MMM, YYYY")}</td>
                                        <td>{item.type}</td>
                                        <td>{item.code}</td>
                                        <td>{item.points}</td>
                                        <td>-</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                }
            </div>
        </>
    )
}
