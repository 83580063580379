import {
    PaymentClientSecretRequest, PaymentClientSecretPendingAction, PAYMENT, PaymentClientSecretResponse,
    PaymentClientScretSuccessAction,
    PaymentClientSecretErrorAction
} from "./types";

export const paymentClientSecretPendingAction = (request: PaymentClientSecretRequest): PaymentClientSecretPendingAction => {
    return {
        type: PAYMENT.PAYMENT_CLIENT_SECRET,
        payload: request
    }
}
export const paymentClientSecretSuccessAction = (response: PaymentClientSecretResponse): PaymentClientScretSuccessAction => {
    return {
        type: PAYMENT.PAYMENT_CLIENT_SECRET_SUCCESS,
        payload: response
    }
}
export const paymentClientSecretErrorAction = (error: any): PaymentClientSecretErrorAction => {
    return {
        type: PAYMENT.PAYMNT_CLIENT_SECRET_ERROR,
        payload: error
    }
}