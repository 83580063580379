import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { GroupList } from './GroupList';
import { Messages } from './Messages';

export const GroupChat = () => {
    const [selectedGroup, setSelectedGroup]: any = useState('');
    const history = useHistory();
    const handleSelectedGroup = (clinic: any) => {
        setSelectedGroup(clinic);
    }
    return (
        <>
            <div className="main-middle-sec ">
                <div className="container-fluid clinic-chat-sec">
                    <div className="row">
                        <div className="col-12 col-md-3 border-end chatlist-panel px-0">
                            <div className="p-3 pb-2">
                                <div className="fs-5 fw-medium mb-2">Group List</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="chat-tab-links">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <button className="nav-link" type="button" onClick={() => {
                                                history.push('/chat/clinic');
                                            }}>Clinic</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link active" type="button" onClick={() => {
                                                history.push('/chat/group');
                                            }}>Group</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div id="userListBox">
                                    <div className="infinite-scroll-component__outerdiv">
                                        <div className="infinite-scroll-component ">
                                            <ul className="list-group">
                                                <GroupList
                                                    handleSelectedGroup={(clinic: any) => { handleSelectedGroup(clinic) }}
                                                    selectedGroup={selectedGroup}
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedGroup !== '' &&
                            <Messages group={selectedGroup} />}
                    </div>

                </div>
            </div>
        </>
    )
}