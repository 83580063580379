import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { sendDigitalDocEmail, uploadPDF } from '../../../services/digitalForm';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
export const DigitalFormUpload = ({ handleModalClose, handleSuccess,clinicList }: any) => {

    const dispatch = useDispatch();
    const [selectedClinicId, setSelectedClinicId] = useState('');
    const [fileName, setFileName] = useState('');
    const [documentpath, setDocumentpath] = useState('');
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);

    useEffect(() => {
        if (clinicList && clinicList.length > 0) {
            setSelectedClinicId(clinicList[0].id);
        }
    },[clinicList]);
    
    const handleOnChangeFile = (files: any) => {
      
        if (files && files[0] && files[0].size && files[0].size > 104857600) {
            toast.error('Max file size 100 MB.');
            return;
        }
        if (files[0].type === "application/pdf") {
            uploadPDF({ upload: files[0] }).then((success) => {
                if (success && success.error === false) {
                    setDocumentpath(success.data);
                }
            }).catch((err) => {
                toast.error('document upload failed.');
            })
        } else {
            toast.error('File extension is not valid, allowed pdf');
            return;
        }
        if (files && files[0] && files[0].name) {
            setFileName(files[0].name);
        }
    }

    const onSubmit = () => {
        console.log('file submited');
        if (documentpath && userData && userData.id && selectedClinicId) {
            const request = {
                "clinicid": Number(selectedClinicId),
                "patientid": Number(userData.id),
                "documentpath": [documentpath]
            }
            dispatch(fetchLoadingPending());
            sendDigitalDocEmail(request).then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.code === 200 && success.error === false) {
                    toast.success(success.message);
                    handleModalClose();
                }
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                console.log(err);
            })
        }
    }
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    onClick={() => { handleModalClose() }}>
                    Cancel
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Upload Completed Form</h5>
            </div>

            <div className="offcanvas-body">

                <div className="alert alert-warning mb-4 text-center" role="alert">
                    Please upload your completed form here. Make sure all the mandatory fields are completed before you upload the form. Thank you !!!
                </div>
                <div className="mb-3">
                    <label className="form-label">Clinic</label>
                    <select className="form-select" value={selectedClinicId} onChange={(e:any)=>{setSelectedClinicId(e.target.value)}}>
                        {
                            (clinicList && clinicList.length > 0) &&
                            clinicList.map((clinic: any) => {
                                return (
                                    <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="mb-3">
                    <div className="fs-7 opacity-50 mb-2">{fileName ? fileName:''}</div>
                    <input type="file" className="btn btn-upload w-100 fw-normal" placeholder="Upload Completed Form" accept="application/pdf" onChange={(e:any)=>{handleOnChangeFile(e.target.files)}} />
                    <div className="fs-7 opacity-50 text-end mt-2">Max file size 100 MB.</div>
                </div>

                <div className="mb-3">
                    <button disabled={!documentpath || !selectedClinicId} type="button" className="btn btn-dark w-100 fw-normal" onClick={()=>{onSubmit()}}>
                        Submit
                    </button>
                </div>
            </div>
        </>
    )
}
