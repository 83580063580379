import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginContainer from './containers/LoginContainer/LoginContainer';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainContainer from './containers/MainContainer/MainContainer';
import { Provider, useSelector } from 'react-redux';
import configureStore, { IAppState } from './store';
import './configs/firebaseConfigs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Landing from './components/landing/Landing';
import Carousel from 'react-bootstrap/Carousel';
import { Login } from './v2/pages/auth/login';
import { ForgotPassword } from './v2/pages/auth/forgotpassword';
import { Register } from './v2/pages/auth/register';
import { ForgotPasswordOtp } from './v2/pages/auth/forgotpasswordotp';
import { ResetPassword } from './v2/pages/auth/resetpassword';
//import './v2/assets/scss/new-ui.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './v2/assets/scss/v3.scss';
import AppointmentDetail from './v2/pages/appointmentDetail/AppointmentDetail';
toast.configure({ closeButton: false, closeOnClick: true, className: 'toaster', autoClose: 1500 });
const store = configureStore();
const AppMain = (props: any) => {
    const showWaitingPresc = useSelector((state: IAppState) => state.auth.showWaitingPresc);
    const showWaitingPayment = useSelector((state: IAppState) => state.auth.showWaitingPayment);
    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router>
                    <Switch>
                        <Route exact path="/landing" component={Landing}></Route>
                        <Route exact path="/login" component={Login}></Route>
                        <Route exact path="/forgot-password" component={ForgotPassword}></Route>
                        <Route exact path="/forgot-password-otp/:id" component={ForgotPasswordOtp}></Route>
                        <Route exact path="/reset-password/:id" component={ResetPassword}></Route>
                        <Route exact path="/register" component={Register}></Route>
                        <Route path="/" render={(props: any) => <ErrorBoundary><MainContainer {...props}></MainContainer> </ErrorBoundary>}></Route>
                    </Switch>
                </Router>
            </MuiPickersUtilsProvider>
            {
                showWaitingPresc &&
                <div className="waiting_presc">
                    <div className="container h-100">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="wpinner">
                                <div className="spinner-grow text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <Carousel fade={true} interval={700}>
                                    <Carousel.Item>
                                        <p>Preparing your pre-screening questions</p>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <p>Using XCare allows clinics to keep staff and patients safe</p>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <p>Enabling virtual check-in feature</p>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <p>With Xcare manage your appointments, payments and more</p>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <p>Have questions? Contact support@xcarepro.com for help at any time</p>
                                    </Carousel.Item>

                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                showWaitingPayment &&
                <div className="waiting_presc">
                    <div className="container h-100">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="wpinner">
                                <div className="spinner-grow text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};
const Routing = (
    <Provider store={store}>
        <AppMain></AppMain>
    </Provider >
);
ReactDOM.render(Routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();