import { all } from 'redux-saga/effects';
import loginPendingWatcher, { logoutWatcher, registerPendingWatcher } from './auth';
import { patientDataPendingWatcher, patientReferPendingWatcher, emailVerificationPendingWatcher, removeDefaultClinicPendingWatcher, addDefaultClinicPendingWatcher } from './patientData';
import { accountUpdatePendingWatcher } from './account';
import { claimSubmitPendingWatcher } from './claim';
import forgotPasswordPendingWatcher from './forgotPassword';
import { appointmentDetailPendingWatcher, assignAppointmentPendingWatcher, changeAppointmentReminderPendingWatcher, writePendingWatcher } from './appointment';
import { paymentClientSecretPendingWatcher } from './payment';
import { searchProviderPendingWatcher } from './provider';
export default function* rootSaga() {
    yield all([
        loginPendingWatcher(), logoutWatcher(), patientDataPendingWatcher(),
        accountUpdatePendingWatcher(), patientReferPendingWatcher(),
        claimSubmitPendingWatcher(), forgotPasswordPendingWatcher(),
        emailVerificationPendingWatcher(),
        appointmentDetailPendingWatcher(), changeAppointmentReminderPendingWatcher(),
        paymentClientSecretPendingWatcher(), registerPendingWatcher(), searchProviderPendingWatcher(),
        removeDefaultClinicPendingWatcher(), addDefaultClinicPendingWatcher(), writePendingWatcher(),
        assignAppointmentPendingWatcher()
    ]);
}