import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userLogout } from '../../../store/auth/actions';
export const PrescreeningModalForPastAppointment = ({ isShow, handleClose, medium }: any) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (medium === 'email' || medium === 'sms') {
            dispatch(userLogout());
        }
    },[]);
    return (
        <Modal centered className="newcommon model_covid modal-background-blur" backdrop={false} show={isShow} >
            <Modal.Header>
                <div className="modal-title">Prescreening appointment</div>
                {
                    (medium !== 'email' && medium !== 'sms') &&
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                        handleClose()
                    }}>
                    </button>
                }
            </Modal.Header>
            <Modal.Body>
                <>
                    <p>Oops! Looks like you are trying to complete pre-screening for an appointment which appears to be in the past or future. You can complete pre-screening 1 to 23 hours before your appointment. Get in touch with the clinic for further assistance.</p>
                    {(medium === 'email' || medium === 'sms') && <p className='text-danger'>Note: Please close this tab from the browser. </p>}
                </>
            </Modal.Body>
        </Modal>
    )
}