import React from 'react';
import './PopUpStyle.css';
import apiConfigs from '../../configs/apiConfigs';
import Select from 'react-select';
import { Avatar } from '@material-ui/core';
import { attachHeader, getCookie } from '../../utils';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import Axios from 'axios';
import configs from '../../configs/apiConfigs';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { PatientDataRequest } from '../../store/patientData/types';
import { patientDataPending } from '../../store/patientData/actions';
import { userLogout } from '../../store/auth/actions';
interface Props {
    showNextPopUp: (selectedAppointment: any, noteForOffice: any) => void;
    closePopUp: () => void;
    data: any;
    doctor: any;
    isNewPatient: boolean;
    dependantName: string;
    dependantRelation: string;
    selectedAvailability: string;
    appointmentFor: any;
    isExistingClinic: boolean;
    patientId: string;
    patientUniqueId: string;
    patientData: typeof patientDataPending;
    logout: typeof userLogout;
    history: any;
}
interface State {
    selectedAppointment: any;
    selectedAppointmentValue: any;
    noteForOffice: any;
    popUpTwoLoader: boolean;
}
class PopUpTwo extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedAppointment: props.doctor.potentialAppointmentTypes[0] ? props.doctor.potentialAppointmentTypes[0].id : '',
            selectedAppointmentValue: props.doctor.potentialAppointmentTypes[0] ? props.doctor.potentialAppointmentTypes[0] : '',
            noteForOffice: '', popUpTwoLoader: false
        }
        this.submitAppointment = this.submitAppointment.bind(this);
    }
    continueClick = (selectedAppointment: any, noteForOffice: any) => {
        this.props.showNextPopUp(selectedAppointment, noteForOffice);
    }
    closePopUp = () => {
        this.props.closePopUp();
    }
    submitAppointment() {
        this.setState({ popUpTwoLoader: true });
        const clinic = {
            id: this.props.data.id,
            doctor: {
                id: this.props.doctor ? this.props.doctor.id : ''
            }
        };
        const appointmentType = {
            id: this.state.selectedAppointment ? this.state.selectedAppointment.id : ''
        }
        const request = {
            isNewPatient: this.props.isNewPatient,
            isExistingClinic: this.props.isExistingClinic,
            isFromApp: true,
            patient: {
                id: this.props.patientId,
                uniqueId: this.props.patientUniqueId
            },
            clinic: clinic,
            appointmenttype: {
                id: this.state.selectedAppointmentValue.id
            },
            request: {
                isNewPatient: this.props.isNewPatient,
                additionalNote: this.state.noteForOffice,
                // dependant: {id: ""},
                dependantName: this.props.dependantName,
                dependantRelation: this.props.dependantRelation
            },
            dependant: {
                id: this.props.appointmentFor.id
            }
        }
        this.appointmentRequest(request);
    }
    render() {
        console.log('State', this.props);
        let doctorName = this.props.doctor.fullname.replace("Dr.", "");
        doctorName = doctorName.trim();
        doctorName = doctorName.split(" ");
        const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
        const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
        return (
            <div className="step-2">
                <div className="modal-header">
                    <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(); }}>
                        <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                    </button>

                    <div className="media">
                        <div className="media-img mr-3 img-box">
                            {
                                (this.props.doctor.photo && this.props.doctor.photo.name && this.props.doctor.photo.type) ?
                                    <img src={apiConfigs.IMAGE_URL + '/doctor/' + this.props.doctor.photo.name + '-200x200.' + this.props.doctor.photo.type} className="img-fluid" /> :
                                    <Avatar>{letterOne}{letterTwo}</Avatar>
                                // <img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" />
                            }
                        </div>
                        <div className="media-body align-self-end">
                            <div>Book your appointment with</div>
                            <div className="p-name">{this.props.doctor.fullname}</div>
                        </div>
                    </div>

                </div>

                <div className="modal-body">
                    <div className="publp_title">
                        <h6>What type of appointment are you seeking?</h6>
                    </div>
                    <div className="publp_form">

                        <div className="form-group">
                            <select value={this.state.selectedAppointment} onChange={(e) => {
                                const potentialAppointment = this.props.doctor.potentialAppointmentTypes.find((value: any, index: number) => {
                                    return value.id === e.target.value;
                                });
                                this.setState({
                                    selectedAppointment: e.target.value,
                                    selectedAppointmentValue: potentialAppointment
                                });
                            }}>
                                {
                                    this.props.doctor.potentialAppointmentTypes.map((pat: any, index: number) => {
                                        return (
                                            <option value={pat.id} key={index + Math.random()}>{pat.name} ({pat.duration} mins)</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <textarea placeholder="Notes for the office (Optional)" value={this.state.noteForOffice}
                                onInput={(e: any) => {
                                    this.setState({ noteForOffice: e.target.value });
                                }}
                                onChange={(e: any) => {
                                    this.setState({ noteForOffice: e.target.value });
                                }}
                            ></textarea>
                        </div>
                        <div className="form-group pop_btnsec">
                            <button disabled={this.state.selectedAppointment === '' || this.state.popUpTwoLoader === true} className="btn btn-custom w-100" onClick={(e) => {
                                // this.continueClick(this.state.selectedAppointmentValue, this.state.noteForOffice);
                                this.submitAppointment();
                            }}>Book {
                                    this.state.popUpTwoLoader === true &&
                                    <div className="text-center cs_popload"><i className="fas fa-circle-notch fa-spin"></i></div>
                                } </button>
                        </div>
                    </div>

                </div>


            </div>


        )
    }
    appointmentRequest(request: any) {
        Axios.post(configs.API_URL + 'patient/appointment/', request, {
            headers: attachHeader()
        }).then((success: any) => {
            if (success.status === 200) {
                if (success.data.status.error === false) {
                    console.log('CheckStatud', success.data)
                    this.addStatusToFirebase(success.data.appopintment, success.data.dependant, success.data.patient, this.props.data.uniqueId, success.data.status.msg);
                } else {
                    this.setState({ popUpTwoLoader: false });
                }
            }
        }).catch((error) => {
            this.setState({ popUpTwoLoader: false });
        });
    }
    addStatusToFirebase(appointmentId: any, dependantId: any, patientId: any, clinicId: any, msg: string) {
        var db = firebase.firestore();
        const obj = {
            "appointmentId": Number(appointmentId),
            "senderId": Number(patientId),
            "sender": 'patient',
            "action": 'Changed status from CREATED to REQUESTED.',
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": 'CREATED',
            "toStatus": 'REQUESTED',
            "sendToCId": clinicId.toString(),
            "dependantId": Number(dependantId)
        };
        db.collection(configs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                console.log("Document successfully written!");
                this.setState({
                    popUpTwoLoader: false
                });
                toast.success(msg);
                const login: any = getCookie();
                if (login) {
                    this.props.patientData({ uniqueId: login.xpr_user_id });
                    this.props.closePopUp();
                    this.props.history.push('/');
                } else {
                    this.props.logout();
                }
            })
            .catch((error: any) => {
                this.props.logout();
                console.error("Error writing document: ", error);
            });
    }
}
const mapStateToProps = (state: IAppState) => ({
    patientState: state.patientData
});
const mapDispatchToProps = (dispatch: any) => ({
    patientData: (request: PatientDataRequest) => {
        dispatch(patientDataPending(request));
    },
    logout: () => {
        dispatch(userLogout())
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(PopUpTwo);