export enum PROVIDER {
    SEARCH_PROVIDER_PENDING = 'SEARCH_PROVIDER_PENDING',
    SEARCH_PROVIDER_PENDING_ERROR = 'SEARCH_PROVIDER_PENDING_ERROR',
    SEARCH_PROVIDER_PENDING_SUCCESS = 'SEARCH_PROVIDER_PENDING_SUCCESS'
}
export interface ProviderState {
    pending: boolean;
    error: any;
    providerSearchRequest?: ProviderSearchRequest;
    providerSearchResponse?: ProviderSearchResponse;
}
export interface ProviderSearchRequest {
    uniqueId: string;
}
export interface ProviderSearchResponse {
    HygieneFeatures: any[];
    address: any;
    description: string;
    doctors: [];
    email: string;
    emailVerified: string;
    features: [];
    highlights: [];
    hours: any;
    id: string;
    isDefault: boolean;
    isFavourite: boolean;
    logo: { name: string, type: string };
    metadata: { clinicTnC: boolean, reviewOn: string, trustApiKey: string };
    name: string;
    phone: string;
    phoneVerified: string;
    photos: []
    primarySpeciality: string
    questionResponse: [];
    questions: [];
    rating: number;
    reviewCount: number;
    status: { error: false, msg: string };
    totalPatient: string;
    uniqueId: string;
    website: string;
}

export interface ProviderSearchPendingAction {
    type: PROVIDER.SEARCH_PROVIDER_PENDING;
    payload: ProviderSearchRequest;
}
export interface ProviderSearchSuccessAction {
    type: PROVIDER.SEARCH_PROVIDER_PENDING_SUCCESS;
    payload: ProviderSearchResponse;
}
export interface ProviderSearchErrorAction {
    type: PROVIDER.SEARCH_PROVIDER_PENDING_ERROR;
    payload: any;
}

export type ProviderActionTypes = ProviderSearchErrorAction | ProviderSearchPendingAction | ProviderSearchSuccessAction;