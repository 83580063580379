import React, { useEffect, useState } from 'react'
import { AddNewMember } from './AddNewMember';
import { EditMember } from './EditMember';
import { MyFamilyList } from './MyFamilyList';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Avatar } from '@material-ui/core';

export const MyFamily = () => {
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [isOpenFamilyList, setIsOpenFamilyList] = useState(false);
    const [isGuarantor, setIsGuarantor] = useState(false);

    useEffect(() => {
        if (userData && userData.dependant && userData.dependant.length > 0) {
            setFamilyMembers(userData.dependant);
        }
        if (userData && userData.isGuarantor) {
            setIsGuarantor(true);
        } else {
            setIsGuarantor(false);
        }
    }, [userData]);
    return (
        isGuarantor ?
            <>
                <div className="card_item my_family_drawer card p-3">
                    <div className="infocard_header d-flex justify-content-between mb-3">
                        <div className="card_ititle">My Family</div>
                        <div className="card_ilink">
                            <button
                                type="button"
                                className="btn btn-link p-0 fw-normal fs-7"
                                onClick={() => { setIsOpenFamilyList(true) }}>
                                {familyMembers && familyMembers.length === 1 ? 'Add' : 'Manage'}
                            </button>
                        </div>
                    </div>

                    <div className="infocard_body">
                        {
                            familyMembers && familyMembers.length <= 1 ?
                                familyMembers.map((member: any) => {
                                    return (
                                        <div className="d-flex align-items-center">

                                            <div className="av-txt">
                                                <div className="av_title">Add your family members</div>
                                                {/* <div className="av_subtitle opacity-50">Owner</div> */}
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="avatar-string ms-2">
                                    {(userData && userData.id && familyMembers && familyMembers.length > 0) &&
                                        familyMembers.map((member: any) => {
                                            if (userData.id !== member.loginId) {
                                                let letterOne = "", letterTwo = "";
                                                if (member.firstName) {
                                                    letterOne = member.firstName.charAt(0);
                                                }
                                                if (member.lastName) {
                                                    letterTwo = member.lastName.charAt(0);
                                                }
                                                return (
                                                    <div key={member.id} className="avatar avatar-lg rounded-circle">
                                                        {/* <img alt="Image placeholder" src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/theme/team-1.jpg" /> */}
                                                        {letterOne}{letterTwo}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
                <MyFamilyList isShow={isOpenFamilyList} handleClose={() => { setIsOpenFamilyList(false) }} />
                {/* <AddNewMember /> */}
                <EditMember />
            </>
            :
            <>
                <div className="card p-3 alert-warning">
                    <div className="nocard text-center pt-2 mt-2">
                        <div><i className="bi bi-exclamation-triangle fs-4"></i></div>
                        You need to be a Guarantor to add Family members
                    </div>
                </div>
            </>
    )
}