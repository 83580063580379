export enum PATIENT_DATA {
    PATIENT_DATA_LOAD_PENDING = 'PATIENT_DATA_LOAD_PENDING',
    PATIENT_DATA_LOAD_ERROR = 'PATIENT_DATA_LOAD_ERROR',
    PATIENT_DATA_LOAD_SUCCESS = 'PATIENT_DATA_LOAD_SUCCESS',
    PATIENT_NAME_UPDATED = 'PATIENT_NAME_UPDATED',
    PATIENT_REFER_PENDING = 'PATIENT_REFER_PENDING',
    PATIENT_REFER_SUCCESS = 'PATIENT_REFER_SUCCESS',
    PATIENT_REFER_ERROR = 'PATIENT_REFER_ERROR',
    EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR',
    EMAIL_VERIFICATION_PENDING = 'EMAIL_VERIFICATION_PENDING',
    EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS',
    REMOVE_DEFAULT_CLINIC = 'REMOVE_DEFAULT_CLINIC',
    REMOVE_DEFAULT_CLINIC_ERROR = 'REMOVE_DEFAULT_CLINIC_ERROR',
    REMOVE_DEFAULT_CLINIC_SUCCESS = 'REMOVE_DEFAULT_CLINIC_SUCCESS',
    ADD_DEFAULT_CLINIC = 'ADD_DEFAULT_CLINIC',
    ADD_DEFAULT_CLINIC_ERROR = 'ADD_DEFAULT_CLINIC_ERROR',
    ADD_DEFAULT_CLINIC_SUCCESS = 'ADD_DEFAULT_CLINIC_SUCCESS',
    CHANGE_WRITE_REVIEW = 'CHANGE_WRITE_REVIEW',
};
export interface PatientDataRequest {
    userId?: string;
    uniqueId: string;
    doNotShowLoader?: boolean;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface Appointment {
    id: string;
    uniqueid: string;
    currentStatus: string;
    requests: {
        isNewPatient: boolean,
        additionalNote: string,
        appointmentFor: string,
        dependantName: string,
        dependantRelation: string,
        isExistingClinic: boolean,
    };
    patient: {
        email: string;
        firstName: string;
        lastName: string;
    };
    billingdata: any;
    date: any;
    time: any;
    claimStus: any;
    claimStatus: any;
    clinic: {
        id: string;
        email: string;
        phone: string;
        uniqueid: string;
        name: string;
        metadata: {
            acceptAssignment: boolean;
            googleReviewUrl: string;
            isTaxable: boolean;
            reviewOn: string[];
            trustApiKey: string;
        }
    };
    doctor: {
        fullname: string;
        id: string;
        email: string;
        uniqueid: string;
        gender: string;
        photo: { name: string, type: string };
    };
    metadata: {
        referral: string;
        patient: {
            reminder: number;
            isNewPatient: boolean;
        },
        appointment: {
            cancellation: {
                reason: any;
                allowRescheduling: any;
            },
            prescreening: any;
            checkin: any;
            payment: any;
        }
    };
    stripeAccount: { customerId: string, accountID: string };
    rating: any;
    reviewText: any;
    owership: boolean;
    dependant:any;
}
export interface DefaultClinic {
    id: string;
    address: any;
    logo: {
        name: string;
        type: string;
    };
    name: string;
    uniqueId: string;
};
export interface PatientDataResponse {
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    gender: string;
    dob: string;
    phone: string;
    phoneVerified: string;
    referralCode: string;
    addressline1: string;
    addressline2: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
    referral: number;
    appointments: Appointment[];
    status: Status;
    defaultClaim: string;
    prescreening: any;
    defaultClinic: DefaultClinic[];
    dependant:any;
    isGuarantor:boolean;
}

export interface AccountUpdateRequest {
    userId?: string;
    uniqueId: string;
    updateType: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    email?: string;
    phone?: string;
    dob?: string;
    address?: {
        addressline1: string; addressline2: string; country: string; state: string; city: string;
        postalCode: string;
    },
    password?: { cpassword: string; nPassword: string; rpassword: string }
}

export interface AccountUpdateResponse {
    status: Status;
    updateType: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    email?: string;
    phone?: string;
    dob?: string;
    address?: {
        addressline1: string; addressline2: string; country: string; state: string; city: string;
        postalCode: string;
    }
}

export interface PatientReferRequest {
    // userId: string;
    uniqueId: string;
    inviteEmail: string;
}
export interface PatientReferResponse {
    status: Status;
}

export interface EmailVerificationRequest {
    modelType: string;
    modelUniqueid: string;
    email: string;
}
export interface EmailVerificationResponse {
    status: Status;
}
export interface RemoveDefaultClinicRequest {
    clinicId: string;
}
export interface RemoveDefaultClinicResponse {
    status: Status;
}
export interface AddDefaultClinicRequest {
    clinicId: string;
    defaultClinicObj: any;
}
export interface AddDefaultClinicResponse {
    status: Status;
}
export interface ChangeWriteReview {
    score: string;
    comment: string;
    appointmentUniqueId: string;
}
export interface PatientDataState {
    pending: boolean;
    patientDataRequest?: PatientDataRequest;
    patientDataResponse?: PatientDataResponse;
    accountUpdateRequest?: AccountUpdateRequest;
    accountUpdateResponse?: AccountUpdateResponse;
    patientReferRequest?: PatientReferRequest;
    patientReferResponse?: PatientReferResponse;
    emailVerificationRequest?: EmailVerificationRequest;
    emailVerificationResponse?: EmailVerificationResponse;
    removeDefaultClinicRequest?: RemoveDefaultClinicRequest;
    removeDefaultClinicResponse?: RemoveDefaultClinicResponse;
    addDefaultClinicRequest?: AddDefaultClinicRequest;
    addDefaultClinicResponse?: AddDefaultClinicResponse;
    changeWriteReview?: ChangeWriteReview;
    error: any;
}

export interface PatientDataPendingAction {
    type: PATIENT_DATA.PATIENT_DATA_LOAD_PENDING;
    payload: PatientDataRequest;
}

export interface PatientDataErrorAction {
    type: PATIENT_DATA.PATIENT_DATA_LOAD_ERROR;
    error: any;
}
export interface PatientDataSuccessAction {
    type: PATIENT_DATA.PATIENT_DATA_LOAD_SUCCESS;
    patientData: PatientDataResponse;
}

/* Account */

export enum ACCOUNT {
    ACCOUNT_UPDATE_PENDING = 'ACCOUNT_UPDATE_PENDING',
    ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR',
    ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS'
};

export interface AccountUpdatePendingAction {
    type: ACCOUNT.ACCOUNT_UPDATE_PENDING;
    payload: AccountUpdateRequest;
}

export interface AccountUpdateErrorAction {
    type: ACCOUNT.ACCOUNT_UPDATE_ERROR;
    error: any;
}
export interface AccountUpdateSuccessAction {
    type: ACCOUNT.ACCOUNT_UPDATE_SUCCESS;
    accountUpdate: AccountUpdateResponse;
}

export interface PatientReferPendingAction {
    type: PATIENT_DATA.PATIENT_REFER_PENDING;
    payload: PatientReferRequest;
}

export interface PatientReferErrorAction {
    type: PATIENT_DATA.PATIENT_REFER_ERROR;
    error: any;
}
export interface PatientReferSuccessAction {
    type: PATIENT_DATA.PATIENT_REFER_SUCCESS;
    patientRefer: PatientReferResponse;
}
export interface EmailVerificationPendingAction {
    type: PATIENT_DATA.EMAIL_VERIFICATION_PENDING;
    payload: EmailVerificationRequest;
}
export interface EmailVerificationSuccessAction {
    type: PATIENT_DATA.EMAIL_VERIFICATION_SUCCESS;
    emailVerification: EmailVerificationResponse;
}
export interface EmailVerificationErrorAction {
    type: PATIENT_DATA.EMAIL_VERIFICATION_ERROR;
    error: any;
}
export interface RemoveDefaultClinicPendingAction {
    type: PATIENT_DATA.REMOVE_DEFAULT_CLINIC;
    payload: RemoveDefaultClinicRequest;
}
export interface RemoveDefaultClinicSuccessAction {
    type: PATIENT_DATA.REMOVE_DEFAULT_CLINIC_SUCCESS;
    payload: RemoveDefaultClinicResponse;
}
export interface RemoveDefaultClinicErrorAction {
    type: PATIENT_DATA.REMOVE_DEFAULT_CLINIC_ERROR;
    payload: any;
}
export interface AddDefaultClinicPendingAction {
    type: PATIENT_DATA.ADD_DEFAULT_CLINIC;
    payload: AddDefaultClinicRequest;
}
export interface AddDefaultClinicSuccessAction {
    type: PATIENT_DATA.ADD_DEFAULT_CLINIC_SUCCESS;
    payload: AddDefaultClinicResponse;
}
export interface AddDefaultClinicErrorAction {
    type: PATIENT_DATA.ADD_DEFAULT_CLINIC_ERROR;
    payload: any;
}
/* Change appointment review response after the review api success */
export interface ChangeWriteReviewAction {
    type: PATIENT_DATA.CHANGE_WRITE_REVIEW;
    payload: ChangeWriteReview;
}
export type PatientDataActionTypes = PatientDataPendingAction | PatientDataErrorAction | PatientDataSuccessAction |
    AccountUpdateErrorAction | AccountUpdatePendingAction | AccountUpdateSuccessAction | PatientReferPendingAction
    | PatientReferErrorAction | PatientReferSuccessAction |
    EmailVerificationPendingAction | EmailVerificationSuccessAction | EmailVerificationErrorAction |
    RemoveDefaultClinicErrorAction | RemoveDefaultClinicPendingAction | RemoveDefaultClinicSuccessAction |
    AddDefaultClinicErrorAction | AddDefaultClinicPendingAction | AddDefaultClinicSuccessAction |
    ChangeWriteReviewAction;