import React from 'react';
import './MyClinic.scss';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { PatientDataState, DefaultClinic, RemoveDefaultClinicRequest } from '../../store/patientData/types';
import configs from '../../configs/apiConfigs';
import { Avatar } from '@material-ui/core';
import { showAddress } from '../renderAddress';
import { removeDefaultClinicPendingAction } from '../../store/patientData/actions';
import { toast } from 'react-toastify';
interface Props {
    patientDataState: PatientDataState;
    removeDefaultClinic: typeof removeDefaultClinicPendingAction;
}
interface State {
    defaultClinic: DefaultClinic[];
}
class MyClinic extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            defaultClinic: []
        };
    }
    componentDidMount() {
        if (this.props.patientDataState && this.props.patientDataState.patientDataResponse && this.props.patientDataState.patientDataResponse.defaultClinic.length > 0) {
            this.setState({
                defaultClinic: this.props.patientDataState.patientDataResponse.defaultClinic
            });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (nextProps.patientDataState.patientDataResponse && this.props.patientDataState.patientDataResponse
            && nextProps.patientDataState.patientDataResponse.defaultClinic !== this.props.patientDataState.patientDataResponse.defaultClinic) {
            toast.success(nextProps.patientDataState.removeDefaultClinicResponse?.status.msg);
            this.setState({ defaultClinic: nextProps.patientDataState.patientDataResponse.defaultClinic });
        }
        return true;
    }
    render() {
        return (
            <>

                <section className="pantient-view">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-md-12 text-center">
                                <h4 className="page-title">My Active Clinic</h4>
                            </div>
                            {
                                this.state.defaultClinic.map((value, index) => {
                                    let letterOne = '';
                                    let letterTwo = '';
                                    if (value.name && value.name !== "") {
                                        letterOne = value.name.split(" ")[0] ? value.name.split(" ")[0].charAt(0) : '';
                                        letterTwo = value.name.split(" ")[1] ? value.name.split(" ")[1].charAt(1) : '';
                                    }
                                    return (
                                        <div className="col-sm-8" key={value.uniqueId}>
                                            <ul className="myclinic-list">
                                                <li className="mycli d-flex">
                                                    <a className="media flex-grow-1" href={"/clinic/" + value.uniqueId}>
                                                        {
                                                            (value.logo.name !== "" && value.logo.type !== "") ?
                                                                <div className="media-img mr-3">
                                                                    <img src={configs.IMAGE_URL + 'clinic/' + value.logo.name + '-200x200.' + value.logo.type} className="img-fluid" />
                                                                </div>
                                                                :
                                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                                        }
                                                        <div className="media-body align-self-center">
                                                            <div className="cl-name">{value.name}</div>
                                                            <div className="cl-loc">{showAddress(value.address)}</div>
                                                        </div>
                                                    </a>
                                                    <div className="text-center">
                                                        <div className="switch">
                                                            <input className="switch" id="switch" name="switch" type="checkbox" onChange={(e) => {
                                                                this.props.removeDefaultClinic({ clinicId: value.id });
                                                            }}
                                                                checked={value.id ? true : false} />
                                                            <label htmlFor="switch"></label>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    patientDataState: state.patientData
});
const mapStateToDispatch = (dispatch: any) => ({
    removeDefaultClinic: (request: RemoveDefaultClinicRequest) => {
        dispatch(removeDefaultClinicPendingAction(request));
    }
})
export default connect(mapStateToProps, mapStateToDispatch)(MyClinic);