import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppointment, assignAppointment } from '../../services/appointment';
import moment from 'moment';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Alert, Modal, ModalBody, ListGroup } from 'react-bootstrap';
import { PrescreeningModal } from './PrescreeningModal';
import { getCookie } from '../../utils/cookies';
import { changeWriteReviewAction, patientDataPending } from '../../../store/patientData/actions';
import { CheckInModal } from './CheckInModal';
import AppointmentAssignModal from '../../../components/appointments/AppointmentAssignModal';
import { assignAppointmentPending, writeReviewPending } from '../../../store/appointments/actions';
import LoadingIndicator from '../../pages/loadingIndicator/LoadingIndicator';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import apiConfigs from '../../configs/apiConfigs';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { showWaitingPresc, userLogout } from '../../../store/auth/actions';
import { AppointmentConfirmationModal } from './AppointmentConfirmationModal';
export const Upcoming = () => {
    let db: any;
    const [isLoading, setIsLoading] = useState(false);
    const [appointmentList, setAppointmentList]: any = useState([]);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const [completeScreeningModal, setCompleteScreeningModal] = useState(false);
    const [currentSelectedAppointment, setCurrentSelectedAppointment]: any = useState('');
    const [checkInModal, setCheckInModal] = useState(false);
    const history = useHistory();
    const location: any = useLocation();
    const [assignAppointmentModal, setAssignAppointmentModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const dispatch = useDispatch();
    const [isNoRecord, setIsNoRecord] = useState(false);
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [status, SetStatus] = useState('');

    useEffect(() => {
        db = firebase.firestore();
        fetchAppointmentApi();

    }, []);
    useEffect(() => {
        if (appointmentList && new URLSearchParams(location.search) && new URLSearchParams(location.search).get('id') !== '') {
            let Id = new URLSearchParams(location.search).get('id');
            const selectedAppointment = appointmentList.find((item: any) => item.uniqueid == Id);
            if (selectedAppointment && selectedAppointment.metadata && selectedAppointment.metadata.appointment &&
                !(selectedAppointment.metadata.appointment.prescreening && selectedAppointment.metadata.appointment.prescreening.attempt)) {
                dispatch(showWaitingPresc(false));
                setCompleteScreeningModal(true);
                setCurrentSelectedAppointment(appointmentList.find((item: any) => item.uniqueid === Id))
            } else {
                dispatch(showWaitingPresc(false));
            }
        }
    }, [appointmentList]);
    const fetchAppointmentApi = () => {
        setIsLoading(true);
        dispatch(fetchLoadingPending());
        fetchAppointment(patientData.uniqueId, 'scheduled').then((success) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setAppointmentList(success.appointments);
                if (success.appointments && success.appointments.length <= 0) {
                    setIsNoRecord(true);
                }
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            setIsLoading(false);
            console.log(err);
        })
    }

    const changeAppointmentAssignModal = (status: boolean, appointment?: any) => {
        setAssignAppointmentModal(status);
        if (appointment) {
            setCurrentSelectedAppointment(appointment);
        }
    }
    const hadleShowAlert = () => {
        setShowAlert(true);
    }
    const handleHidePreScreeningModal = () => {
        setCurrentQuestion(0);
        setCompleteScreeningModal(false);
    }
    const handleSuccessPreScreening = () => {
        setCurrentQuestion(0);
        setCompleteScreeningModal(false);
        const login: any = getCookie();
        dispatch(fetchLoadingSuccess(false));
        fetchAppointmentApi();
    }
    const hideCheckInModal = () => {
        setCheckInModal(false);
    }
    const handleAssignAppointment = (email: any) => {
        setIsLoading(true);
        let data = { email: email };
        assignAppointment(data, currentSelectedAppointment.uniqueid).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                console.log('success', success);
                toast.success(success.status.msg);
                assignAppointmentAddObjectFirebase(success);
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    const assignAppointmentAddObjectFirebase = (assignAppointmentResponse: any) => {
        if (currentSelectedAppointment && patientData) {
            const user: any = getCookie();
            const obj = {
                "appointmentId": Number(currentSelectedAppointment.uniqueid),
                "senderId": Number(user.xpr_user_id),
                "sender": CHAT.SENDER.PATIENT,
                "senderName": patientData.firstName + " " + patientData.lastName,
                "assignToEmail": assignAppointmentResponse.email,
                "action": "Appointment is assigned to another user",
                "actionType": CHAT.ACTION_TYPE.APPONINTMENT_ASSIGN,
                "text": "Assign Appointment Book",
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": currentSelectedAppointment.clinic.uniqueid,
                "sendToDId": currentSelectedAppointment.doctor.uniqueid,
                "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
                dateTime: {
                    date: currentSelectedAppointment.date,
                    time: currentSelectedAppointment.time
                }
            };
            db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                .then(() => {
                    dispatch(fetchLoadingSuccess(false));
                    console.log("Document successfully written!");
                    setAssignAppointmentModal(false);
                    setCurrentSelectedAppointment('');
                })
                .catch((error: any) => {
                    dispatch(fetchLoadingSuccess(false));
                    dispatch(userLogout());
                    history.push('/');
                    console.error("Error writing document: ", error);
                });
        }
    }
    const btnClickCancel = () => {
        db = firebase.firestore();
        dispatch(fetchLoadingPending());
        const user: any = getCookie();
        const obj = {
            "appointmentId": Number(currentSelectedAppointment.uniqueid),
            "senderId": Number(user.xpr_user_id),
            "sender": CHAT.SENDER.PATIENT,
            "action": "Cancel by patient",
            "actionType": CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT,
            "text": "Cancellation by " + currentSelectedAppointment.patient.firstName + " " + currentSelectedAppointment.patient.lastName + " for appointment on " + moment(currentSelectedAppointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " - " + currentSelectedAppointment.time + " with " + currentSelectedAppointment.doctor.fullname + " having XcarePro id " + currentSelectedAppointment.uniqueid,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": currentSelectedAppointment.clinic.uniqueid,
            "addedFromMessageCenter": true,
            "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
            dateTime: {
                date: currentSelectedAppointment.date,
                time: currentSelectedAppointment.time
            }
        };
        console.log('firestoreCancelObj', obj);
        setIsLoading(true);
        db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                dispatch(fetchLoadingSuccess(false));
                console.log("Document successfully written!");
                setCurrentSelectedAppointment('');
                setTimeout(() => {
                    fetchAppointmentApi();
                }, 3000);
            })
            .catch((error: any) => {
                dispatch(fetchLoadingSuccess(false));
                dispatch(userLogout());
                history.push('/');
                console.error("Error writing document: ", error);
            });
    }
    const btnClickConfirm = () => {
        db = firebase.firestore();
        dispatch(fetchLoadingPending());
        const user: any = getCookie();
        const obj = {
            "appointmentId": Number(currentSelectedAppointment.uniqueid),
            "senderId": Number(user.xpr_user_id),
            "sender": CHAT.SENDER.PATIENT,
            "action": "Confirm by patient",
            "actionType": CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT,
            "text": "Confirmation by " + currentSelectedAppointment.patient.firstName + " " + currentSelectedAppointment.patient.lastName + " for appointment on " + moment(currentSelectedAppointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " - " + currentSelectedAppointment.time + " with " + currentSelectedAppointment.doctor.fullname + " having XcarePro id " + currentSelectedAppointment.uniqueid,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": currentSelectedAppointment.clinic.uniqueid,
            "addedFromMessageCenter": true,
            "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
            dateTime: {
                date: currentSelectedAppointment.date,
                time: currentSelectedAppointment.time
            }
        };
        console.log('firestoreConfirmObj', obj);
        setIsLoading(true);
        db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                dispatch(fetchLoadingSuccess(false));
                console.log("Document successfully written!");
                setCurrentSelectedAppointment('');
                setTimeout(() => {
                    fetchAppointmentApi();
                }, 3000);
            })
            .catch((error: any) => {
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false));
                dispatch(userLogout());
                history.push('/');
                console.error("Error writing document: ", error);
            });
    }
    const hideConfirmationModal = () => {
        setIsConfirmationModal(false);
        SetStatus('');

    }
    return (
        <section className="px-md-3 py-4">
            {
                isLoading &&
                <LoadingIndicator />
            }
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {
                            showAlert === true &&
                            <Alert className="noti_covid" variant="danger" onClose={() => { setShowAlert(false) }} dismissible>
                                <p>
                                    Please complete the Pre-Screening before 2 to 12 hours of your appointment time, notify Clinic about your arrival at the Clinic approximately 15 min before your appointment using Check-in button. You may be restricted to enter the Clinic without Pre-screening and Check-in.
                                </p>
                            </Alert>
                        }
                        <div className="appUpcoming">
                            {(appointmentList && appointmentList.length > 0) ?
                                appointmentList.map((appointment: any, index: number) => {
                                    let endTime: any = '';
                                    if (appointment.time && appointment.duration) {
                                        endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                                    }
                                    let pFullname = '';
                                    if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
                                        pFullname = appointment.dependant.firstName + " " + appointment.dependant.lastName;
                                    }
                                    return (
                                        <div key={index + Math.random() + 'appointment'} className="d-xl-flex d-block upcoming-app-box appDataBox rounded p-3 justify-content-between mb-4">
                                            <div className="leftSec d-flex cpointer"
                                                onClick={() => {
                                                    history.push('/appointmentdetail/' + appointment.uniqueid)
                                                }}
                                            >
                                                <div className="dateBox primary-badge py-3 px-md-3 px-2  me-md-3 me-2">
                                                    <div className="dayVal text-center pt-1">{appointment.date ? moment(appointment.date).format('DD') : '-'}</div>
                                                    <div className="monVal text-center text-muted">{appointment.date ? moment(appointment.date).format('MMM') : '-'}</div>
                                                </div>
                                                <div className="detailBox d-block">
                                                    <span className="badge rounded-pill bg-primary text-white text-capitalize">
                                                        {
                                                            appointment.currentStatus === 'requested' ? 'Requested' :
                                                                appointment.currentStatus === 'scheduled' && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "confirmed" ? 'Confirmed' :
                                                                    appointment.currentStatus === 'scheduled' && appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.confirmation && appointment.metadata.appointment.confirmation.status === "cancelled" ? 'Cancel Requested' : 'Scheduled'
                                                        }
                                                    </span>
                                                    <div className="detailTxt my-1">{appointment.appointmentType ? appointment.appointmentType : '-'} with {appointment.doctor.fullname ? appointment.doctor.fullname : ''} - ({appointment.doctor.speciality})</div>
                                                    <div className="time-name-box justify-content-between">
                                                        <div className="text-muted me-3">{appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'}  {appointment.date ? "(" + moment(appointment.date).format('MMM DD, YYYY') + ")" : '-'}</div>
                                                        <div className="text-muted">Clinic: <span>{appointment.clinic.name ? appointment.clinic.name : '-'}</span></div>
                                                    </div>
                                                    <div className="text-muted">Apt. for - <span>{pFullname ? pFullname : '-'}</span></div>
                                                    {/* <div className="d-flex justify-content-start mt-3">
                                                        <button type="button" className="btn btn-sm btn-outline-danger me-2"
                                                            onClick={() => {
                                                                btnClickCancel(appointment)
                                                            }}
                                                        >Cancel</button>
                                                        <button type="button" className="btn btn-sm btn-outline-success"
                                                            onClick={() => {
                                                                btnClickConfirm(appointment)
                                                            }}
                                                        >Confirm</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="rightSec d-flex mt-xl-0 mt-md-3">
                                                {
                                                    appointment.currentStatus === 'scheduled' &&
                                                    <>
                                                        <div className={"covid-box mt-md-0 mt-3  d-flex me-md-3 mx-1 rounded align-self-center px-1 px-md-3 py-1" + ((appointment.date
                                                            && appointment.time && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').add('1', 'hour').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                                            && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').add('1', 'hour').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0 &&
                                                            appointment.metadata && appointment.metadata.appointment &&
                                                            (!appointment.metadata.appointment.prescreening || (!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt)))) ? '' : ' diableState') +
                                                            ((appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt && appointment.metadata.appointment.prescreening.verifiedOn) ? ' verified' : '') +
                                                            ((!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt)) ? ' pending' : '') +
                                                            ((appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt && !appointment.metadata.appointment.prescreening.verifiedOn) ? ' completed' : '')
                                                        }
                                                            onClick={(e) => {
                                                                if (appointment.date
                                                                    && appointment.time && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').add('1', 'hour').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                                                    && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').add('1', 'hour').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0 &&
                                                                    appointment.metadata && appointment.metadata.appointment &&
                                                                    (!appointment.metadata.appointment.prescreening || (!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt)))) {
                                                                    setCompleteScreeningModal(true);
                                                                    setCurrentSelectedAppointment(appointment);
                                                                }
                                                            }}>
                                                            <img src={require("../../assets/images/coronavirus_black.svg")} className="preScreening iconSec align-self-md-start align-self-center me-2" />
                                                            <div>
                                                                <div className="btn-txt-box">Pre-Screening</div>
                                                                {
                                                                    (appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt && appointment.metadata.appointment.prescreening.verifiedOn) &&
                                                                    <span className="text-success small">Verified</span>
                                                                }
                                                                {
                                                                    (!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt)) &&
                                                                    <span className="text-danger small">Pending</span>
                                                                }
                                                                {
                                                                    (appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt && !appointment.metadata.appointment.prescreening.verifiedOn) &&
                                                                    <span className="text-success small">Waiting for approval</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={"covid-box mt-md-0 mt-3  d-flex me-md-3 mx-1 rounded align-self-center px-md-3 px-1 py-1" +
                                                            ((appointment.metadata
                                                                && appointment.metadata.appointment
                                                                && appointment.metadata.appointment.prescreening &&
                                                                appointment.metadata.appointment.prescreening.attempt &&
                                                                appointment.metadata.appointment.prescreening.verifiedOn &&
                                                                (!appointment.metadata.appointment.checkin || (!(appointment.metadata.appointment.checkin && appointment.metadata.appointment.checkin.attempt))) &&
                                                                moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 1 && moment(appointment.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) ? '' : ' diableState')}

                                                        >

                                                            <img src={require("../../assets/images/checkin.svg")} className="iconSec align-self-md-start align-self-center me-2" />
                                                            <div onClick={(e) => {
                                                                if (appointment.metadata
                                                                    && appointment.metadata.appointment
                                                                    && appointment.metadata.appointment.prescreening &&
                                                                    appointment.metadata.appointment.prescreening.attempt &&
                                                                    appointment.metadata.appointment.prescreening.verifiedOn &&
                                                                    (!appointment.metadata.appointment.checkin || (!(appointment.metadata.appointment.checkin && appointment.metadata.appointment.checkin.attempt))) &&
                                                                    moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 1 && moment(appointment.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
                                                                    setCheckInModal(true);
                                                                    setCurrentSelectedAppointment(appointment);
                                                                }
                                                            }}>
                                                                <div className="btn-txt-box">
                                                                    {
                                                                        (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.checkin &&
                                                                            appointment.metadata.appointment.checkin.attempt) ?
                                                                            <>Checked-in</> : <>Check-in</>
                                                                    }
                                                                </div>
                                                                {
                                                                    (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.checkin &&
                                                                        appointment.metadata.appointment.checkin.attempt) ?
                                                                        <>
                                                                            {
                                                                                (appointment.metadata.appointment.checkin.data.toLowerCase() === "Check-in Now".toLowerCase()) ?
                                                                                    null :
                                                                                    <span className="small text-orange ">
                                                                                        {appointment.metadata.appointment.checkin.data}
                                                                                    </span>
                                                                            }
                                                                        </> :
                                                                        <span className="small text-orange ">Pending</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            (!appointment?.metadata?.appointment?.confirmation && (!appointment?.metadata?.appointment?.prescreening || !appointment?.metadata?.appointment?.prescreening.attempt === true)
                                                                && moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').isSameOrAfter(moment())) &&
                                                            <div className="d-flex d-md-block align-self-center xtra-btn-sec">
                                                                <button type="button" className="btn d-block btn-sm btn-outline-success w-100 me-2 me-md-0"
                                                                    onClick={() => {
                                                                        setCurrentSelectedAppointment(appointment);
                                                                        setIsConfirmationModal(true);
                                                                        SetStatus('confirm');
                                                                    }}
                                                                >Confirm</button>
                                                                <button type="button" disabled={isLoading} className="btn d-block btn-sm btn-outline-danger w-100 ms-2 ms-md-0"
                                                                    onClick={() => {
                                                                        setCurrentSelectedAppointment(appointment);
                                                                        setIsConfirmationModal(true);
                                                                        SetStatus('cancel');
                                                                    }}
                                                                >Cancel</button>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {/* {
                                                    (appointment.owership) &&
                                                    <div className="align-self-center d-none">
                                                        <button className="btn btn-border"
                                                            onClick={() => { changeAppointmentAssignModal(true, appointment) }}
                                                        >Assign</button>
                                                    </div>
                                                } */}
                                            </div>
                                        </div>
                                    )
                                })
                                : isNoRecord &&
                                <div className="d-flex appDataBox rounded p-3 justify-content-between my-4">
                                    No record found.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                (completeScreeningModal) &&
                <PrescreeningModal
                    completeScreeningModal={completeScreeningModal}
                    hadleShowAlert={hadleShowAlert}
                    handleHidePreScreeningModal={handleHidePreScreeningModal}
                    currentSelectedAppointment={currentSelectedAppointment}
                    handleSuccessPreScreening={handleSuccessPreScreening}
                />
            }
            {
                (checkInModal) &&
                <CheckInModal
                    checkInModal={checkInModal}
                    hideCheckInModal={hideCheckInModal}
                    currentSelectedAppointment={currentSelectedAppointment}
                    checkInSuccess={() => {
                        fetchAppointmentApi();
                        hideCheckInModal();
                    }}
                />
            }
            {
                assignAppointmentModal &&
                <AppointmentAssignModal closeModal={() => {
                    changeAppointmentAssignModal(false);
                }} submitAssign={(email: string) => {
                    if (currentSelectedAppointment)
                        handleAssignAppointment(email);
                }}></AppointmentAssignModal>
            }
            {
                (isConfirmationModal) &&
                <AppointmentConfirmationModal
                    isConfirm={isConfirmationModal}
                    status={status}
                    hideConfirmationModal={hideConfirmationModal}
                    onClickYes={() => {
                        if (status === 'cancel') {
                            hideConfirmationModal();
                            btnClickCancel();
                        } else {
                            hideConfirmationModal();
                            btnClickConfirm();
                        }
                    }}
                />
            }
        </section >
    )
}
