import { patientDataReducer } from './patientData/reducers';
import { authReducer } from './auth/reducers';
import { combineReducers, Store, createStore, applyMiddleware } from 'redux';
import CreateSagaMiddleware from "redux-saga";
import rootSaga from '../saga';
import { createLogger } from 'redux-logger';
import { loadingReducer } from './loadingIndicator/reducers';
import { claimSubmitReducer } from './claim/reducers';
import { forgotPasswordReducer } from './forgotpassword/reducers';
import { appointmentDetailReducer } from './appointments/reducers';
import { paymentReducer } from './payment/reducers';
import { providerReducer } from './provider/reducers';
import pinReducer from './pins/reducers';
const appReducer: any = combineReducers({
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    patientData: patientDataReducer,
    loading: loadingReducer,
    claim: claimSubmitReducer,
    appointmentDetail: appointmentDetailReducer,
    payment: paymentReducer,
    provider: providerReducer,
    pins:pinReducer
});
const rootReducer = (state: any, action: any) => {
    if (action.type === 'USER_LOGGED_OUT') {
        state = undefined;
    }
    return appReducer(state, action);
}
const loggerMiddleware = createLogger();
export type IAppState = ReturnType<typeof rootReducer>;
export default function configureStore(): Store<any, any> {
    const sagaMiddleware = CreateSagaMiddleware();
    const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, loggerMiddleware));
    sagaMiddleware.run(rootSaga);
    return store;
}