import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Animated } from 'react-animated-css';
import { Modal, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import configs from '../../configs/apiConfigs';
import { LABELS, TITLES_LABELS } from '../../constants';
import { checkLogin, patientLogin } from '../../services/auth';
import { loginPending, showWaitingPayment, showWaitingPresc, userLogout } from '../../../store/auth/actions';
import { getCookie, isLogin } from '../../utils';
import { getCurrentUser, signInWithCustomToken } from '../../utils/firebaseUtils';
import { emailValidation } from '../../utils/validators';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { OTPModal } from './otp';
import { InvalidSessionModal } from './InvalidSessionModal';
export const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isViewPassword, setIsViewPassword] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger } = useForm();
    const [askFor, setAskFor] = useState("");
    const [isShowOTPModal, setIsShowOTPModal] = useState(false);
    const [patientId, setPatientId] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const history = useHistory();
    const dispatch = useDispatch();
    const location: any = useLocation();
    const [isInvalidSession, setIsInvalidSession] = useState(false);
    useEffect(() => {
        if (isLogin()) {
            setIsLoading(true);
            if (!getCurrentUser()) {
                try {
                    signInWithCustomToken(getCookie().jwtToken).then((signInSuccess) => {
                        history.replace('/');
                    }).catch((error) => {
                        setIsLoading(false);
                        dispatch(userLogout());
                    })
                } catch (err) {
                    setIsLoading(false);
                    dispatch(userLogout());
                }
            } else {
                history.replace('/');
            }
        } else {
            if (location.state && location.state.sessId) {
                setIsLoading(true);
                if (location.state.mode === "prescreening") {
                    dispatch(showWaitingPresc(true));
                } else if (location.state.mode === "payment") {
                    dispatch(showWaitingPayment(true));
                }
                setIsLoading(true);
                patientLogin({ sessId: location.state.sessId,appointmentId:location.state.id }).then((success) => {
                    setIsLoading(false);
                    if (success && success.status && success.status.error === false) {
                        /*  if (success.phone !== null && success.phoneVerified === null) {
                             setPhone(success.phone);
                             setPatientId(success.id);
                             setIsShowOTPModal(true);
                             return;
                         } */
                        postLoginAction(success);
                    }
                }).catch((err) => {
                    dispatch(showWaitingPresc(false));
                    dispatch(showWaitingPayment(false));
                    // history.replace('/');
                    setIsLoading(false);
                    setError('password', { type: 'custom', message: err.msg });
                    console.log(err);
                    if (err && err.error === true && err.msg === 'Invalid session.') {
                        setIsInvalidSession(true);
                    }
                });
                // dispatch(loginPending({ sessId: location.state.sessId }));
                return;
            }
        }
    }, []);
    const onLoginClick = (data: any) => {
        let requestData: any = {
            password: getValues('password')
        };
        if (isNaN(getValues('emailorphone'))) {
            requestData = {
                ...requestData,
                email: getValues('emailorphone'), phone: getValues('phone')
            }
        } else {
            requestData = {
                ...requestData,
                phone: getValues('emailorphone'), email: getValues('email')
            };
        }
        if (requestData.password) {
            setIsLoading(true);
            patientLogin(requestData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    if (success.phone !== null && success.phoneVerified === null && success.email !== null && success.emailVerified === null) {
                        setPhone(success.phone);
                        setEmail(success.email);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        return;
                    } else if (success.phone !== null && success.phoneVerified === null) {
                        setPhone(success.phone);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        return;
                    } else if (success.email !== null && success.emailVerified === null) {
                        setEmail(success.email);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        return;
                    }
                    postLoginAction(success);
                }
            }).catch((err) => {
                setIsLoading(false);
                setError('password', { type: 'custom', message: err.msg });
                console.log(err);
            });
        }
    }
    const emailOrPhoneBlur = async (e: any) => {
        try {
            const isValid = await trigger('emailorphone');
            if (isValid) {
                if (isNaN(getValues('emailorphone'))) {
                    if (!emailValidation(getValues('emailorphone'))) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid email' });
                        return;
                    }
                } else {
                    if (getValues('emailorphone').length > 10 || getValues('emailorphone').length < 10) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid phone' });
                        return;
                    }
                }
                setIsLoading(true);
                checkLogin({ login: getValues('emailorphone') }).then((success) => {
                    setIsLoading(false);
                    if (success && success.status && success.status.error === false) {
                        setAskFor(success.askFor);
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    if (err && err.msg === 'Error while sending OTP.') {
                        if (!emailValidation(getValues('emailorphone'))) {
                            setError('emailorphone', { type: 'custom', message: 'Failed to send OTP. Kindly check email.' });
                            return;
                        } else {
                            setError('emailorphone', { type: 'custom', message: 'Failed to send OTP. Kindly check mobile number.' });
                            return;
                        }
                    }
                    setError('emailorphone', { type: 'custom', message: err.msg });
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    const postLoginAction = (data: any) => {
        setIsLoading(true);
        signInWithCustomToken(data.jwtToken).then((response) => {
            setIsLoading(false);
            if (response) {
                const key1 = 'xprUser.patient-' + data.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'xprUserName';
                const key5 = 'jwtToken';
                const key6 = 'xprIsGuarantor';
                var json: any = {};
                json[key1] = true;
                json[key2] = data.uniqueId;
                json[key3] = data.authParam;
                json[key4] = data.firstName + " " + data.lastName;
                json[key5] = data.jwtToken;
                json[key6] = data.isGuarantor;
                Cookies.remove('patient_cookie');
                Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json, { expires: 30, domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json, { expires: 30 });
                if (location.state && location.state.id) {
                    dispatch(showWaitingPresc(false));
                    dispatch(showWaitingPayment(false));
                    console.log('Mediumcheck', location);
                    
                    let url = '/board?id=' + location.state.id + '&medium=' + location.state.medium + '&mode=' + location.state.mode;
                    if (location.state.webview) {
                        url = url + '&webview=yes';
                    }
                    history.replace(url);
                } else {
                    // history.replace('/dashboard/upcoming');
                    history.replace('/board');
                }
            } else {
                toast.error('Something went wrong');
            }
        }).catch((error) => {
            setIsLoading(false);
            toast.error('Something went wrong');
        });
    }
    return (
        <ErrorBoundary>
            <div className="master_patientp">
                {/*  {
                    isLoading &&
                    <LoadingIndicator></LoadingIndicator>
                } */}
                <section className="login-page">
                    <Helmet><title>{TITLES_LABELS.META.LOGIN}</title></Helmet>
                    <div className="container">
                        <div className="row flex-center min-vh-100 py-5">
                            <div className="col-sm-8 col-md-6 col-lg-6 col-xl-5 col-xxl-4 m-auto">
                                <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                    <div className="card shadow-sm ">
                                        <div className="card-header bg-light px-5 py-3">
                                            <img src={require('../../../assets/images/logo-black.svg')} alt="logo" />
                                        </div>
                                        <div className="card-body p-5">
                                            <div className="mb-3">
                                                <label className="form-label fw-medium">{LABELS.EMAILORPHONE}</label>
                                                <input type="text" disabled={isLoading} className="form-control" name="emailorphone" ref={register({ required: true })} onBlur={emailOrPhoneBlur} />
                                                {
                                                    (errors && errors.emailorphone && errors.emailorphone.type === 'required') &&
                                                    <div className="text-danger error">Email or phone is required.</div>
                                                }
                                                {
                                                    (errors && errors.emailorphone && errors.emailorphone.type === 'custom') &&
                                                    <div className="text-danger error">{errors.emailorphone.message}</div>
                                                }
                                            </div>
                                            {
                                                askFor === "email" &&
                                                <div className="mb-3">
                                                    <p>
                                                        We have detected multiple accounts with this mobile number, please enter email associated with this account to sign in.
                                                        In case if you do not have email associated with this account please enter password directly to sign in
                                                    </p>
                                                    <label className="form-label fw-medium">{LABELS.EMAIL}</label>
                                                    <input type="text" disabled={isLoading} className="form-control" name="email" ref={register({
                                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                    })} />
                                                    {
                                                        (errors && errors.email && errors.email.type === 'pattern') &&
                                                        <div className="text-danger error">Email is invalid</div>
                                                    }
                                                </div>
                                            }
                                            {
                                                askFor === "phone" &&
                                                <div className="mb-3">
                                                    <p>
                                                        We have detected multiple accounts with this email, please enter mobile number associated with this account to sign in.
                                                        In case if you do not have mobile number associated with this account please enter password directly to sign in
                                                    </p>
                                                    <label className="form-label fw-medium">{LABELS.PHONE}</label>
                                                    <input type="text" disabled={isLoading} className="form-control" name="phone" ref={register({
                                                        maxLength: 10, minLength: 10
                                                    })} />
                                                    {
                                                        (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                                        <div className="text-danger error">Mobile number is invalid</div>
                                                    }
                                                    {
                                                        (errors && errors.phone && errors.phone.type === 'minLength') &&
                                                        <div className="text-danger error">Mobile number is invalid</div>
                                                    }
                                                </div>
                                            }
                                            {
                                                (askFor === "password" || askFor === "email" || askFor === "phone") &&
                                                <div className="mb-3">
                                                    <label className="form-label fw-medium">{LABELS.PASSWORD}</label>
                                                    <div className="input-group">
                                                        <input type={isViewPassword?"text":"password"} disabled={isLoading} className="form-control" name={'password'} ref={register({ required: true })} />
                                                        <span className="input-group-text bg-transparent">
                                                            <div className="view-password-control opacity-50 fs-7" onClick={()=>{setIsViewPassword(!isViewPassword)}}>
                                                                <i className={isViewPassword ?"bi bi-eye active":"bi bi-eye-slash active"}></i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {
                                                        (errors && errors.password && errors.password.type === 'required') &&
                                                        <div className="text-danger error">Password is required.</div>
                                                    }
                                                    {
                                                        (errors && errors.password && errors.password.type === 'custom') &&
                                                        <div className="text-danger error">{errors.password.message}</div>
                                                    }
                                                </div>
                                            }
                                            <div className="mb-3">
                                                <input className="form-check-input" type="checkbox" name="termsandcond" id="termsandcond" value="Terms and conditions" ref={register({ required: true })} />
                                                <label className="form-check-label mx-2" htmlFor="termsandcond">Agree to <a href="https://www.xcare.app/app-user-terms-of-service/" target="_blank">terms and conditions</a></label>
                                                {
                                                    (errors && errors.termsandcond && errors.termsandcond.type === 'required') &&
                                                    <div className="text-danger error">Please accept Terms and Conditions.</div>
                                                }
                                            </div>
                                            <button type="button" className="btn btn-dark w-100" onClick={handleSubmit(onLoginClick)}>{LABELS.BUTTON.LOGIN}
                                                <span>
                                                    <>
                                                        {
                                                            isLoading === true &&
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="ms-2"
                                                            />
                                                        }
                                                    </>
                                                </span>
                                            </button>
                                            <div className="mt-3 bottom-links">
                                                {
                                                    !['patient.xcarepro.com', 'patient.us.xcarepro.com'].includes(window.location.hostname) &&
                                                    <a className="text-danger d-block text-end" href="javascript:void(0)" onClick={(e) => {
                                                        history.push('/register');
                                                    }}>{TITLES_LABELS.LINKS.REGISTER}</a>
                                                }
                                                <a className="text-danger d-block text-end" href="javascript:void(0)" onClick={(e) => {
                                                    history.push('/forgot-password');
                                                }}>{TITLES_LABELS.LINKS.FORGOT_PASSWORD}</a>
                                                {/* <div className="mt-4 text-center">Don't have account? <a href="javascript:void(0)" onClick={(e) => {
                                                    history.push('/register');
                                                }}>{TITLES_LABELS.LINKS.REGISTER}</a>
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </Animated>
                                {
                                    isShowOTPModal &&
                                    <OTPModal hideModal={() => {
                                        setIsShowOTPModal(false);
                                    }}
                                        sentPhone={phone}
                                        sentEmail={email}
                                        patientId={patientId}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        codeVerifySuccessLogin={postLoginAction}
                                    ></OTPModal>
                                }
                            </div>
                            <div className="text-center app-download-sec">
                                <a href="https://apps.apple.com/us/app/x-care/id1517457992?ls=1" target="_blank" className="app-btn app-store">
                                    <img src={require('../../../assets/images/images/app-store-badge.svg')} alt="app-store" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.xcarepro.android" target="_blank" className="app-btn play-store">
                                    <img src={require('../../../assets/images/images/play-store-badge.svg')} alt="play-store" />
                                </a>
                            </div>
                            <div className="text-center fw-normal">Copyright © {new Date().getFullYear()} Medesphere Inc. All rights reserved.</div>
                        </div>
                    </div>
                </section>

            </div>
            {
                (isInvalidSession) &&
                <InvalidSessionModal isShow={isInvalidSession} />
            }
        </ErrorBoundary>
    )
}