import Cookie from 'js-cookie';
export const getCookie = () => {
    // const str: any = localStorage.getItem('login:Patient');
    // return JSON.parse(str);
    return Cookie.getJSON('patient_cookie');
}
export const isLogin = () => {
    try {
        // const str: any = localStorage.getItem('login:Patient');
        const str: any = Cookie.getJSON('patient_cookie');
        console.log(str);
        if (str === undefined || str === null || str === '') {
            return false;
        }
        // if (JSON.parse(str).uniqueId) {
        if (str.xpr_user_id) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}

export const attachHeader = () => {
    const login = getCookie();
    let headers = {
        'Content-Type': 'application/json',
        // 'x-access-param': login.authParam,
        'x-access-param': login.xprAuthUser,
        // 'xpr_user_id': login.uniqueId,
        'xpr_user_id': login.xpr_user_id,
        'xprApp': 'patient',
        'xcookie': prepareCookie()
    }
    return headers;
}

export const prepareCookie = () => {
    const json = Cookie.getJSON('patient_cookie');
    let cookieString: string = '';
    for (let key in json) {
        if (json.hasOwnProperty(key)) {
            let val = json[key];
            console.log(val, key);
            cookieString = cookieString + key + '=' + val + ';';
        }
    }
    console.log('Cookie String', cookieString);
    console.log('Cookie', Cookie.getJSON('patient_cookie'));
    return cookieString;
}