import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import { updateNotificationPrefrences } from '../../services/notificationPrefrences';
export const NotificationPrefrencesModal = ({ isShow, hideModal, prefrences, pUniqueId, updateSuccess }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, getValues, setValue, errors, watch, handleSubmit, control } = useForm({
        defaultValues: {
            email: prefrences.email,
            phone: prefrences.phone,
            contactMethod: prefrences.contactMethod
        }
    });
    const clickSubmit = (data: any) => {
        const body = {
            id: prefrences.id,
            fname: prefrences.firstName,
            lname: prefrences.lastName,
            relation: prefrences.relation,
            email: data.email,
            phone: data.phone,
            gender: prefrences.gender,
            dob: prefrences.dob,
            contactMethod: data.contactMethod,
            reminder: prefrences.reminder,
        }
        setIsLoading(true);
        updateNotificationPrefrences(body, pUniqueId).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                updateSuccess();
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    return (
        <Modal className="newcommon" centered show={isShow} onHide={hideModal}>
            <Modal.Header>
                <div className="modal-title">Notification Preference</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-3">
                        <label className="form-label">Email</label>
                        <input className="form-control" placeholder={"Please enter your email"} name="email" id="email" ref={register({
                            required: true,
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}></input>
                        {
                            (errors && errors.email && errors.email.type === "required") &&
                            <div className="is-invalid">Email is required.</div>
                        }
                        {
                            (errors && errors.email && errors.email.type === "pattern") &&
                            <div className="is-invalid">Email is invalid.</div>
                        }
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Mobile Number</label>
                        <Controller
                            as={ReactInputMask}
                            control={control}
                            mask="9999999999"
                            name="phone"
                            className="form-control"
                            placeholder="1234567890"
                            rules={{ required: true, minLength: { value: 10, message: 'Mobile Number is invalid' } }}
                        />
                        {
                            (errors && errors.phone && errors.phone.type === 'required') &&
                            <div className="is-invalid">Mobile Number is required</div>
                        }
                        {
                            (errors && errors.phone && errors.phone.type === 'minLength') &&
                            <div className="is-invalid">{errors.phone.message}</div>
                        }
                    </div>
                    <div className="col-12">
                        <label className="form-label">
                            Preferred contact method?
                        </label>
                        <div className="d-md-flex align-items-center">
                            <div className="form-check me-3 my-2 my-md-0">
                                <input className="form-check-input" type="radio" name="contactMethod" id="email-only" value={"email"} ref={register({ required: true })} />
                                <label className="form-check-label" htmlFor="email-only">
                                    Email Only
                                </label>
                            </div>
                            <div className="form-check me-3 my-2 my-md-0">
                                <input className="form-check-input" type="radio" name="contactMethod" id="text-only" value="text" ref={register({ required: true })} />
                                <label className="form-check-label" htmlFor="text-only">
                                    Text Only
                                </label>
                            </div>

                            <div className="form-check me-3 my-2 my-md-0">
                                <input className="form-check-input" type="radio" name="contactMethod" id="et-both" value="email,text" ref={register({ required: true })} />
                                <label className="form-check-label" htmlFor="et-both">
                                    Email & Text
                                </label>
                            </div>
                        </div>
                        {
                            (errors && errors.contactMethod && errors.contactMethod.type === 'required') &&
                            <div className="is-invalid">Contact method is required.</div>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-primary" onClick={(e) => { hideModal(); }}>Cancel</button>
                <button className="btn btn-primary" onClick={handleSubmit(clickSubmit)}>
                    {(isLoading) &&
                        <span className="aft_load right">
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </span>}
                    Submit</button>
            </Modal.Footer>
        </Modal>
    )
}
