import React from 'react';
import { Animated } from 'react-animated-css';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { emailValidation } from '../../utils/validators';
interface Props {
    closeModal: () => void;
    submitAssign: (email: string) => void;
};
interface State {
    email: string;
    emailValidation: { error: boolean, msg: string };
};
class AppointmentAssignModal extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: '',
            emailValidation: { error: false, msg: '' }
        };
    }
    onHide = () => {
        this.props.closeModal();
    }
    onSubmit = () => {
        if (!emailValidation(this.state.email)) {
            this.setState({ emailValidation: { error: true, msg: 'Please enter valid email' } });
            return;
        }
        this.props.submitAssign(this.state.email);
    }
    render() {
        return (
            <Modal className="newcommon" centered show onHide={this.onHide}>
                <Modal.Header>
                    <div className="modal-title">Assign appointment</div>
                    <button type="button" className="btn-close" aria-label="Close" onClick={this.onHide}>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <input className="form-control" placeholder={"Please enter your email"} name="email" id="email" value={this.state.email} onChange={(e) => {
                        this.setState({ email: e.target.value });
                    }} onInput={(e: any) => {
                        this.setState({ email: e.target.value });
                    }}></input>
                    {
                        (this.state.emailValidation.error) ?
                            <label>{this.state.emailValidation.msg}</label> : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { this.onHide(); }}>Close</button>
                    <button className="btn btn-success" onClick={(e) => { this.onSubmit(); }}>Submit</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default AppointmentAssignModal;