import { ERROR_LABELS } from './error';
import { TITLES_LABELS } from './titles';
import { LABELS } from './labels';
import { APPOINTMENT_STATUS } from './status';
import { CHAT } from './chat';
import { CONTACTMETHODS } from './contactMethods';
const APPOINTMENT_AMOUNT = '100';
export {
    ERROR_LABELS,
    TITLES_LABELS,
    LABELS,
    APPOINTMENT_STATUS,
    APPOINTMENT_AMOUNT,
    CHAT,
    CONTACTMETHODS
}