import { PatientDataState, PatientDataActionTypes, PATIENT_DATA, ACCOUNT } from './types';

export const initialState: PatientDataState = {
    pending: false,
    error: null
};

export const patientDataReducer = (state = initialState, action: PatientDataActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case PATIENT_DATA.PATIENT_DATA_LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PATIENT_DATA.PATIENT_DATA_LOAD_PENDING:
            return {
                ...state,
                pending: true,
                patientDataRequest: action.payload
            }
        case PATIENT_DATA.PATIENT_DATA_LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                patientDataResponse: action.patientData
            }
        case ACCOUNT.ACCOUNT_UPDATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ACCOUNT.ACCOUNT_UPDATE_PENDING:
            return {
                ...state,
                pending: true,
                accountUpdateRequest: action.payload
            }
        case ACCOUNT.ACCOUNT_UPDATE_SUCCESS:
            if (action.accountUpdate.updateType === 'name') {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        firstName: action.accountUpdate.firstName,
                        lastName: action.accountUpdate.lastName
                    }
                }
            } else if (action.accountUpdate.updateType === 'phone') {
                const phoneVerify = state.patientDataResponse ? action.accountUpdate.phone === state.patientDataResponse.phone ? state.patientDataResponse.phoneVerified : null : null;
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        phoneVerified: phoneVerify,
                        phone: action.accountUpdate.phone
                    }
                }
            } else if (action.accountUpdate.updateType === 'email') {
                const emailVerify = state.patientDataResponse ? action.accountUpdate.email === state.patientDataResponse.email ? state.patientDataResponse.emailVerified : null : null;
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        emailVerified: emailVerify,
                        email: action.accountUpdate.email
                    }
                }
            } else if (action.accountUpdate.updateType === 'dob') {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        dob: action.accountUpdate.dob
                    }
                }
            } else if (action.accountUpdate.updateType === 'gender') {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        gender: action.accountUpdate.gender
                    }
                }
            } else if (action.accountUpdate.updateType === 'address' && action.accountUpdate.address) {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        addressline1: action.accountUpdate.address.addressline1,
                        addressline2: action.accountUpdate.address.addressline2,
                        country: action.accountUpdate.address.country,
                        state: action.accountUpdate.address.state,
                        city: action.accountUpdate.address.city,
                        postalCode: action.accountUpdate.address.postalCode
                    }
                }
            } else {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate
                }
            }
        case PATIENT_DATA.PATIENT_REFER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PATIENT_DATA.PATIENT_REFER_PENDING:
            return {
                ...state,
                pending: true,
                patientReferRequest: action.payload
            }
        case PATIENT_DATA.PATIENT_REFER_SUCCESS:
            return {
                ...state,
                pending: false,
                patientReferResponse: action.patientRefer
            }
        case PATIENT_DATA.EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PATIENT_DATA.EMAIL_VERIFICATION_PENDING:
            return {
                ...state,
                pending: true,
                emailVerificationRequest: action.payload
            }
        case PATIENT_DATA.EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                emailVerificationResponse: action.emailVerification
            }
        case PATIENT_DATA.REMOVE_DEFAULT_CLINIC:
            return {
                ...state,
                pending: true,
                error: null,
                removeDefaultClinicRequest: action.payload,
                removeDefaultClinicResponse: undefined
            }
        case PATIENT_DATA.REMOVE_DEFAULT_CLINIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                removeDefaultClinicRequest: undefined,
                removeDefaultClinicResponse: undefined
            }
        case PATIENT_DATA.REMOVE_DEFAULT_CLINIC_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                removeDefaultClinicResponse: action.payload,
                patientDataResponse: {
                    ...state.patientDataResponse,
                    defaultClinic: state.patientDataResponse?.defaultClinic.filter((clinic) => {
                        return clinic.id !== state.removeDefaultClinicRequest?.clinicId;
                    }),
                }
            }
        case PATIENT_DATA.ADD_DEFAULT_CLINIC:
            return {
                ...state,
                pending: true,
                error: null,
                addDefaultClinicRequest: action.payload,
                addDefaultClinicResponse: undefined
            }
        case PATIENT_DATA.ADD_DEFAULT_CLINIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                addDefaultClinicRequest: undefined,
                addDefaultClinicResponse: undefined
            }
        case PATIENT_DATA.ADD_DEFAULT_CLINIC_SUCCESS:
            if (state.patientDataResponse && state.addDefaultClinicRequest)
                return {
                    ...state,
                    pending: false,
                    error: null,
                    addDefaultClinicResponse: action.payload,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        defaultClinic: [...state.patientDataResponse.defaultClinic, state.addDefaultClinicRequest.defaultClinicObj],
                    }
                }
            else
                return state;
        case PATIENT_DATA.CHANGE_WRITE_REVIEW:
            if (state.patientDataResponse) {
                return {
                    ...state,
                    patientDataResponse: {
                        ...state.patientDataResponse,
                        appointments: state.patientDataResponse.appointments.map((value, index) => {
                            if (value.uniqueid === action.payload.appointmentUniqueId) {
                                return {
                                    ...value,
                                    rating: action.payload.score,
                                    reviewText: action.payload.comment
                                };
                            }
                            return value;
                        })
                    }
                }
            }
            return state;
        default:
            return NewState;

    }
};