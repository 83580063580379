import React, { useState, useEffect } from 'react';
import { NotificationPrefrencesModal } from './NotificationPrefrencesModal';
import { useSelector, useDispatch } from 'react-redux';
import { patientDataPending } from '../../../store/patientData/actions';
import { getCookie } from '../../../utils';
import { TITLES_LABELS } from '../../constants';
export const Notifications = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [prefrences, setPrefrences]: any = useState('');
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const dispatch = useDispatch();
    useEffect(() => {
        if (patientData) {
            const ID = patientData.id;
            setPrefrences(patientData.dependant.find((item: any) => { return Number(item.loginId) === Number(ID) }))
        }
    },[patientData]);
    const hideModal = () => {
        setIsEdit(false);
    }
    return (
        <div className="container-fluid notification-page">
            <div className="p-3 py-4">
                <h5 className="page-title fw-medium mb-3">Manage notification preference</h5>
                <div className="card">
                    <div className="card-body">
                        <div className="d-md-flex">
                            <div className="me-md-5 mb-2 mb-md-0">
                                <small className="text-muted">Communication email</small>
                                <div>{prefrences && prefrences.email ? prefrences.email : '-'}</div>
                            </div>

                            <div className="me-md-5 mb-2 mb-md-0">
                                <small className="text-muted">Mobile number</small>
                                <div>{prefrences && prefrences.phone ? TITLES_LABELS.PLUS_ONE+prefrences.phone : '-'}</div>
                            </div>

                            <div className="me-md-5 mb-2 mb-md-0">
                                <small className="text-muted">Communication method</small>
                                <div>{prefrences && prefrences.contactMethod ? prefrences.contactMethod : '-'}</div>
                            </div>

                            <div className="ms-auto mt-4 mt-md-0 align-self-center">
                                <button type="button" className="btn edit-btn"
                                    onClick={() => {
                                        setIsEdit(true);
                                    }}
                                >Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                (isEdit) &&
                <NotificationPrefrencesModal
                    isShow={isEdit}
                    prefrences={prefrences}
                    pUniqueId={patientData.uniqueId}
                    hideModal={hideModal}
                    updateSuccess={() => {
                        hideModal();
                        const login: any = getCookie();
                        dispatch(patientDataPending({
                            uniqueId: login.xpr_user_id
                        }))
                    }}
                />
            }
        </div>
    )
}
