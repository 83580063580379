import React from 'react';
import Helmet from 'react-helmet';
import { IAppState } from '../../../store';
import { connect } from 'react-redux';
import { TITLES_LABELS } from '../../../constants';
interface Props {
}
interface State {
}
class ClaimRewardStatus extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
        }
        return true;
    }
    render() {
        return (
            <section className="claim-status pantient-view">
                <Helmet><title>{TITLES_LABELS.META.CLAIM}</title></Helmet>
                <div className="container h-100">
                    <div className="row justify-content-center h-100">

                        <div className="col-lg-6 align-self-center text-center">
                            <h4 className="page-title"><strong>Claim status</strong></h4>
                            <ul className="step-wizard p-0">
                                <li className="step active">
                                    <span className="icon"><img src={require("../../../assets/images/ic_check_24px.svg")} /></span>
                                    <span className="name">Visited</span>
                                </li>
                                <li className="step active">
                                    <span className="icon"><img src={require("../../../assets/images/ic_star_24px.svg")} /></span>
                                    <span className="name">Claming</span>
                                </li>
                                <li className="step disable">
                                    <span className="icon">3</span>
                                    <span className="name">Validated</span>
                                </li>
                                <li className="step disable">
                                    <span className="icon">4</span>
                                    <span className="name">Sent</span>
                                </li>
                            </ul>
                            {/* <ul className="step-wizard p-0">
                                <li className="step active">
                                    <span className="icon"><img src="images/ic_check_24px.svg" /></span>
                                    <span className="name">Visited</span>
                                </li>
                                <li className="step active">
                                    <span className="icon"><img src="images/ic_star_24px.svg" /></span>
                                    <span className="name">Claming</span>
                                </li>
                                <li className="step active">
                                    <span className="icon"><img src="images/ic_done_all_24px.svg" /></span>
                                    <span className="name">Validated</span>
                                </li>
                                <li className="step active">
                                    <span className="icon"><img src="images/ic_near_me_24px.svg" /></span>
                                    <span className="name">Sent</span>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}
const mapDispatchToProps = (dispatch: any) => ({
});
const mapStateToProps = (state: IAppState) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(ClaimRewardStatus);