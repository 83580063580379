import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils";

export const guarantorEdit = (data:any,patientUniqueId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "patient/"+patientUniqueId+"/guarantor/edit/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(),
            withCredentials: true
        }
    }
    return commonApi(request);
}