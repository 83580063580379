import React, { useEffect, useState } from 'react';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { useSelector, useDispatch } from 'react-redux';
import { patientDomain } from '../../../services/patientDomain';
import { getXcareOffers } from '../../../services/xcareOffers';
import configs from '../../../configs/apiConfigs';

import Slider from 'react-slick';

export const AdSlider = () => {
    const [offersList, setOffersList] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        getXcareoffersData();
    }, []);

    const getXcareoffersData = () => {
        dispatch(fetchLoadingPending());
        getXcareOffers().then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success.status.error === false) {
                setOffersList(success.data);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }

    const advslider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };


    return (
        <Slider {...advslider}>
            {
                (offersList && offersList.length > 0) &&
                offersList.map((offer: any) => {
                    return (
                        <div key={offer.id} className="ad_item">
                            <a href="#"><img className="img-fluid" src={configs.IMAGE_URL + '/xcareoffer/' + offer.image.name + '-400x160.' + offer.image.type} alt="" /></a>
                        </div>
                    )
                })
            }
        </Slider>
    )
}