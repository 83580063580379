
export enum FORGOT_PASSWORD {
    LOAD_PENDING = 'LOAD_PENDING_FORGOT_PASSWORD',
    LOAD_ERROR = 'LOAD_FAILED_FORGOT_PASSWORD',
    LOAD_SUCCESS = 'LOAD_SUCCESS_FORGOT_PASSWORD'
};

export interface ForgotPasswordRequest {
    email: string;
    modelType: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface ForgotPasswordResponse {
    status: Status
}
export interface ForgotPasswordState {
    pending: boolean;
    forgotPassword: ForgotPasswordRequest;
    forgotPasswordResponse: ForgotPasswordResponse
    error: any;
}

export interface FetchForgotPasswordPendingAction {
    type: FORGOT_PASSWORD.LOAD_PENDING;
    payload: ForgotPasswordRequest
}

export interface FetchForgotPasswordErrorAction {
    type: FORGOT_PASSWORD.LOAD_ERROR;
    error: any;
}
export interface FetchForgotPasswordSuccessAction {
    type: FORGOT_PASSWORD.LOAD_SUCCESS;
    forgotPassword: ForgotPasswordResponse
}

export type ForgotPasswordActionTypes = FetchForgotPasswordErrorAction | FetchForgotPasswordPendingAction | FetchForgotPasswordSuccessAction;