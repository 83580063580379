import { LoginRequest, LoginPendingAction, AUTH } from './../store/auth/types';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { PATIENT_DATA, PatientDataRequest, PatientDataPendingAction, PatientReferRequest, PatientReferPendingAction, EmailVerificationRequest, EmailVerificationPendingAction, RemoveDefaultClinicPendingAction, RemoveDefaultClinicRequest, AddDefaultClinicRequest, AddDefaultClinicPendingAction } from '../store/patientData/types';
import { patientDataError, patientDataSuccess, patientReferError, patientReferSuccess, emailVerificationErrorAction, emailVerificationSuccessAction, removeDefaultClinicSuccessAction, removeDefaultClinicErrorAction, addDefaultClinicErrorAction, addDefaultClinicSuccessAction } from '../store/patientData/actions';
import { attachHeader } from '../utils';
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';

function execute(request: PatientDataRequest) {
    /* const headers = {
        'Content-Type': 'application/json',
    }; */
    const body = {
        // id: request.userId,
        uniqueid: request.uniqueId
    }
    return axios.post(CONFIG.API_URL + "patientdata/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* patientDataPendingRun(action: PatientDataPendingAction) {
    //    yield delay(2000);
    if (action.payload.doNotShowLoader === true) {
        //do nothing
    } else {
        // yield put(fetchLoadingPending());
    }
    const { response, error } = yield execute(action.payload);
    console.log('Response', JSON.stringify(response));
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(patientDataError(response.data));
            } else {
                yield put(patientDataSuccess(response.data));
            }
        } else {
            yield put(patientDataError(response));
        }
    }
    if (error) {
        yield put(patientDataError(error));
    }
    if (action.payload.doNotShowLoader === true) {
        //do nothing
    } else {
        yield put(fetchLoadingSuccess(false));
    }
}

export function* patientDataPendingWatcher() {
    yield takeEvery(PATIENT_DATA.PATIENT_DATA_LOAD_PENDING, patientDataPendingRun);
}

/* Refer */
function executeRefer(request: PatientReferRequest) {
    /* const headers = {
        'Content-Type': 'application/json',
    }; */
    const body = {
        inviteEmail: request.inviteEmail
    };
    return axios.post(CONFIG.API_URL + "patient/" + request.uniqueId + "/send/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* patientReferPendingRun(action: PatientReferPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeRefer(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(patientReferError(response.data));
            } else {
                yield put(patientReferSuccess(response.data));
            }
        } else {
            yield put(patientReferError(response));
        }
    }
    if (error) {
        yield put(patientReferError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* patientReferPendingWatcher() {
    yield takeEvery(PATIENT_DATA.PATIENT_REFER_PENDING, patientReferPendingRun);
}

function executeEmailVerification(request: EmailVerificationRequest) {
    const body = {
        email: request.email
    };
    return axios.post(CONFIG.API_URL + 'verify/' + request.modelType + "/" + request.modelUniqueid + "/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
export function* emailVerificationRun(action: EmailVerificationPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeEmailVerification(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(emailVerificationErrorAction(response.data));
            } else {
                yield put(emailVerificationSuccessAction(response.data));
            }
        } else {
            yield put(emailVerificationErrorAction(response));
        }
    }
    if (error) {
        yield put(emailVerificationErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* emailVerificationPendingWatcher() {
    yield takeEvery(PATIENT_DATA.EMAIL_VERIFICATION_PENDING, emailVerificationRun);
}

function executeRemoveDefaultClinicRun(request: RemoveDefaultClinicRequest) {
    return axios.get(CONFIG.API_URL + 'patient/' + request.clinicId + "/default/remove/", {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
export function* removeDefaultClinicRun(action: RemoveDefaultClinicPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeRemoveDefaultClinicRun(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(removeDefaultClinicErrorAction(response.data));
            } else {
                yield put(removeDefaultClinicSuccessAction(response.data));
            }
        } else {
            yield put(removeDefaultClinicErrorAction(response));
        }
    }
    if (error) {
        yield put(removeDefaultClinicErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* removeDefaultClinicPendingWatcher() {
    yield takeEvery(PATIENT_DATA.REMOVE_DEFAULT_CLINIC, removeDefaultClinicRun);
}

function executeAddDefaultClinicRun(request: AddDefaultClinicRequest) {
    return axios.get(CONFIG.API_URL + 'patient/' + request.clinicId + "/default/add/", {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
export function* addDefaultClinicRun(action: AddDefaultClinicPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeAddDefaultClinicRun(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(addDefaultClinicErrorAction(response.data));
            } else {
                yield put(addDefaultClinicSuccessAction(response.data));
            }
        } else {
            yield put(addDefaultClinicErrorAction(response));
        }
    }
    if (error) {
        yield put(addDefaultClinicErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* addDefaultClinicPendingWatcher() {
    yield takeEvery(PATIENT_DATA.ADD_DEFAULT_CLINIC, addDefaultClinicRun);
}
