import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';
import StateManager from 'react-select';
import { toast } from 'react-toastify';
export const GuarantorInfoModal = ({ hideModal, patientType, selfguarantor,
    secondaryguarantor, primaryguarantor,
    submit,
    masterInsuranceList,
    selectedGuarantorList
}: any) => {
    console.log(selfguarantor, secondaryguarantor, primaryguarantor);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [guarantorList, setGuarantorList]: any = useState([]);
    const [primaryGuarantorState, setPrimaryGuarantor]: any = useState(null);
    const [secondaryGuarantorState, setSecondaryGuarantor]: any = useState(null);
    const [insurancePlanList, setInsurancePlanList] = useState([]);
    const { register, errors, setError, getValues, setValue, watch, control, handleSubmit } = useForm({
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            gender: 'Male',
            dob: moment().subtract(1, 'day').toDate(),
            insurancename: '',
            insuranceplanname: '',
            guarantorselect: '',
            otherInsurance: '',
            otherInsuranceplanName: ''
        }
    });
    let messageDependant = '';
    if (primaryguarantor && secondaryguarantor[0]) {
        messageDependant = 'You indicated that patient will have primary guarator and secondary guarantor.';
    } else if (primaryguarantor) {
        messageDependant = 'You indicated that patient will have primary guarator.';
    }
    useEffect(() => {
        if (guarantorList[0] && guarantorList[0].id) {
            setValue('guarantorselect', guarantorList[0].id);
            setValue('firstName', guarantorList[0].fname);
            setValue('lastName', guarantorList[0].lname);
            setValue('email', guarantorList[0].email);
            setValue('phone', guarantorList[0].phone);
            if (guarantorList[0].dob) { setValue('dob', guarantorList[0].dob); }
            if (guarantorList[0].gender) { setValue('gender', guarantorList[0].gender); }
        }
    }, [guarantorList]);
    useEffect(() => {
        if (getValues('insurancename') && getValues('insurancename') !== '') {
            let selectedInsurance: any = getValues('insurancename');
            let insurance = masterInsuranceList.find((item: any) => item.id === selectedInsurance);
            if (insurance) {
                if (selectedInsurance === 'other') {
                    insurance = {
                        ...insurance,
                        plan: [{ id: 'other', name: 'Other' }]
                    }
                } else {
                    insurance = {
                        ...insurance,
                        plan: [...insurance.plan, { id: 'other', name: 'Other' }]
                    }
                }
                setInsurancePlanList(insurance.plan)
                if (insurance.plan[0]) {
                    setValue('insuranceplanname', insurance.plan[0].id);
                }
            }
        }
    }, [watch('insurancename')]);
    useEffect(() => {
        if (patientType === 'individual') {
            setSecondaryGuarantor(selectedGuarantorList[0]);
        } else if (patientType === 'dependent') {
            setPrimaryGuarantor(selectedGuarantorList[0]);
            setSecondaryGuarantor(selectedGuarantorList[1]);
        }
    }, [selectedGuarantorList]);
    const handleSubmitPrimaryGuarantor = (data: any) => {
        let insurance = masterInsuranceList.find((item: any) => item.id === getValues('insurancename'));
        let insurancePlan: any = insurancePlanList.find((item: any) => item.id === getValues('insuranceplanname'));
        if (getValues('guarantorselect')) {
            const guarantorSelected = guarantorList.find((item: any) => item.id === getValues('guarantorselect'));
            if (guarantorSelected) {
                setPrimaryGuarantor({
                    ...guarantorSelected,
                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                    insurancename: insurance ? insurance : '',
                    insuranceplanname: getValues('insuranceplanname'),
                    insurance: {
                        id: insurance.id,
                        name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                        plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                        planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                    },
                })
            }
        } else {
            setPrimaryGuarantor({
                fname: getValues('firstName'),
                lname: getValues('lastName'),
                email: getValues('email'),
                phone: getValues('phone'),
                gender: getValues('gender'),
                dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                insurancename: insurance ? insurance : '',
                insuranceplanname: getValues('insuranceplanname'),
                insurance: {
                    id: insurance.id,
                    name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                    plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                    planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                },
            })
        }
    }
    const handleSubmitSecondaryGuarantor = (data: any) => {
        let insurance = masterInsuranceList.find((item: any) => item.id === getValues('insurancename'));
        let insurancePlan: any = insurancePlanList.find((item: any) => item.id === getValues('insuranceplanname'));
        console.log('insurancepLAN', insurancePlan);
        if (getValues('guarantorselect')) {
            const guarantorSelected = guarantorList.find((item: any) => item.id === getValues('guarantorselect'));
            if (guarantorSelected) {
                setSecondaryGuarantor({
                    ...guarantorSelected,
                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                    insurancename: insurance ? insurance : '',
                    insuranceplanname: getValues('insuranceplanname'),
                    insurance: {
                        id: insurance.id,
                        name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                        plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                        planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                    },
                })
            }
        } else {
            if (primaryGuarantorState && getValues('email') === primaryGuarantorState.email && getValues('phone') === primaryGuarantorState.phone) {
                toast.error('Secondary guarantor email and phone should be different than primary guarantor.');
                return;
            }
            setSecondaryGuarantor({
                fname: getValues('firstName'),
                lname: getValues('lastName'),
                email: getValues('email'),
                phone: getValues('phone'),
                gender: getValues('gender'),
                dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                insurancename: insurance ? insurance : '',
                insuranceplanname: getValues('insuranceplanname'),
                insurance: {
                    id: insurance.id,
                    name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                    plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                    planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                },
            })
        }
    }
    useEffect(() => {
        if (getValues('guarantorselect')) {
            const guarantorSelected = guarantorList.find((item: any) => item.id === getValues('guarantorselect'));
            if (guarantorSelected) {
                setValue('firstName', guarantorSelected.fname);
                setValue('lastName', guarantorSelected.lname);
                setValue('email', guarantorSelected.email);
                setValue('phone', guarantorSelected.phone);
                if (guarantorSelected.dob) { setValue('dob', guarantorSelected.dob); }
                if (guarantorSelected.gender) { setValue('gender', guarantorSelected.gender); }
            }
        }
    }, [watch('guarantorselect')]);
    const handleSubmitBtn = () => {
        let data = [];
        if (patientType === 'dependent') {
            if (primaryGuarantorState && !secondaryGuarantorState && (secondaryguarantor !== "secondary")) {
                data.push(primaryGuarantorState);
            } else {
                data.push(primaryGuarantorState);
                data.push(secondaryGuarantorState);
            }
        } else if (patientType === 'individual') {
            data.push(secondaryGuarantorState);
        }
        submit(data);
    }
    return (
        <Modal className="newcommon addnewappt pantientAddNew" centered show={true} backdrop={true} onHide={() => { hideModal() }}>
            <Modal.Header>
                <div className="modal-title">Guarantor info</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => { hideModal() }}></button>
            </Modal.Header>
            <Modal.Body>
                {
                    patientType === 'individual' &&
                    <div className="alert alert-warning py-2">
                        You indicated that patient will have self guarator and secondary guarantor
                    </div>
                }
                {
                    patientType === 'dependent' &&
                    <div className="alert alert-warning py-2">
                        {messageDependant}
                    </div>
                }
                <>
                    {
                        primaryGuarantorState &&
                        <div className="alert alert-success alert-dismissible">Primary Guarantor : {
                            primaryGuarantorState.fname + " " + primaryGuarantorState.lname + " (" +
                            primaryGuarantorState.email + ")"
                        } <button className="btn-close" onClick={() => {
                            setPrimaryGuarantor(null);
                            setSecondaryGuarantor(null);
                        }}></button></div>
                    }
                    {
                        secondaryGuarantorState &&
                        <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                            secondaryGuarantorState.fname + " " + secondaryGuarantorState.lname + " (" +
                            secondaryGuarantorState.email + ")"
                        } <button className="btn-close" onClick={() => {
                            setSecondaryGuarantor(null);
                        }}></button></div>
                    }
                </>
                {
                    <>

                        {
                            guarantorList.length !== 0 &&
                            <div className="row mb-3">
                                <div className="col">
                                    <label className="form-label">Select Guarantor:</label>
                                    <select className="form-select" name="guarantorselect" ref={register}>
                                        {
                                            guarantorList.map((item: any) => {
                                                return (<option key={item.id} value={item.id}>{item.fname + " " + item.lname + " - " + item.email}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                        <div className="row g-3">
                            <div className="col-md-6">
                                <label className="form-label">First Name:</label>
                                <input type="text" className="form-control" placeholder="Enter first name" name="firstName" ref={register({ required: true })} />
                                {
                                    (errors && errors.firstName && errors.firstName.type === 'required') &&
                                    <div className="is-invalid">First name is required</div>
                                }
                            </div>

                            <div className="col-md-6">
                                <label className="form-label">Last Name:</label>
                                <input type="text" className="form-control" placeholder="Enter last name" name="lastName" ref={register({ required: true })} />
                                {
                                    (errors && errors.lastName && errors.lastName.type === 'required') &&
                                    <div className="is-invalid">Last name is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Enter Email:</label>
                                <input type="email" className="form-control" placeholder="Enter email" name="email" ref={register({ required: true })} />
                                {
                                    (errors && errors.email && errors.email.type === 'required') &&
                                    <div className="is-invalid">Email is required</div>
                                }
                            </div>

                            <div className="col-md-6">
                                <label className="form-label">Enter mobile number:</label>
                                <Controller
                                    as={ReactInputMask}
                                    control={control}
                                    mask="9999999999"
                                    name="phone"
                                    className="form-control"
                                    placeholder="1234567890"
                                    rules={{ required: true, minLength: { value: 10, message: 'Mobile number is invalid' } }}
                                />
                                {
                                    (errors && errors.phone && errors.phone.type === 'required') &&
                                    <div className="is-invalid">Mobile is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Gender</label>
                                <select className="form-select" name="gender" ref={register({ required: true })}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                                {
                                    (errors && errors.gender && errors.gender.type === 'required') &&
                                    <div className="is-invalid">First name is required</div>
                                }
                            </div>
                            <div className="col-md-6 miuif_margin">
                                <label className="form-label">Date of birth</label>
                                <Controller
                                    name="dob"
                                    control={control}
                                    render={(props) => {
                                        return (
                                            <KeyboardDatePicker
                                                margin="none"
                                                id={"date-picker-dialog-dob"}
                                                name={"date-picker-dialog-dob"}
                                                format="YYYY/MM/DD"
                                                maxDate={moment().subtract(1, 'days')}
                                                value={props.value}
                                                onChange={(date: any) => { props.onChange(date) }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        )
                                    }}
                                ></Controller>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Insurance name:</label>
                                <select className="form-select" name="insurancename" ref={register({ required: true })}>
                                    {
                                        masterInsuranceList.map((item: any) => (
                                            <option value={item.id} key={item.id + item.name}>{item.name}</option>
                                        ))
                                    }
                                </select>
                                {
                                    (watch('insurancename') === 'other') &&
                                    <input type="text" className="form-control" placeholder="Enter insurance plan name" name="otherInsurance" ref={register({ required: true })} />
                                }
                                {
                                    (errors && errors.otherInsurance && errors.otherInsurance.type === 'required') &&
                                    <div className="text-danger error">Insurance plan name is required.</div>
                                }
                                {/* <input type="text" className="form-control" placeholder="Enter insurance name" name="insurancename" ref={register({ required: true })} /> */}
                                {
                                    (errors && errors.insurancename && errors.insurancename.type === 'required') &&
                                    <div className="is-invalid">Insurance name is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Insurance plan name:</label>
                                <select className="form-select" name="insuranceplanname" ref={register({ required: true })} placeholder="Insurance plan name">
                                    {
                                        insurancePlanList.map((item: any) => (
                                            <option value={item.id} key={item.id + item.name}>{item.name}</option>
                                        ))
                                    }
                                </select>
                                {
                                    (errors && errors.insuranceplanname && errors.insuranceplanname.type === 'required') &&
                                    <div className="text-danger error">Insurance plan name is required.</div>
                                }
                                {
                                    (watch('insuranceplanname') === 'other') &&
                                    <input type="text" className="form-control" placeholder="Enter insurance plan name" name="otherInsuranceplanName" ref={register({ required: true })} />
                                }
                                {
                                    (errors && errors.otherInsuranceplanName && errors.otherInsuranceplanName.type === 'required') &&
                                    <div className="text-danger error">Insurance plan name is required.</div>
                                }
                            </div>

                        </div>
                    </>
                }
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-outline-secondary mr-2" onClick={() => {
                    setValue('guarantorselect', '');
                    setValue('firstName', '');
                    setValue('lastName', '');
                    setValue('gender', 'Male');
                    setValue('dob', moment().subtract(1, 'day').toDate());
                    setValue('email', '');
                    setValue('phone', '');
                    setValue('insurancename', '');
                    setValue('insuranceplanname', '');
                }}>Reset</button>

                {
                    patientType === 'dependent' &&
                    <>
                        {
                            !primaryGuarantorState && !secondaryGuarantorState &&
                            <button className={"btn btn-primary"} type="submit" onClick={handleSubmit(handleSubmitPrimaryGuarantor)}>Add as primary</button>
                        }
                        {
                            primaryGuarantorState && !secondaryGuarantorState && (secondaryguarantor === "secondary") &&
                            <button className={"btn btn-primary"} type="submit" onClick={handleSubmit(handleSubmitSecondaryGuarantor)}>Add as secondary</button>
                        }
                        {
                            primaryGuarantorState && !secondaryGuarantorState && (secondaryguarantor !== "secondary") &&
                            <button className={"btn btn-primary"} type="button" onClick={handleSubmitBtn}>Submit</button>
                        }
                        {
                            primaryGuarantorState && (secondaryGuarantorState && secondaryguarantor === "secondary") &&
                            <button className={"btn btn-primary"} type="button" onClick={handleSubmitBtn}>Submit</button>
                        }
                    </>
                }
                {
                    patientType === 'individual' &&
                    <>
                        {
                            !secondaryGuarantorState &&
                            <button className={"btn btn-primary"} type="submit" onClick={handleSubmit(handleSubmitSecondaryGuarantor)}>Add as secondary</button>
                        }
                        {
                            secondaryGuarantorState &&
                            <button className={"btn btn-primary"} type="button" onClick={handleSubmitBtn}>Submit</button>
                        }
                    </>
                }
            </Modal.Footer>
        </Modal >
    )
}