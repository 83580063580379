import {
    ProviderSearchRequest, ProviderSearchPendingAction, PROVIDER, ProviderSearchErrorAction,
    ProviderSearchResponse, ProviderSearchSuccessAction
} from "./types";

export const searchProviderPending = (request: ProviderSearchRequest): ProviderSearchPendingAction => {
    return {
        type: PROVIDER.SEARCH_PROVIDER_PENDING,
        payload: request
    }
}
export const searchProviderError = (error: any): ProviderSearchErrorAction => {
    return {
        type: PROVIDER.SEARCH_PROVIDER_PENDING_ERROR,
        payload: error
    }
}
export const searchProviderSuccess = (response: ProviderSearchResponse): ProviderSearchSuccessAction => {
    return {
        type: PROVIDER.SEARCH_PROVIDER_PENDING_SUCCESS,
        payload: response
    }
}