import axios from "axios"
import configs from "../configs/apiConfigs";
import {CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";

export const joinLoyalty = (id: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "joinloyalty/"+id+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const getLoyaltyActionList = (id: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "loyaltyactionlist/"+id+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}