import React, { useState, useEffect } from 'react';
import { Alert, Modal, ModalBody, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import apiConfigs from '../../configs/apiConfigs';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { showWaitingPresc, userLogout } from '../../../store/auth/actions';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
export const CheckInModal = ({isCheckInWarning, checkInModal, hideCheckInModal, currentSelectedAppointment, checkInSuccess }: any) => {
    let db: any;
    const [checkInBox, setCheckInBox]: any = useState(-1);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        db = firebase.firestore();
    }, []);
    const onCheckInClick = (text: string) => {
        if (currentSelectedAppointment && patientData) {
            const obj = {
                "appointmentId": Number(currentSelectedAppointment.uniqueid),
                "senderId": Number(patientData.uniqueId),
                "sender": CHAT.SENDER.PATIENT,
                "action": CHAT.ACTION.CBP,
                "actionType": CHAT.ACTION_TYPE.CHECKIN,
                "text": text,
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": currentSelectedAppointment.clinic.uniqueid,
                "sendToDId": currentSelectedAppointment.doctor.uniqueid,
                "metadata": {
                    "viewByClinicAt": null,
                    "viewByPatientAt": null,
                },
                "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
                dateTime: {
                    date: currentSelectedAppointment.date,
                    time: currentSelectedAppointment.time
                }
            };
            dispatch(fetchLoadingPending());
            console.log(obj);
            db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                .then(() => {
                    setTimeout(() => {
                        dispatch(fetchLoadingSuccess(false));
                        console.log("Document successfully written!");
                        checkInSuccess();
                    }, 3000);
                })
                .catch((error: any) => {
                    dispatch(userLogout());
                    history.push('/');
                    console.error("Error writing document: ", error);
                });
        }
    }
    return (
        <Modal centered className="chk_in newcommon" show={checkInModal} onHide={() => {
            hideCheckInModal()
        }}>
            <Modal.Header>
                <Modal.Title>Check-in</Modal.Title>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                    hideCheckInModal();
                }}>
                </button>
            </Modal.Header>
            <ModalBody>
                {
                    isCheckInWarning ? 
                        <div className='m-2 d-flex'>
                            {/* Check-in will be allowed 1 hour prior to your appointment time. Please try again later! */}
                            Appointment is in the past, please contact clinic admin.
                        </div>
                        :
                        <ul className="checkinlist">
                    <li className="list-group-item">
                        <div className="form-check">
                            <input type="checkbox" id="checkin1" value={"Check-in Now"} className="form-check-input"
                                checked={checkInBox === 0}
                                onChange={(e: any) => {
                                    onCheckInClick(e.target.value);
                                    setCheckInBox(0);
                                }}
                            />
                            <label title="" htmlFor="checkin1" className="custom-control-label">Check-in now</label>
                        </div>
                    </li>

                    <li className="list-group-item">
                        <div className="form-check">
                            <input type="checkbox" id="checkin2" value={"Running a bit late (5-10 mins)"}
                                className="form-check-input"
                                checked={checkInBox === 1}
                                onChange={(e: any) => {
                                    onCheckInClick(e.target.value);
                                    setCheckInBox(1);
                                }}
                            />
                            <label title="" htmlFor="checkin2" className="custom-control-label">Running a bit late <span>(5-10 mins)</span></label>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="form-check">
                            <input type="checkbox" id="checkin3" value={"Stuck in traffic (15-30 mins)"}
                                className="form-check-input"
                                checked={checkInBox === 2}
                                onChange={(e: any) => {
                                    onCheckInClick(e.target.value);
                                    setCheckInBox(2);
                                }}
                            />
                            <label title="" htmlFor="checkin3" className="custom-control-label">Stuck in traffic <span>(15-30 mins)</span></label>
                        </div>
                    </li>
                </ul>

                }
                
            </ModalBody>
        </Modal>
    )
}
