import { PAYMENT, PaymentClientSecretPendingAction, PaymentClientSecretRequest } from "../store/payment/types";
import { takeEvery, put } from "redux-saga/effects";
import Axios from "axios";
import configs from "../configs/apiConfigs";
import { attachHeader } from "../utils";
import { paymentClientSecretErrorAction, paymentClientSecretSuccessAction } from "../store/payment/actions";
function execute(request: PaymentClientSecretRequest) {
    return Axios.get(configs.API_URL + "makepayment/patient/" + request.appointmentId + "/", {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* paymentClientSecretPendingRun(action: PaymentClientSecretPendingAction) {
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status !== 200 || response.data === "" || (response.data && response.data.status && response.data.status.error)) {
            yield put(paymentClientSecretErrorAction('Something went wrong'));
            return
        }
        yield put(paymentClientSecretSuccessAction(response.data));
    } else if (error) {

    } else {
        yield put(paymentClientSecretErrorAction('Something went wrong'));
    }
}
export function* paymentClientSecretPendingWatcher() {
    yield takeEvery(PAYMENT.PAYMENT_CLIENT_SECRET, paymentClientSecretPendingRun);
}
