import React, { useEffect, useState } from 'react'
import configs from '../../../configs/apiConfigs'
import moment from 'moment';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { Controller, useForm } from 'react-hook-form';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import apiConfigs from '../../../configs/apiConfigs';
import { getCookie } from '../../../utils/cookies';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { showWaitingPresc, userLogout } from '../../../../store/auth/actions';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Spinner } from 'react-bootstrap';

export const ReSchedule = ({ appointment, handleModalClose, handleSuccess, handleSuccessReschedule }: any) => {
    const [endTime, setEndTime]: any = useState('');
    let db: any;
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [fullname, setFullname] = useState('');
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            date: appointment ? moment(appointment.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : moment(),
            starttime: appointment && appointment.time ? moment(appointment.time, 'LT') : moment().format('LT'),
            endtime: appointment ? moment(appointment.time, 'LT').add(appointment.duration, 'minutes') : moment().format('LT'),
            instruction: ''
        }
    });
    useEffect(() => {
        if (appointment) {

            if (appointment.time && appointment.duration) {
                // endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                setEndTime(moment(appointment.time, 'LT').add(appointment.duration, 'minutes'));
            }
            if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
                setFullname(appointment.dependant.firstName + " " + appointment.dependant.lastName);
            }

            setValue('date', moment(appointment.date, 'YYYY-MM-DD').format('MM/DD/YYYY'));
            setValue('starttime', appointment.time ? moment(appointment.time, 'LT') : moment());
            setValue('endtime', moment(appointment.time, 'LT').add(appointment.duration, 'minutes'));
        }
    }, [appointment]);

    const handleReSchedule = (data: any) => {
        let dateTemp: any = '', startTimeTemp: any = '',endTimeTemp:any='';
        dateTemp = moment(data.date).format('YYYY-MM-DD');
        startTimeTemp = moment(data.starttime).format('LT');
        endTimeTemp = moment(data.endtime).format('LT');
        if (moment(dateTemp +" "+ startTimeTemp, 'YYYY-MM-DD LT').isBefore(moment().format('YYYY-MM-DD LT'))) {
            toast.warn('Date and time should be greater than current date and time');
            return;
        }
        if (moment(dateTemp +" "+ startTimeTemp, 'YYYY-MM-DD LT').isAfter(moment(dateTemp +" "+ endTimeTemp, 'YYYY-MM-DD LT').format('YYYY-MM-DD LT'))) {
            toast.warn('End time should not be previous.');
            return;
        }
        var textMessage: any = '';
        // "text": "Appointment on " + moment(getValues('date')).format('MMM DD, YYYY') + " " + moment(getValues('starttime')).format('LT') + " is requested to be re-scheduled by " + fullname + " at " + appointment.clinic.name + " with " + appointment.doctor.fullname + ". XcarePro id for Reference: " + appointment.uniqueid,
        if (patientData.id === appointment.dependant.loginId) {
            // textMessage = "Appointment on " + moment(getValues('date')).format('MMM DD,YYYY') + "-" + moment(getValues('starttime')).format('LT') + " with " + appointment.doctor.fullname + " is requested to be re-scheduled by " + appointment.patient.firstName + " " + appointment.patient.lastName + ".XCarePro id: " + appointment.uniqueid;
            textMessage = "Appointment on " + moment(appointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY')+ " - " + appointment.time + " with " + appointment.doctor.fullname + " is requested to be re-scheduled between " + moment(getValues('date')).format('MMM DD, YYYY') + " - " + moment(getValues('starttime')).format('LT') + " and " + moment(data.endtime).format('LT') + ", by " + appointment.patient.firstName + " " + appointment.patient.lastName + ". XcarePro id: " + appointment.uniqueid;
        } else {
            // textMessage = "Appointment on " + moment(getValues('date')).format('MMM DD,YYYY') + "-" + moment(getValues('starttime')).format('LT') + " with " + appointment.doctor.fullname + " for " + appointment.dependant.firstName + " " + appointment.dependant.lastName + " is requested to be re-scheduled by " + appointment.patient.firstName + " " + appointment.patient.lastName + ".XCarePro id: " + appointment.uniqueid;
            textMessage = "Appointment on " + moment(appointment.date, 'YYYY-MM-DD').format('MMM DD, YYYY')+ " - " + appointment.time + " with " + appointment.doctor.fullname + " for " + appointment.dependant.firstName + " " + appointment.dependant.lastName + " is requested to be re-scheduled between " + moment(getValues('date')).format('MMM DD, YYYY') + " - " + moment(getValues('starttime')).format('LT') + " and " + moment(data.endtime).format('LT') + ", by " + patientData.firstName + " " + patientData.lastName + ". XcarePro id: " + appointment.uniqueid;
        }
        db = firebase.firestore();
        // dispatch(fetchLoadingPending());
        const user: any = getCookie();
        const obj = {
            "appointmentId": Number(appointment.uniqueid),
            "senderId": Number(user.xpr_user_id),
            "sender": CHAT.SENDER.PATIENT,
            "action": "Cancel by patient",
            "actionType": CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT,
            "text": textMessage,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": appointment.clinic.uniqueid,
            "addedFromMessageCenter": false,
            "dependantId": Number(appointment.dependant.uniqueid),
            "origin": 'selfcare',
            "metadata": { "markReadByClinicAt": '',"viewByClinicAt":null },
            "addedFromPatient":true,
            dateTime: {
                date: dateTemp,
                time: startTimeTemp,
                startTime: startTimeTemp,
                endTime:endTimeTemp
            }
        };
        console.log('firestoreConfirmObj', obj);
        setIsLoading(true);
        db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                console.log("Document successfully written!");
                setTimeout(() => {
                    // fetchAppointmentApi();
                    handleSuccess();
                }, 3000);
            })
            .catch((error: any) => {
                setIsLoading(false);
                dispatch(userLogout());
                history.push('/');
                console.error("Error writing document: ", error);
            });
    }
    return (
        <>

            <div className="offcanvas-action d-flex">
                {
                    (isLoading) ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            variant="primary"
                            role="status"
                            aria-hidden="true"
                        />
                        :
                        <button type="button" className="btn btn-link p-0 fw-normal fs-7"
                            // data-bs-toggle="offcanvas"
                            // data-bs-target="#my_family"
                            // aria-controls="my_family"
                            onClick={handleSubmit(handleReSchedule)}
                        >
                            Save
                        </button>

                }
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    onClick={() => { handleModalClose() }}>
                    Cancel
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Re-schedule</h5>
            </div>
            <div className="offcanvas-body">

                <div className="offcanvas-subtitle">Current appointment:</div>

                <div className="appo_card card p-4 position-relative mb-4">
                    <div className="d-flex align-items-center appo_with mb-4">
                        <div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
                            <img alt="Image placeholder" src={configs.IMAGE_URL + '/doctor/' + appointment.doctor.photo.name + '-200x200.' + appointment.doctor.photo.type} />
                        </div>
                        <div className="av-txt">
                            <div className="av_title">{appointment.doctor.fullname ? appointment.doctor.fullname : ''}</div>
                            <div className="av_subtitle opacity-50">{appointment.doctor.speciality} - {appointment.clinic.name ? appointment.clinic.name : '-'}</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center appo_for">
                        <div className="av-txt">
                            <div className="av_title fw-normal">{fullname ? fullname : '-'}</div>
                            <div className="av_subtitle">{appointment.date ? moment(appointment.date).format('MMM DD, YYYY') : '-'} ({appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'})</div>
                        </div>
                    </div>
                </div>

                <div className="offcanvas-subtitle">Re-schedule to:</div>

                <div className="re_schduled_form miuif_margin">
                    <div className="mb-3">
                        <Controller
                            name="date"
                            control={control}
                            defaultValue={moment()}
                            render={(props) => (
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    format="MM/DD/YYYY"
                                    name="date"
                                    minDate={moment()}
                                    className="form-control"
                                    value={props.value}
                                    onChange={(date: any) => props.onChange(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            )}
                        ></Controller>
                    </div>

                    <div className="mb-3">
                        <Controller
                            name="starttime"
                            control={control}
                            defaultValue={moment()}
                            render={(props) => (
                                <KeyboardTimePicker
                                    id="date-picker-dialog2"
                                    className="form-control"
                                    name="starttime"
                                    value={props.value}
                                    onChange={(date: any) => props.onChange(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            )}
                        ></Controller>
                    </div>

                    <div className="mb-3">
                        <Controller
                            name="endtime"
                            control={control}
                            defaultValue={moment()}
                            render={(props) => (
                                <KeyboardTimePicker
                                    id="date-picker-dialog3"
                                    className="form-control"
                                    name="endtime"
                                    value={props.value}
                                    onChange={(date: any) => props.onChange(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            )}
                        ></Controller>
                    </div>

                    <div className="mb-3">
                        <textarea className="form-control" id="" rows={3} placeholder="Instruction for clinic admin" ref={register()}></textarea>
                    </div>
                </div>

            </div>
        </>
    )
}
