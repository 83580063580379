import React from 'react';
import { Modal } from 'react-bootstrap';
export const ConfirmationModal = ({ isConfirm, hideConfirmationModal, onClickDelete, title, confirmText }: any) => {
    return (
        <Modal className="newcommon" show={isConfirm} onHide={hideConfirmationModal} backdrop={true} keyboard={true} centered>
            <Modal.Body>
                <div className="d-flex justify-content-center btns-box">
                    <div className="modal-title">{title ? title : `Are you sure you want to delete this card ?`}</div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <button onClick={onClickDelete} className="btn btn-dark mx-2 px-3">{confirmText ? confirmText : `Delete`}</button>
                <button onClick={hideConfirmationModal} className="btn btn-dark mx-2 px-3">Cancel</button>
            </Modal.Footer>
        </Modal>
    )
}
