export enum APPOINTMENT {
    LOAD_PENDING = 'LOAD_PENDING',
    LOAD_ERROR = 'LOAD_ERROR',
    LOAD_SUCCESS = 'LOAD_SUCCESS',
    CHANGE_APPOINTMENT_REMINDER_PENDING = 'CHANGE_APPOINTMENT_REMINDER_PENDING',
    CHANGE_APPOINTMENT_REMIDER_ERROR = 'CHANGE_APPOINTMENT_REMIDER_ERROR',
    CHANGE_APPOINTMENT_REMINDER_SUCCESS = 'CHANGE_APPOINTMENT_REMINDER_SUCCESS',
    WRITE_REVIEW_PENDING = 'WRITE_REVIEW_PENDING',
    WRITE_REVIEW_ERROR = 'WRITE_REVIEW_ERROR',
    WRITE_REVIEW_SUCCESS = 'WRITE_REVIEW_SUCCESS',
    ASSIGN_APPOINTMENT = 'ASSIGN_APPOINTMENT',
    ASSIGN_APPOINTMENT_ERROR = 'ASSIGN_APPOINTMENT_ERROR',
    ASSIGN_APPOINTMENT_SUCCESS = 'ASSIGN_APPOINTMENT_SUCCESS'
}
export interface AppointmentDetailRequest {
    appointmentUniqueId: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface AppointmentDetailResponse {
    id: string;
    uniqueid: string;
    currentStatus: string;
    requests: any;
    date: any;
    time: any;
    claimStus: any;
    claimStatus: any;
    clinic: {
        id: string;
        email: string;
        phone: string;
        uniqueid: string;
        name: string;
        address?: any;
    };
    doctor: {
        fullname: string;
        id: string;
        email: string;
        uniqueid: string;
        gender: string;
        phone: string;
        photo: { name: string, type: string };
        providerId: string;
    };
    metadata: {
        referral: string;
        patient: {
            reminder: number;
            isNewPatient: boolean;
        },
        appointment: {
            cancellation: {
                reason: any;
                allowRescheduling: any;
            },
            prescreening: any;
        }
    };
    status: Status;
    dependant:any;
}
export interface ChangeAppointmentRemiderRequest {
    appointmentUniqueId: string;
    reminderStatus: number;
}
export interface ChangeAppointmentRemiderResponse {
    reminder: number;
    status: Status;
}
export interface WriteReviewRequest {
    score: number;
    comment: string;
    appointment: number;
    patientUniqueId: string;
}
export interface WriteReviewResponse {
    status: { error: false, msg: string };
}
export interface AssignAppointmentRequest {
    appointmentUniqueId: number;
    email: string;
}
export interface AssignAppointmentResponse {
    status: Status;
    email: string;
}
export interface AppointmentDetailState {
    pending: boolean;
    appointmentDetailRequest?: AppointmentDetailRequest;
    appointmentDetailResponse?: AppointmentDetailResponse;
    changeAppointmentReminderRequest?: ChangeAppointmentRemiderRequest;
    changeApppointmentRemiderResponse?: ChangeAppointmentRemiderResponse;
    writeReviewRequest?: WriteReviewRequest;
    writeReviewResponse?: WriteReviewResponse;
    assignAppointmentRequest?: AssignAppointmentRequest;
    assignAppointmentResponse?: AssignAppointmentResponse;
    error: any;
}

export interface AppointmentDetailPendingAction {
    type: APPOINTMENT.LOAD_PENDING;
    payload: AppointmentDetailRequest;
}
export interface AppointmentDetailErrorAction {
    type: APPOINTMENT.LOAD_ERROR;
    error: any;
}
export interface AppointmentDetailSuccessAction {
    type: APPOINTMENT.LOAD_SUCCESS;
    appointmentDetail: AppointmentDetailResponse;
}

export interface ChangeAppointmentRemiderPendingAction {
    type: APPOINTMENT.CHANGE_APPOINTMENT_REMINDER_PENDING;
    payload: ChangeAppointmentRemiderRequest;
}
export interface ChangeAppointmentRemiderErrorAction {
    type: APPOINTMENT.CHANGE_APPOINTMENT_REMIDER_ERROR;
    error: any;
}
export interface ChangeAppointmentRemiderSuccessAction {
    type: APPOINTMENT.CHANGE_APPOINTMENT_REMINDER_SUCCESS;
    changeAppointmentRemider: ChangeAppointmentRemiderResponse;
}
export interface WriteReviewPendingAction {
    type: APPOINTMENT.WRITE_REVIEW_PENDING,
    payload: WriteReviewRequest;
}
export interface WriteReviewErrorAction {
    type: APPOINTMENT.WRITE_REVIEW_ERROR,
    payload: any;
}
export interface WriteReviewSuccessAction {
    type: APPOINTMENT.WRITE_REVIEW_SUCCESS,
    payload: WriteReviewResponse;
}
export interface AssignAppointmentPendingAction {
    type: APPOINTMENT.ASSIGN_APPOINTMENT,
    payload: AssignAppointmentRequest,
}
export interface AssignAppointmentErrorAction {
    type: APPOINTMENT.ASSIGN_APPOINTMENT_ERROR,
    payload: any,
}
export interface AssignAppointmentSuccessAction {
    type: APPOINTMENT.ASSIGN_APPOINTMENT_SUCCESS,
    payload: AssignAppointmentResponse,
}
export type AppointmentDetailTypes = AppointmentDetailErrorAction | AppointmentDetailPendingAction | AppointmentDetailSuccessAction |
    ChangeAppointmentRemiderErrorAction | ChangeAppointmentRemiderPendingAction | ChangeAppointmentRemiderSuccessAction |
    WriteReviewErrorAction | WriteReviewPendingAction | WriteReviewSuccessAction |
    AssignAppointmentSuccessAction | AssignAppointmentPendingAction | AssignAppointmentErrorAction;