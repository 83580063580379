import axios from "axios"
import configs from "../configs/apiConfigs";
import {BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";
import { getCookie } from "../utils/cookies";

export const addMember = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "addmember/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}
export const editMember = (data: any,Id:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "editmember/"+Id+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}