import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";
import axios from "axios";

export const addCoverage = (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('for', data.for);
    for (let i = 0; i < data.coverageDetails.length; i++) {
        formData.append('type[' + i + ']', data.coverageDetails[i].type);
        formData.append('amount[' + i + ']', data.coverageDetails[i].amount);
    }
    if (data.coverageId) {
        for (let i = 0; i < data.coverageDetails.length; i++) {
            formData.append('detailId[' + i + ']', data.coverageDetails[i].id);
        }
    }
    return axios.post(
        configs.API_URL + "addCoverage/", formData, {
        headers: attachHeader(true),
        withCredentials: true
    }).then((response) => ({ response }), error => ({ error }));
    
}
export const editCoverage = (data: any) => {
    console.log('reduceerData',data);
    
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('for', data.for);
    formData.append('coverageId', data.coverageId);
    for (let i = 0; i < data.coverageDetails.length; i++) {
        formData.append('type[' + i + ']', data.coverageDetails[i].name);
        formData.append('amount[' + i + ']', data.coverageDetails[i].amount);
    }
    if (data.coverageId) {
        for (let i = 0; i < data.coverageDetails.length; i++) {
            formData.append('detailId[' + i + ']', data.coverageDetails[i].id);
        }
    }
    return axios.post(
        configs.API_URL + "addCoverage/", formData, {
        headers: attachHeader(true),
        withCredentials: true
    }).then((response) => ({ response }), error => ({ error }));
    
}

export const fetchCoverageDetails = (dependantId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "coverage/"+dependantId+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const fetchClinicsAndSpentOnList = (dependantId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "addCoverageExpense/"+dependantId+"/clinic/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const addExpense = (data: any, dependantId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "addCoverageExpense/" + dependantId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        }
    };
    return commonApi(request);
}

export const deleteCoverage = (coverageId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "deleteCoverageExpense/"+coverageId+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const EditCoverageExpence = (data: any, expanceId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "editCoverageExpense/" + expanceId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        }
    };
    return commonApi(request);
}