import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { GroupChat } from '../../components/groupchat/GroupChat';
import { ChatPage } from './Chat';

export const ChatRoutes = () => {
    return (
        <Switch>
            <Route exact path="/chat/clinic" component={ChatPage}></Route>
            <Route exact path="/chat/group" component={GroupChat}></Route>
            <Redirect to="/chat/clinic"></Redirect>
        </Switch>
    )
}