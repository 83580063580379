import React, { useEffect, useState } from 'react';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { useSelector, useDispatch } from 'react-redux';
import { patientDomain } from '../../../services/patientDomain';

import Slider from 'react-slick';



export const Speciality = () => {
    const [domainType, setDomainType] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        postDomainApi();
    }, []);

    const postDomainApi = () => {
        dispatch(fetchLoadingPending());
        patientDomain().then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success.status.error === false) {
                setDomainType(success.domain);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }

    const speciality = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
    };

    return (
        <div className="ready_provider">
            <h4 className="fw-medium mb-4">Find Trusted Healthcare Providers Near You</h4>
            <div className="healthc_list">
                <Slider {...speciality}>
                    {
                        (domainType && domainType.length > 0) &&
                        domainType.map((domain: any) => {
                            return (
                                <div key={domain.id}>
                                    <a className="healthc rounded-circle border flex-shrink-0 " href="#">
                                        <span className="healthc_icon"><img src={require("../../../assets/images/domain/" + domain.icon)} /></span>
                                        <span className="healthc_name">{domain.title}</span>
                                    </a>
                                </div>
                            )
                        })
                    }
                </Slider>
                {/* <a className="healthc rounded-circle border" href="#">
                    <span className="healthc_icon"><img src={require("../../../assets/images/clinic_icon/dentist_ic.png")} /></span>
                    <span className="healthc_name">Dentist</span>
                </a>

                <a className="healthc rounded-circle border" href="#">
                    <span className="healthc_icon"><img src={require("../../../assets/images/clinic_icon/optician_ic.png")} /></span>
                    <span className="healthc_name">Optician</span>
                </a>

                <a className="healthc rounded-circle border" href="#">
                    <span className="healthc_icon"><img src={require("../../../assets/images/clinic_icon/chiro_ic.png")} /></span>
                    <span className="healthc_name">Chiro</span>
                </a>

                <a className="healthc rounded-circle border" href="#">
                    <span className="healthc_icon"><img src={require("../../../assets/images/clinic_icon/veterinary_ic.png")} /></span>
                    <span className="healthc_name">Veterinary</span>
                </a>

                <a className="healthc rounded-circle border" href="#">
                    <span className="healthc_icon"><img src={require("../../../assets/images/clinic_icon/massage.png")} /></span>
                    <span className="healthc_name">Physio</span>
                </a>

                <a className="healthc rounded-circle border" href="#">
                    <span className="healthc_icon"><img src={require("../../../assets/images/clinic_icon/physician_ic.png")} /></span>
                    <span className="healthc_name">Physician</span>
                </a>

                <a className="healthc rounded-circle border" href="#">
                    <span className="healthc_icon"><img src={require("../../../assets/images/clinic_icon/wellness_ic.png")} /></span>
                    <span className="healthc_name">Wellness</span>
                </a> */}

            </div>
        </div>
    )
}
