import React from 'react';
import './ClinicProfile.scss';
import { connect } from 'react-redux';
import { ProviderSearchRequest, ProviderState, ProviderSearchResponse } from '../../store/provider/types';
import { searchProviderPending } from '../../store/provider/actions';
import { IAppState } from '../../store';
import { toast } from 'react-toastify';
import { getHygieneFeatures } from '../../services/provider';
import { setMarkerToPostion, setGoogleMap, loadMap } from '../../utils/googleFunction';
import { timeConvert, weekDay } from '../../utils/global';
import configs from '../../configs/apiConfigs';
import { showAddress } from '../renderAddress';
import { HygieneRating } from '../hygieneRating';
import { RatingComp } from '../RatingComponent';
import { Modal } from 'react-bootstrap';
import PopUpOne from '../BookAppointmentPopUp/PopUpOne';
import PopUpTwo from '../BookAppointmentPopUp/PopUpTwo';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import PopUpTimingRelation from '../BookAppointmentPopUp/PopUpTimingRelation';
import { PatientDataState, RemoveDefaultClinicRequest, AddDefaultClinicRequest } from '../../store/patientData/types';
import { removeDefaultClinicPendingAction, addDefaultClinicPendingAction } from '../../store/patientData/actions';
interface Props {
    searchProvider: typeof searchProviderPending;
    providerState: ProviderState;
    history: any;
    location: any;
    match: any;
    patientState: PatientDataState;
    addDefaultClinic: typeof addDefaultClinicPendingAction;
    removeDefaultClinic: typeof removeDefaultClinicPendingAction;
}
interface State {
    resultData: any;
    viewMore: boolean;
    noDataFound: boolean;
    isLoading: boolean;
    data: any;
    selectedDoctor: any;
    selectedAppointment: any;
    noteForOffice: any;
    emailAddress: string;
    isPatientNew: boolean;
    userRegistrationResponse: any;
    showPopupOne: boolean;
    showPopupTwo: boolean;
    showPopupThree: boolean;
    showPopupFour: boolean;
    viewCallTo: boolean;
    hygieneList: { status: boolean, list: any[] };
    lightBoxOpen: boolean;
    photoIndex: number;
    showPopupAlreadyClinic: boolean;
    isExistingClinic: boolean;
    dependantName: string;
    dependantRelation: string;
    appointmentFor: any;
    selectedAvailability: string;
};
class ClinicProfile extends React.Component<Props, State> {
    googleMap: any;
    constructor(props: any) {
        super(props);
        this.state = {
            resultData: '',
            viewMore: true,
            noDataFound: false,
            isLoading: true,
            data: '',
            selectedDoctor: '', selectedAppointment: '', noteForOffice: '', emailAddress: '', isPatientNew: false,
            userRegistrationResponse: '',
            showPopupOne: false, showPopupTwo: false, showPopupThree: false, showPopupFour: false,
            viewCallTo: false, hygieneList: { status: false, list: [] }, lightBoxOpen: false,
            photoIndex: 0, showPopupAlreadyClinic: false, isExistingClinic: false, dependantName: '', dependantRelation: 'Son', appointmentFor: '',
            selectedAvailability: ''
        }
    }
    componentDidMount() {
        try {
            loadMap().then((success: any) => {
                //map loaded
            }, (err) => {
                console.log(err);
            });
        } catch (err) {
            console.log(err);
        }
        /* Fetch Hygiene list */
        this.fetchHygieneList();
        console.log(this.props.match);
        if (this.props.match.params.Id) {
            this.props.searchProvider({ uniqueId: this.props.match.params.Id });
            return;
        }
        this.props.history.push('/');
    }
    async fetchHygieneList() {
        try {
            const results = await getHygieneFeatures();
            if (results.hygieneFeatures) {
                this.setState({ hygieneList: { status: true, list: results.hygieneFeatures } });
            }
        } catch (error) {
            this.setState({ hygieneList: { status: false, list: [] } });
        }
    }
    bookAppointment() {
        this.setState({ showPopupOne: true });
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props !== nextProps) {
            if (nextProps.providerState.error !== null) {
                toast.error('Something went wrong');
                nextProps.providerState.error = null;
            } else if (nextProps.providerState.pending === false && nextProps.providerState.providerSearchResponse &&
                nextProps.providerState.providerSearchResponse !== this.props.providerState.providerSearchResponse) {
                this.setState({ resultData: nextProps.providerState.providerSearchResponse, data: nextProps.providerState.providerSearchResponse, noDataFound: false, isLoading: false }, () => {
                    setTimeout(() => {
                        if (nextProps.providerState.providerSearchResponse) {
                            if (this.googleMap) {
                                if (nextProps.providerState.providerSearchResponse.address && nextProps.providerState.providerSearchResponse.address.latitude && nextProps.providerState.providerSearchResponse.address.longitude) {
                                    setMarkerToPostion(this.googleMap, { latitude: nextProps.providerState.providerSearchResponse.address.latitude, longitude: nextProps.providerState.providerSearchResponse.address.longitude });
                                } else {
                                    setMarkerToPostion(this.googleMap, { latitude: 23.41, longitude: 72.12 });
                                }
                            } else {
                                if (nextProps.providerState.providerSearchResponse && nextProps.providerState.providerSearchResponse.address && nextProps.providerState.providerSearchResponse.address.latitude && nextProps.providerState.providerSearchResponse.address.longitude) {
                                    const latitude = parseFloat(nextProps.providerState.providerSearchResponse.address.latitude);
                                    const longitude = parseFloat(nextProps.providerState.providerSearchResponse.address.longitude);
                                    setGoogleMap({ latitude: latitude, longitude: longitude }, 'map')?.then((success: any) => {
                                        this.googleMap = success;
                                        setMarkerToPostion(this.googleMap, { latitude: latitude, longitude: longitude });
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                } else {
                                    setGoogleMap({ latitude: 23.41, longitude: 71.23 }, 'map')?.then((success) => {
                                        this.googleMap = success;
                                        setMarkerToPostion(this.googleMap, { latitude: 23.41, longitude: 72.12 });
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }
                            }
                        }
                    }, 100);
                });
            }
        }
        return true;
    }
    render() {
        let services: any[] = [];
        if (this.state.resultData && this.state.resultData.doctors && this.state.resultData.doctors.length > 0) {
            this.state.resultData.doctors.forEach((value: any) => {
                if (value.services && value.services.length > 0) {
                    value.services.forEach((serviceParent: any) => {
                        const findService = services.findIndex((service: any) => { return service.id.toString() === serviceParent.id.toString() });
                        if (findService === -1) {
                            services.push(serviceParent);
                        }
                    })
                }
            });
        }
        let isClinicActive = false;
        if (this.props.patientState && this.props.patientState.patientDataResponse && this.props.patientState.patientDataResponse.defaultClinic.length > 0) {
            const foundClinic = this.props.patientState.patientDataResponse.defaultClinic.find((value, index) => {
                return value.uniqueId === this.props.match.params.Id;
            });
            if (foundClinic) {
                isClinicActive = true;
            }
        }
        return (
            <>

                <div className="seo-page-dr">
                    {/* <section style={{ backgroundImage: "https://staging.xcarepro.com/uploads/clinic/7581313132_clinic_photos_1231351783-original.jpeg" }} className="timeline-sec"></section> */}
                    <section className="timeline-sec" style={{
                        backgroundImage: `url(${(this.state.resultData && this.state.resultData.photos && this.state.resultData.photos.length > 0) ?
                            configs.IMAGE_URL + 'clinic/' + this.state.resultData.photos[0].image.name + '-original.' + this.state.resultData.photos[0].image.type :
                            process.env.PUBLIC_URL + "/images/p-seo-banner-img.png"}
                            )`
                    }}></section>

                    <section className="profile-sec-seo for_provider">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <div className="practician-name media">
                                        <div className="media-img mr-5">
                                            {
                                                (this.state.resultData && this.state.resultData.logo && this.state.resultData.logo.name && this.state.resultData.logo.type) ?
                                                    <img src={configs.IMAGE_URL + 'clinic/' + this.state.resultData.logo.name + '-300x300.' + this.state.resultData.logo.type}
                                                        className="img-fluid" /> :
                                                    <img src={configs.DEFAULT_IMAGE_URL_CLINIC}
                                                        className="img-fluid" />
                                            }
                                        </div>
                                        <div className="media-body">
                                            <div className="trusted-txt"><img src={process.env.PUBLIC_URL + "/images/trusted-icon.svg"} className="mr-2" />Trusted by Xcarepro</div>
                                            <div className="p-name">{(this.state.resultData && this.state.resultData.name) ? this.state.resultData.name : ''}</div>
                                            <div id="clinicAddress" className="p-address">
                                                {
                                                    (this.state.resultData && this.state.resultData !== '' && this.state.resultData.address) &&
                                                    showAddress(this.state.resultData.address)
                                                }
                                            </div>

                                            {
                                                (this.state.hygieneList.status && this.state.resultData.HygieneFeatures) &&
                                                <HygieneRating key={'hrpp-1'} HygieneFeatures={this.state.resultData.HygieneFeatures} hygieneList={this.state.hygieneList}></HygieneRating>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 text-mdd-right text-center">
                                    <div className="text-center text-md-right practician-name">
                                        <div id="clinicRating" className="ratting-value mt-4">
                                            {
                                                (this.state.resultData && this.state.resultData.rating > 0) &&
                                                <>
                                                    <strong>{this.state.resultData.rating}</strong>
                                                    /5 <span>Avg. Rating</span>
                                                </>
                                            }
                                        </div>
                                        <div id="clinicStar" className="ratting-star mb-4">
                                            {
                                                (this.state.resultData && this.state.resultData.rating > 0) &&
                                                <RatingComp rating={this.state.resultData.rating}></RatingComp>
                                            }
                                            <span>{
                                                (this.state.resultData && this.state.resultData.reviewCount > 0) &&
                                                <>{this.state.resultData.reviewCount} Reviews</>
                                            }</span>
                                        </div>
                                    </div>
                                    {
                                        (this.state.resultData.doctors && this.state.resultData.doctors.length !== 0) &&
                                        <button className="btn btn-custom w-100" onClick={(e) => { this.bookAppointment(); }}><img src={process.env.PUBLIC_URL + "/images/ic_today_24px.svg"} className="mr-2" /> Book an Appointment</button>
                                    }
                                    {
                                        (this.state.resultData.doctors && this.state.resultData.doctors.length === 0 && this.state.viewCallTo === false) &&
                                        <button className="btn btn-custom" onClick={(e) => {
                                            this.setState({
                                                viewCallTo: true,
                                            });
                                        }}>See phone number</button>
                                    }
                                    {
                                        (this.state.resultData.doctors && this.state.resultData.doctors.length === 0 && this.state.viewCallTo === true) &&
                                        <a href={"tel:" + this.state.resultData.phone}><button className="btn btn-custom">Call {this.state.resultData.phone}</button></a>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="profile-description-seo">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 order-2 order-lg-1">
                                    <div className="cd-inner-section">
                                        <div className="abt-sec">
                                            <div className="title-txt">Welcome to {
                                                (this.state.resultData && this.state.resultData.name) &&
                                                <>{this.state.resultData.name}</>
                                            } </div>
                                            <p>
                                                {
                                                    (this.state.resultData && this.state.resultData.description) &&
                                                    <>{this.state.resultData.description}</>
                                                }
                                            </p>
                                            {/*  {
                                                    (this.state.viewMore === false && this.state.resultData.description !== "") &&
                                                    <button className="read-more btn" onClick={(e) => { this.setState({ viewMore: true }) }}>Read more</button>
                                                } */}
                                            {
                                                this.state.viewMore === true &&
                                                <>
                                                    {
                                                        (this.state.resultData && this.state.resultData.questions
                                                            && this.state.resultData.questions.length > 0 && this.state.resultData.questionResponse && this.state.resultData.questionResponse.length > 0) &&
                                                        <>
                                                            {
                                                                this.state.resultData.questions.map((question: any) => {
                                                                    const questionResponse = this.state.resultData.questionResponse.find((que: any) => {
                                                                        return question.id === que.id;
                                                                    });
                                                                    if (questionResponse && questionResponse.response !== "") {
                                                                        return (
                                                                            <>
                                                                                <p>{question.title ? question.title : ''}</p>
                                                                                <p>{questionResponse.response ? questionResponse.response : ''}</p>
                                                                            </>
                                                                        )
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })
                                                            }
                                                        </>
                                                    }
                                                    {/* <button className="read-more btn" onClick={(e) => { this.setState({ viewMore: false }) }}>View less</button> */}
                                                </>
                                            }
                                        </div>
                                        <hr />
                                        <div className="services-offered">
                                            <div className="section-title">Services</div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="all-services">
                                                        {
                                                            services.length > 0 &&
                                                            <ul className="all-service-list">
                                                                {
                                                                    services.map((service: any, index) => {
                                                                        return (<li key={index + Math.random()}>{service.name}</li>)
                                                                    })

                                                                }
                                                            </ul>
                                                        }
                                                        {
                                                            services.length === 0 &&
                                                            <label>No services</label>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="img-gallery">
                                            <div className="section-title">Practice Photos</div>
                                            <div className="row mx-0">
                                                {
                                                    (this.state.resultData && this.state.resultData.photos && this.state.resultData.photos.length > 0) &&
                                                    <>
                                                        {
                                                            this.state.resultData.photos.map((photo: any, index: number) => {
                                                                if (photo.image && photo.image.name && photo.image.type) {
                                                                    return (
                                                                        <div className="p-0 col-3" key={index + Math.random()} onClick={(e) => {
                                                                            this.setState({ lightBoxOpen: true });
                                                                        }}>
                                                                            <div className="img-box">
                                                                                <img src={configs.IMAGE_URL + "/clinic/" + photo.image.name + '-500x500.' + photo.image.type} />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })
                                                        }
                                                    </>
                                                }
                                                {
                                                    (this.state.resultData && this.state.resultData.photos && this.state.resultData.photos.length === 0) &&
                                                    <label>No photos</label>
                                                }
                                            </div>
                                        </div>
                                        <div className="provider-list">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="title-txt">Providers at this clinic</div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="list-sec-seo">
                                                <div className="row">
                                                    {
                                                        (this.state.resultData && this.state.resultData.doctors && this.state.resultData.doctors.length > 0) &&
                                                        <>
                                                            {
                                                                this.state.resultData.doctors.map((doctor: any, index: number) => {
                                                                    let coreSpeciality = '';
                                                                    if (doctor.specialities) {
                                                                        const coreSpecialityFound = doctor.specialities.findIndex((speciality: any) => {
                                                                            return speciality.coreSpeciality === "1";
                                                                        });
                                                                        if (coreSpecialityFound !== -1) {
                                                                            coreSpeciality = doctor.specialities[coreSpecialityFound].name;
                                                                        } else {
                                                                            const specialityFound = doctor.specialities.findIndex((speciality: any) => {
                                                                                return speciality.coreSpeciality !== "1";
                                                                            });
                                                                            coreSpeciality = doctor.specialities[specialityFound].name;
                                                                        }
                                                                    }
                                                                    let firstField = '';
                                                                    let secondField = '';
                                                                    if (this.state.resultData && this.state.resultData.address) {
                                                                        if (this.state.resultData.address.sublocality && this.state.resultData.address.sublocality !== "") {
                                                                            firstField = this.state.resultData.address.sublocality;
                                                                        } else if (this.state.resultData.address.neigborhood && this.state.resultData.address.neigborhood !== "") {
                                                                            firstField = this.state.resultData.address.neigborhood;
                                                                        } else if (this.state.resultData.address.locality && this.state.resultData.address.locality !== "") {
                                                                            firstField = this.state.resultData.address.locality;
                                                                        }

                                                                        if (this.state.resultData.address.address_level_1_short && this.state.resultData.address.address_level_1_short !== "") {
                                                                            secondField = this.state.resultData.address.address_level_1_short;
                                                                        }
                                                                    }
                                                                    let doctorName = doctor.fullname.replace("Dr.", "");
                                                                    doctorName = doctorName.trim();
                                                                    doctorName = doctorName.split(" ");
                                                                    const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                                                    const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                                                    return (
                                                                        <div className="col-md-4" key={index + Math.random()}>
                                                                            <Link to={"/dentists/" + firstField.toLowerCase() + "-" + secondField.toLowerCase() + "/" + doctor.fullname.trim().toLowerCase().replace(/[,._+]/g, '').replace(/\s+/g, "-") + "/" + doctor.providerId + "/d"} key={index + Math.random()}>

                                                                                <div className="media dr-box">
                                                                                    <div className="media-img mr-2">
                                                                                        {
                                                                                            (doctor.photo && doctor.photo.name && doctor.photo.type) ?
                                                                                                <img src={configs.IMAGE_URL + '/doctor/' + doctor.photo.name + '-200x200.' + doctor.photo.type} className="img-fluid" />
                                                                                                :
                                                                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                                                                            // <img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="media-body align-self-center">
                                                                                        <div className="dr-name">{doctor.fullname ? doctor.fullname : ''}</div>
                                                                                        <div className="dr-desg">
                                                                                            {coreSpeciality}
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </Link>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        (this.state.resultData && this.state.resultData.doctors && this.state.resultData.doctors.length === 0) &&
                                                        <label>Appointment has been disabled, you can call the clinic.</label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 order-1 order-lg-2">
                                    <div className="cd-sidebar">

                                        <div className="d-flex justify-content-between align-items-center act-cl">
                                            <div>Active Clinic</div>
                                            <div className="text-center">
                                                <div className="switch">
                                                    <input className="switch" id="switch" name="switch" type="checkbox" onChange={(e) => {
                                                        if (isClinicActive) {
                                                            this.props.removeDefaultClinic({ clinicId: this.state.resultData.id });
                                                        } else {
                                                            this.props.addDefaultClinic({
                                                                clinicId: this.state.resultData.id,
                                                                defaultClinicObj: {
                                                                    address: this.state.resultData.address,
                                                                    id: this.state.resultData.id,
                                                                    uniqueId: this.state.resultData.uniqueId,
                                                                    logo: this.state.resultData.logo,
                                                                    name: this.state.resultData.name
                                                                }
                                                            });
                                                        }
                                                    }} checked={isClinicActive} />
                                                    <label htmlFor="switch"></label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="insurance-btn media">
                                            <div className="media-img mr-4 align-self-center">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/insurance-icon.svg"} />
                                            </div>
                                            <div className="media-body align-self-center">Accepts most insurance plans</div>
                                        </div>
                                        <div className="open-hours">
                                            <div className="title-sec">
                                                Practice Open Hours
                                            </div>
                                            <div className="desc-sec">
                                                <ul>
                                                    {
                                                        (this.state.resultData && this.state.resultData !== '' && this.state.resultData.hours) &&
                                                        <>
                                                            {
                                                                this.state.resultData.hours.map((item: any, index: number) => {
                                                                    return (
                                                                        <li key={Math.random() + item.id} className={"" + ((new Date().getDay().toString() === (index + 1).toString()) ? "active" : '')}>
                                                                            <span>{item.label}</span>
                                                                            <span>
                                                                                {item.startHour}
                                                                                &nbsp; - &nbsp;
                                                                                {item.endHour}
                                                                            </span>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                        </>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="desc-sec d-none">
                                                <ul>
                                                    {
                                                        (this.state.resultData && this.state.resultData !== '' && this.state.resultData.hours) &&
                                                        <>
                                                            {
                                                                (Object.keys(this.state.resultData.hours).map((key: any, index) => {
                                                                    return (
                                                                        <li key={Math.random()} className={"" + ((new Date().getDay().toString() === key) ? "active" : '')}>
                                                                            <span>{weekDay[key - 1]}</span>
                                                                            <span>
                                                                                {
                                                                                    timeConvert(this.state.resultData.hours[key][0])
                                                                                }
                                                                                &nbsp; - &nbsp;
                                                                                {
                                                                                    timeConvert(this.state.resultData.hours[key][1])
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                    );
                                                                }))
                                                            }
                                                        </>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="map-sec-client">
                                            <div id="map" className="map-sec kflrui" style={{ height: "250px" }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {
                        (this.state.noDataFound === true && this.state.isLoading === false) &&
                        <div className="seo-page-dr">
                            <section className="profile-sec-seo">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <label>No data found</label>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                </div>
                <Modal show={this.state.showPopupOne} className="public_popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupOne: false });
                }}>
                    <PopUpOne showNextPopUp={(clinic: any, selectedDoctor: any) => {
                        this.setState({ showPopupOne: false, showPopupAlreadyClinic: true, selectedDoctor: selectedDoctor });
                    }} closePopUp={() => {
                        this.setState({ showPopupOne: false });
                    }} data={this.state.data}></PopUpOne>
                </Modal>
                <Modal show={this.state.showPopupAlreadyClinic} className="public_popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupAlreadyClinic: false });
                }}>
                    <PopUpTimingRelation showNextPopUp={(clinic: any, selectedAvailability: any, dependantName: string, dependantRelation: string, appoitnmentFor: string) => {
                        this.setState({
                            showPopupOne: false, showPopupAlreadyClinic: false,
                            showPopupTwo: true,
                            selectedAvailability: selectedAvailability,
                            dependantName: dependantName,
                            dependantRelation: dependantRelation,
                            appointmentFor: appoitnmentFor
                        });
                    }} closePopUp={() => {
                        this.setState({ showPopupAlreadyClinic: false });
                    }} data={this.state.data} selectedDoctor={this.state.selectedDoctor}></PopUpTimingRelation>
                </Modal>
                <Modal show={this.state.showPopupTwo} className="public_popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupTwo: false });
                }}>
                    <PopUpTwo showNextPopUp={(selectedAppointment: any, noteForOffice: any) => {
                        this.setState({ showPopupTwo: false, showPopupThree: true, selectedAppointment: selectedAppointment, noteForOffice: noteForOffice });
                    }} closePopUp={() => {
                        this.setState({ showPopupTwo: false });
                    }} data={this.state.data} doctor={this.state.selectedDoctor} isNewPatient={false} dependantName={this.state.dependantName}
                        dependantRelation={this.state.dependantRelation} appointmentFor={this.state.appointmentFor}
                        selectedAvailability={this.state.selectedAvailability} isExistingClinic={true} patientId={this.props.patientState.patientDataResponse ? this.props.patientState.patientDataResponse.id : ''}
                        patientUniqueId={this.props.patientState.patientDataResponse ? this.props.patientState.patientDataResponse.uniqueId : ''}
                        history={this.props.history}></PopUpTwo>
                </Modal>
                {(this.state.lightBoxOpen && this.state.resultData && this.state.resultData.photos && this.state.resultData.photos.length > 0) && (
                    <div style={{ zIndex: 2000 }}>
                        <Lightbox
                            reactModalStyle={{ zIndex: 2000 }}
                            mainSrc={configs.IMAGE_URL + 'clinic/' + this.state.resultData.photos[this.state.photoIndex].image.name + '-original.' + this.state.resultData.photos[this.state.photoIndex].image.type}
                            nextSrc={configs.IMAGE_URL + 'clinic/' + this.state.resultData.photos[(this.state.photoIndex + 1) % this.state.resultData.photos.length].image.name + '-original.' + this.state.resultData.photos[this.state.photoIndex].image.type}
                            prevSrc={configs.IMAGE_URL + 'clinic/' + this.state.resultData.photos[(this.state.photoIndex + this.state.resultData.photos.length - 1) % this.state.resultData.photos.length].image.name + '-original.' + this.state.resultData.photos[this.state.photoIndex].image.type}
                            onCloseRequest={() => this.setState({ lightBoxOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (this.state.photoIndex + this.state.resultData.photos.length - 1) % this.state.resultData.photos.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (this.state.photoIndex + 1) % this.state.resultData.photos.length,
                                })
                            }
                        />
                    </div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    providerState: state.provider,
    patientState: state.patientData
});
const mapDispatchToProps = (dispatch: any) => ({
    searchProvider: (request: ProviderSearchRequest) => {
        dispatch(searchProviderPending(request));
    },
    removeDefaultClinic: (request: RemoveDefaultClinicRequest) => {
        dispatch(removeDefaultClinicPendingAction(request));
    },
    addDefaultClinic: (request: AddDefaultClinicRequest) => {
        dispatch(addDefaultClinicPendingAction(request));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(ClinicProfile);