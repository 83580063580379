import React from 'react';
import '../../assets/css/owl.css';
import '../../assets/css/animate.css';
import '../../assets/css/all.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import { getProviderIdByName } from '../../services/provider';
import { Spinner } from 'react-bootstrap';
import ClinicSearch from '../clinicserach/ClinicSearch';
import { isLogin } from '../../utils';

interface Props {
  history: any;
}
interface State {
  clinicName: string;
  loading: boolean;
  showClinicList: boolean;
  data: any[];
}
export default class extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      clinicName: '', loading: false, showClinicList: false, data: []
    }
  }
  componentDidMount() {
    if (isLogin()) {
      this.props.history.push('/appointments')
    }
  }

  onClinicNameChange = (e: any) => {
    this.setState({ clinicName: e.target.value });
  }
  searchProviderId = () => {
    this.setState({ loading: true });
    getProviderIdByName(this.state.clinicName).then((success) => {
      console.log(success);
      this.setState({ loading: false, data: success.clinic, showClinicList: true });
    }).catch((err) => {
      this.setState({ loading: false });
      console.log(err);
    });
  }
  render() {
    return (
      <>
        <header className="header-landing" data-stellar-background-ratio="0.5" id="home" style={{ backgroundPosition: "50% 0px" }}>
          <div className="color-overlay">
            <div className="container">
              <div className="only-logo">
                <div className="navbar d-flex py-3">
                  <div className="navbar-header text-left">
                    <img src={require('../../assets/images/images/logo.svg')} />
                  </div>
                  <div>
                    <span className="me-3 d-none d-md-inline-block" style={{ color: "#fff" }} >Already have an account?</span>
                    <NavLink to="/login" className="btn btn-light me-2">Sign In</NavLink>
                    <NavLink to={{
                      pathname: "/register", state: {
                        register: true
                      }
                    }} className="btn btn-light">Sign Up</NavLink>
                  </div>
                </div>
              </div>
              <div className="row home-contents">
                <div className="col-md-6 col-sm-6 align-self-center">
                  <div className="intro-section text-center text-lg-left">
                    <h1 className="intro">Control Your <strong>Health Care Experience</strong> In The Palm Of Your Hand</h1>
                    <h5>Connect with providers in your area and get started today.</h5>
                    <div className="app-download-sec">
                      <a href="https://apps.apple.com/us/app/x-care/id1517457992?ls=1" target="_blank" className="app-btn app-store">
                        <img src={require('../../assets/images/images/app-store-badge.svg')} alt="app-store" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.xcarepro.android" target="_blank" className="app-btn play-store">
                        <img src={require('../../assets/images/images/play-store-badge.svg')} alt="play-store" />
                      </a>
                    </div>
                    {/* <a href="#brief1" className="btn btn-default standard-button mt-3 mx-auto">Download App Now</a> */}

                    {/* <div className="form-group text-white">
                      <label>Search provider id: </label>
                      <input className="form-control" type="text" style={{ maxWidth: "360px" }}
                        value={this.state.clinicName} onChange={(e) => { this.onClinicNameChange(e); }}
                        placeholder="Enter your clinic name" />
                      <small className="form-text text-white">Enter your clinic name to search your provider id.</small>
                    </div>

                    <button type="submit" className="btn btn-custom" onClick={() => { this.searchProviderId() }}>
                      <span className="aft_load right">
                        <>
                          {
                            this.state.loading === true &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          }
                          {
                            this.state.loading === false &&
                            <>Search provider ID</>
                          }
                        </>
                      </span>
                    </button>

                      <button type="submit" className="btn btn-custom">Search provider ID</button> */}

                    {/* <div className="address-section">
                      <input type="text" id="autocomplete" placeholder="Address or Postal Code (Ex. Mississauga OR L4T 0A4)" className="location-input pac-target-input" />
                      <button className="location-btn"><img src={require('../../assets/images/images/location.svg')} /></button>
                    </div>
                    <a className="location-title" href="javascript:void(0);"><img src={require('../../assets/images/images/current-location.svg')} />Use my current location</a> */}
                  </div>

                  {/* <div className="text-center text-lg-left">
                    <strong className="d-block mt-5" style={{ color: "#fff" }} >Already have an account?</strong>
                    <a href="http://patient.staging.xcarepro.com/login" className="btn btn-default standard-button mt-3 mx-auto" target="_blank">Sign In Now</a>
                  </div> */}

                </div>
                <div className="col-md-6 col-sm-6 align-self-center hidden-xs">
                  <div className="phone-image">
                    <img src={require('../../assets/images/images/2-iphone-right.png')} className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="app-brief" id="brief1">
          <div className="container">
            <div className="row">
              <div className="col-md-6 wow fadeInRight animated animated align-self-center">
                <div className="phone-image">
                  <img src={require('../../assets/images/images/2-iphone-left.png')} />
                </div>
              </div>
              <div className="col-md-6 left-align wow fadeInLeft animated animated">
                <h2 className="like-h1">Making Your Experience Safer & Simpler </h2>
                <p>
                  XCare allows patients to book health care provider appointments, complete pre-screening forms, manage payments and more all from within your mobile app. Stay more connected with your health care providers and take advantage of all that XCare has to offer.
                </p>
                <p className="mt-0">
                  Best of all, XCare is free for all patients to use. Download the XCare mobile app today to get started.
                </p>
                <a href="https://apps.apple.com/us/app/x-care/id1517457992?ls=1" target="_blank" className="app-btn app-store">
                  <img src={require('../../assets/images/images/app-store-badge.svg')} alt="app-store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.xcarepro.android" target="_blank" className="app-btn play-store">
                  <img src={require('../../assets/images/images/play-store-badge.svg')} alt="play-store" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="features text-center" id="features">
          <div className="container">
            <div className="section-header wow fadeIn animated animated">
              <h2 className="like-h1 text-white">Patient Benefits</h2>
              <div className="section-description">
                There are a variety of advantages for patients when using the XCare mobile app or web portal.
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 features-left wow fadeInLeft animated animated">
                <div className="feature">
                  <div className="icon-container">
                    <div className="icon">
                      <i className="fas fa-check-double"></i>
                    </div>
                  </div>
                  <div className="fetaure-details">
                    <h4 className="main-color">Control Appointment Bookings</h4>
                    <p>
                      Within the app you can submit appointment requests to meet your schedule while also managing all upcoming and past appointments for yourself and dependents.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon-container">
                    <div className="icon">
                      <i className="fas fa-check-double"></i>
                    </div>
                  </div>
                  <div className="fetaure-details">
                    <h4 className="main-color">Complete Pre-screening Forms</h4>
                    <p>
                      Easily complete your required pre-screening form in a digital fashion prior to your appointments.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon-container">
                    <div className="icon">
                      <i className="fas fa-check-double"></i>
                    </div>
                  </div>
                  <div className="fetaure-details">
                    <h4 className="main-color">Receive Appointment Reminders</h4>
                    <p>
                      For all upcoming appointments, you’ll receive reminders and notifications to ensure you are fully prepared.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 align-self-center">
                <div className="phone-image wow bounceIn animated animated">
                  <img src={require('../../assets/images/images/single-iphone.png')} />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 features-right wow fadeInRight animated animated">
                <div className="feature">
                  <div className="icon-container">
                    <div className="icon">
                      <i className="fas fa-check-double"></i>
                    </div>
                  </div>
                  <div className="fetaure-details">
                    <h4 className="main-color">Manage Payments</h4>
                    <p>
                      Pay for your treatment quickly in a contactless fashion using a credit card of your choice, Google Pay or Apple Pay. All invoices and receipts are stored in your account as well.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon-container">
                    <div className="icon">
                      <i className="fas fa-check-double"></i>
                    </div>
                  </div>
                  <div className="fetaure-details">
                    <h4 className="main-color">Check-in Virtually </h4>
                    <p>
                      Arrived to your clinic? Check-in from the parking lot with a click of a button to notify them of your arrival.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon-container">
                    <div className="icon">
                      <i className="fas fa-check-double"></i>
                    </div>
                  </div>
                  <div className="fetaure-details">
                    <h4 className="main-color">Leave Online Reviews</h4>
                    <p>
                      Enjoyed the service? Easily leave a review on XCare or Google to let others know about your experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="screenshots deep-dark-bg text-center" id="screenshot-section">

          <div className="container">
            <div className="section-header wow fadeIn animated animated" data-wow-offset="10" data-wow-duration="1.5s" style={{ visibility: "visible", animationDuration: "1.5s" }}>
              <h2 className="like-h1">The All In One Mobile App To Meet Your Health Care Needs</h2>
              <div className="section-description">
                Try XCare today and experience the benefits.
              </div>
            </div>
            <div className="row wow bounceIn animated animated">
              <OwlCarousel
                className="owl-carousel owl-theme"
                loop
                margin={10}
                nav
                items={4}
              >
                <div className="shot">
                  <img src={require('../../assets/images/images/1_001.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_002.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_003.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_004.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_005.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_006.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_007.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_008.jpg')} alt="Screenshot" />
                </div>
                {/* <div className="shot">
                  <img src={require('../../assets/images/images/1_009.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_0010.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_0011.jpeg')} alt="Screenshot" />
                </div>
                <div className="shot">
                  <img src={require('../../assets/images/images/1_0012.jpeg')} alt="Screenshot" />
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </section>
        <footer className="text-center">
          <div className="container">
            <div className="copyright-strip">
              <img src={require('../../assets/images/images/logo.svg')} alt="LOGO" className="responsive-img" />
              <p className="contact-txt">Have questions? Contact <a href="mailto:support@xcarepro.com">support@xcarepro.com</a></p>
              <hr />
              <p className="copyright">
                ©2021 Xcare Pro, All Rights Reserved
              </p>
            </div>
          </div>
        </footer>

        {
          this.state.showClinicList === true &&
          <ClinicSearch data={this.state.data} onHide={() => {
            this.setState({ showClinicList: false });
          }}></ClinicSearch>
        }
      </>
    );
  }
}