import { getCookie, prepareCookie } from './../utils/index';
import { claimSubmitError, claimSubmitSuccess } from './../store/claim/actions';
import { put } from 'redux-saga/effects';
import { CLAIM_SUBMIT, ClaimSubmitPendingAction, ClaimSubmitRequest } from './../store/claim/types';
import { takeEvery } from 'redux-saga/effects';
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';
import Axios from 'axios';
import apiConfigs from '../configs/apiConfigs';
import { APPOINTMENT_AMOUNT } from '../constants';
function execute(request: ClaimSubmitRequest) {
    const login = getCookie();
    let headers = {
        'Content-Type': 'multipart/form-data',
        // 'x-access-param': login.authParam,
        'x-access-param': login.xprAuthUser,
        'xpr_user_id': login.xpr_user_id,
        'xprApp': 'patient',
        'xcookie': prepareCookie()
        // 'xpr_user_id': login.uniqueId
    }
    console.log('Request', request);
    const formData = new FormData();
    formData.append('appointment', request.appointmentId.toString());
    formData.append('appointmentDate', request.appointmentDate);
    formData.append('score', request.score);
    formData.append('forChild', request.forChild);
    formData.append('receivedPast', request.receivedPast);
    formData.append('additionalNote', request.additionalNote);
    formData.append('spentAmount', request.amount);
    formData.append('amount', APPOINTMENT_AMOUNT);
    formData.append('comment', request.comment);
    formData.append('receipt', request.receipt);
    return Axios.post(apiConfigs.API_URL + "patient/" + request.uniqueId + "/claim/", formData, {
        headers: headers,
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* claimSubmitPendingRun(action: ClaimSubmitPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(claimSubmitError(response.data));
            } else {
                yield put(claimSubmitSuccess(response.data));
            }
        } else {
            yield put(claimSubmitError(response));
        }
    }
    if (error) {
        yield put(claimSubmitError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* claimSubmitPendingWatcher() {
    yield takeEvery(CLAIM_SUBMIT.CLAIM_SUBMIT_PENDING, claimSubmitPendingRun);
}